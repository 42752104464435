import React, { useEffect, useRef, useState } from 'react';
import './style/TextInput.css';
import dropdownIcon from '../assets/dropdown_icon.svg';
import useOutsideClick from './useOutsideClick';
import CustomCheckbox from './CustomCheckbox';

function SelectDropdown(props) {
  const [disabled, setDisabled] = useState(false);
  const [required, setRequired] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useOutsideClick(ref, () => {
    setDropdown(false);
  });

  useEffect(() => {
    if (props.disabled !== undefined && props.disabled !== null) {
      setDisabled(props.disabled);
    }
    if (props.required !== undefined && props.required !== null) {
      setRequired(props.required);
    }
  }, [props.disabled, props.required, props.valueList]);

  return (
    <div
      ref={ref}
      style={props.margin ? { margin: props.margin } : null}
      className={'input_component dropdown_component_wrapper'}
    >
      {props.title ? (
        <span style={disabled ? { color: '#C2C2C2' } : null}>
          {' '}
          {props.title}{' '}
        </span>
      ) : null}
      <input
        ref={ref}
        onClick={() => setDropdown(!dropdown)}
        style={props.width ? { width: props.width } : null}
        disabled={disabled}
        required={required}
        autoFocus={props.focus ? props.focus : false}
        placeholder={props.placeholder ? props.placeholder : null}
        className={props.error ? 'error custom_input' : 'custom_input'}
        type={props.type ? props.type : 'text'}
        value={props.value ? props.value : ''}
      />
      <img src={dropdownIcon} className={'dropdown_image'} alt="" />
      {dropdown ? (
        <div
          style={
            props.dropdownStyle
              ? props.dropdownStyle
              : props.width
              ? { width: props.width }
              : null
          }
          className={'dropdown_component_opened'}
        >
          {props.dataList
            ? props.dataList.map((data, index) => {
                return (
                  <CustomCheckbox
                    title={data}
                    onclick={
                      props.valueList.includes(data)
                        ? () => props.removeValue(data)
                        : () => props.setValueList(data)
                    }
                    value={
                      props.valueList.length > 0
                        ? props.valueList.includes(data)
                        : false
                    }
                  />
                );
              })
            : null}
        </div>
      ) : null}
    </div>
  );
}

export default SelectDropdown;
