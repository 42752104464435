import React from 'react';
import LoginLayout from '../components/LoginLayout';
import { Signup } from '../components/signup';

export const SignupPage = (props) => {
  return (
    <div className="signup-wrapper">
      <LoginLayout
        isReset={false}
        setIsReset={(e) => {}}
        email={''}
        setEmail={() => {}}
        setIsLogInCustom={(e) => {}}
        setIsLogIn={(e) => {}}
        isLogIn={false}
      >
        <Signup changeToLogIn={() => {}} changeToConfirm={() => {}} />
      </LoginLayout>
    </div>
  );
};
