import React from 'react';
import './style/CustomCheckbox.css'
import Checked from "../assets/checkbox-tick.svg";

function CustomCheckbox(props) {

    console.log(props.value)
    return (
        <div style={props.margin ? {margin: props.margin} : null}
             className={'input_component'}>
            <div className={`login-form__checkbox`}
                 onClick={props.onclick ? e => {
                     props.onclick(e)
                 } : null}>
                <input type="checkbox"
                       id={props.id ? props.id : ''}
                       autoFocus={props.focus ? props.focus : false}
                       className="checkbox"
                       onSubmit={(e) => {
                           props.onclick(e)
                       }} value={props.value}/>
                <label className={'custom_checkbox_label'}>
                                <span style={{width: 'max-content'}}>
                                    <img src={Checked}
                                         className={props.value ? 'login-form__checkbox__checked' : 'login-form__checkbox__not-checked'}
                                         alt={"Checkbox"}/>
                                </span>
                    <p>{props.title ? props.title : ''}</p>
                </label>
            </div>
        </div>

    );
}

export default CustomCheckbox;
