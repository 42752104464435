import React from 'react';
import './style/LicenseStatus.css'

function CustomCheckbox(props) {

    return (
        <div onMouseOver={props.onMouseOver ? ()=> props.onMouseOver() : null} onMouseOut={props.onMouseOut ? ()=> props.onMouseOut() : null } style={props.margin ? {margin: props.margin} : null}
             className={props.status === 'active' ? 'active_license license_status' :
            props.status === 'pending' ? 'pending_license license_status' :
                props.status === 'inactive' ? 'inactive_license license_status' :
                    null}>
            <span>{props.status === 'active' ? 'Active' :
                props.status === 'pending' ? 'Pending' :
                    props.status === 'inactive' ? 'Inactive' :
                        null}</span>
        </div>

    );
}

export default CustomCheckbox;
