import React from 'react';
import './style/CustomRadioButton.css';

function CustomRadioButton(props) {
  return (
    <div
      style={props.margin ? { margin: props.margin } : null}
      className={'input_component_radio'}
    >
      <div
        className={`login-form__checkbox_radio`}
        onClick={
          props.onclick
            ? (e) => {
                props.onclick(e);
              }
            : null
        }
      >
        <input
          type="checkbox"
          id={props.id ? props.id : ''}
          autoFocus={props.focus ? props.focus : false}
          className="checkbox_radio"
          onSubmit={(e) => {
            props.onclick(e);
          }}
          value={props.value}
        />
        <label className={'custom_checkbox_label_radio'}>
          <span style={{ width: 'max-content' }}>
            <div
              className={
                props.value
                  ? 'login-form__checkbox__checked_radio'
                  : 'login-form__checkbox__not-checked_radio'
              }
            />
          </span>
          <p>{props.title ? props.title : ''}</p>
        </label>
      </div>
    </div>
  );
}

export default CustomRadioButton;
