import {SHOW_CREATE_LICENSE, SET_REDIRECT_MODAL} from "./actions";

const InitialState = {
    createLicense: false,
    redirectModal: false
};

export const shop = (state = InitialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case SHOW_CREATE_LICENSE:
            return {...state, createLicense: action.payload};
        case  SET_REDIRECT_MODAL:
            return {...state, redirectModal: action.payload}
    }
    return state;
};
