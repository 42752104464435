import React, {useState} from "react";
import Layout from "../components/Layout";
import {withRouter} from "react-router-dom";
import "../components/style/profile.scss"
import "../components/style/billing.css"
import CardSection from "../components/CardElement";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import API from "../API";
import {Keys} from "../helper";


const PaymentTest = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [popupAlert, setPopupAlert] = useState(0);
    const createPaymentMethod = (customerId, priceId) => {
        if (!stripe || !elements) {
            alert("error")
            return;
        }

        const cardElement = elements.getElement(CardElement);

        return stripe
            .createPaymentMethod({
                type: 'card',
                card: cardElement,
            })
            .then((result) => {
                if (result.error) {
                    alert("error")
                } else {
                    API.post("/limit/upgrade", {
                        "accountKey": localStorage.getItem(Keys.ACCOUNT_KEY),
                        "limitTemplateKey": "57cd3416164b47f08841b5da64162568",
                        "paymentPeriod": "MONTH",
                        "stripePaymentMethodKey": result.paymentMethod.id
                    }).then(r => {
                        if (r.data.status === 'active') {
                            alert("Successful payment")
                        }
                        else{
                            //todo show notification card was declined, please try another one.
                        }
                    }
                    )
                }
            });
    }

    return (
        <Layout popupAlert={popupAlert} setPopupAlert={() => setPopupAlert(0)}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <CardSection style={{marginBottom: "40px", display: "unset"}}/>
                <button
                    style={{width: "65px"}}
                    onClick={() => {
                        createPaymentMethod(1, 2)
                    }}>Submit
                </button>
            </div>
        </Layout>);
};

export default withRouter(PaymentTest)
