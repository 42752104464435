import React from 'react';
import doneIcon from '../assets/done_icon.svg';
import alertIcon from '../assets/alert_icon.svg';
import closeIcon from '../assets/close_icon.svg'
import './style/Notification.css'

function MainButton(props) {

    return (
        <div style={props.margin ? {margin: props.margin} : null}
             className={props.alert === 'success' ? 'success notification' :
                props.alert === 'warning' ? 'warning notification' :
                    props.alert === 'error' ? 'error notification' :
                        'notification'
             }>
            <img className={'status'}
                 src={props.alert === 'success' ? doneIcon :
                     props.alert === 'warning' ? alertIcon :
                         props.alert === 'error' ? alertIcon :
                             alertIcon
                 } alt=""/>
            <span>{props.text && props.text.length > 0 ? props.text :
                props.alert === 'success' ? 'Your settings has been saved.' :
                    props.alert === 'warning' ? 'Looks like we\'re having trouble.' :
                        props.alert === 'error' ? 'Looks like we\'re having trouble.' :
                            'Looks like we\'re having trouble.'
            }</span>
            <img src={closeIcon} onClick={props.onclick ? ()=> props.onclick() : null} alt=""/>
            {/*<CloseIcon style={{width: '10px', height: '10px', position: 'unset', top:'unset', right: 'unset'}} onclick={props.onclick ? ()=> props.onclick() : null}  />*/}
        </div>
    );
}

export default MainButton;
