import React, {useEffect, useState} from 'react';
import './style/TextInput.css'

function TextBox(props) {
    const [disabled, setDisabled] = useState(false)
    const [required, setRequired] = useState(false)

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
        if (props.required !== undefined && props.required !== null) {
            setRequired(props.required)
        }
    }, [props.disabled, props.required])

    return (
        <div style={props.margin ? {margin: props.margin} : null} className={'input_component'}>
            <span style={disabled ? {color: '#C2C2C2'} : null}>{props.title ? props.title : ''}</span>
            <textarea
                style={props.width ? {width: `${props.width}`} : null}
                disabled={disabled}
                name={props.name ? props.name : ""}
                required={required}
                placeholder={props.placeholder ? props.placeholder : null}
                className={props.error ? 'error textarea' : 'textarea'}
                rows={props.rows ? props.rows : 5}
                value={props.value ? props.value : ''}
                onChange={props.onchange ? e => {
                    props.onchange(e)
                } : null}
            />
        </div>
    );
}

export default TextBox;
