import React, {useEffect, useRef, useState} from "react";
import Layout from "../components/Layout";
import {withRouter} from "react-router-dom";
import "../components/style/profile.scss"
import "../components/style/billing.css"
import TextInput from "../components/TextInput";
import TextBox from "../components/TextBox";
import MainButton from "../components/MainButton";
import CloseIcon from "../components/CloseIcon";
import Pricing from "./Pricing";
import useOutsideClick from "../components/useOutsideClick";
import CardSection from "../components/CardElement";
import stripeImg from "../assets/secured_stripe.svg";

const Profile = () => {
    const [popupAlert, setPopupAlert] = useState(0);
    const [upgradePlan, setUpgradePlan] = useState(false)
    const [updatePayment, setUpdatePayment] = useState(false)
    const [billingData, setBillingData] = useState({
            company: '',
            companyProfile: '',
            address1: '',
            address2: '',
            city: '',
            zipCode: '',
            country: '',
            state: '',
            vat: '',
            email: '',
            phone: '',
            cardName: '',
        }
    )

    let ref = useRef();

    useOutsideClick(ref, () => {
        console.log(ref)
        if (updatePayment) {
            setUpdatePayment(false)
        }
    });


    useEffect(() => {
        if (updatePayment) {
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setUpdatePayment(false)
                }
            })
        }
    }, [updatePayment]);

    const changeBillingData = (name, value) => {
        console.log(name, value)
        setBillingData(prevState => ({...prevState, [name]: value}))
    }


    useEffect(() => {
        if (upgradePlan) {
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                    setUpgradePlan(false)
                }
            })
        }
    }, [upgradePlan]);

    return (
        <Layout popupAlert={popupAlert} setPopupAlert={() => setPopupAlert(0)}>
            <div className={'wrapper_profile_information'}
                 style={{gridTemplateColumns: 'max-content', justifyContent: 'center'}}>
                {upgradePlan ?
                    <>
                        <CloseIcon onclick={() => {
                            document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                            setUpgradePlan(false)
                        }}
                                   style={{
                                       position: 'fixed',
                                       top: '24px',
                                       right: '24px',
                                       zIndex: '1000000',
                                       cursor: 'pointer'
                                   }}/>
                        <Pricing goToDashboard={() => setUpgradePlan(false)} fixed={true}/>
                    </>
                    : null
                }
                {updatePayment ?
                    <div className={'global_popup_wrapper'}>
                        <form style={{width: '475px'}} className={'global_popup'}
                              ref={ref}>
                            <CloseIcon onclick={() => setUpdatePayment(false)}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Update Payment Details</span>
                            </div>
                            <div className={'update_payment_content'}>
                                <CardSection style={{marginBottom: "40px", display: "unset"}}/>
                                <TextInput title={'Name on card'}
                                           value={billingData.cardName}
                                           name={'cardName'}
                                           required={true}
                                           margin={'5px 0 5px 0'}
                                           onchange={e => {
                                               changeBillingData(e.target.name, e.target.value)
                                           }}/>
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                                    <MainButton width={'155px'} onclick={() => setUpdatePayment(false)}
                                                button={'accent'} text={`Save`}/>
                                    <img src={stripeImg} style={{marginLeft: 'auto'}} alt=""/>
                                </div>
                            </div>
                        </form>
                    </div>
                    : null
                }
                <div style={{width: "900px"}}>
                    <h2 className={"children-wrapper__page-header"}>Billing</h2>
                    <div className={'billing_details_wrapper'}>
                        <div>Billing Details</div>
                        <div className={'billing_details_content'}>
                            <div>
                                <span>Company</span>
                                <div className={'billing_input_info'}>
                                    <TextInput value={billingData.company}
                                               name={'company'}
                                               margin={'5px 0 5px 0'}
                                               onchange={e => {
                                                   changeBillingData(e.target.name, e.target.value)
                                               }}/>
                                    <span>
                                            If you belong to a Company, it will be added to billing receipts.
                                        </span>
                                </div>
                            </div>
                            <div>
                                <span>Company Profile</span>
                                <div className={'billing_input_info'}>
                                    <TextBox rows={3}
                                             value={billingData.companyProfile}
                                             name={'companyProfile'}
                                             margin={'5px 0 5px 0'}
                                             onchange={e => {
                                                 changeBillingData(e.target.name, e.target.value)
                                             }}/>
                                    <span>
                                            Additional company information to be shown on invoices; e.g. address, PO number, etc.
                                        </span>
                                </div>
                            </div>
                            <div className={'billing_default_input'}>
                                <TextInput title={'Address line 1'}
                                           name={'address1'}
                                           value={billingData.address1}
                                           margin={'5px 0 5px 0'}
                                           onchange={e => {
                                               changeBillingData(e.target.name, e.target.value)
                                           }}/>
                            </div>
                            <div className={'billing_default_input'}>
                                <TextInput title={'Address line 2'}
                                           value={billingData.address2}
                                           margin={'5px 0 5px 0'}
                                           name={'address2'}
                                           onchange={e => {
                                               changeBillingData(e.target.name, e.target.value)
                                           }}/>
                            </div>
                            <div className={'billing_default_input'}>
                                <div className={'billing_double_input_big'}>
                                    <div>
                                        <TextInput title={'City'}
                                                   value={billingData.city}
                                                   name={'city'}
                                                   margin={'5px 0 5px 0'}
                                                   onchange={e => {
                                                       changeBillingData(e.target.name, e.target.value)
                                                   }}/>
                                    </div>
                                    <div>
                                        <TextInput title={'Zip-code'}
                                                   value={billingData.zipCode}
                                                   name={'zipCode'}
                                                   margin={'5px 0 5px 0'}
                                                   type={'number'}
                                                   onchange={e => {
                                                       changeBillingData(e.target.name, e.target.value)
                                                   }}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'billing_default_input'}>
                                <div className={'billing_double_input_big'}>
                                    <div>
                                        <TextInput title={'Country'}
                                                   value={billingData.country}
                                                   name={'country'}
                                                   margin={'5px 0 5px 0'}
                                                   onchange={e => {
                                                       changeBillingData(e.target.name, e.target.value)
                                                   }}/>
                                    </div>
                                    <div>
                                        <TextInput title={'State'}
                                                   value={billingData.state}
                                                   name={'state'}
                                                   margin={'5px 0 5px 0'}
                                                   onchange={e => {
                                                       changeBillingData(e.target.name, e.target.value)
                                                   }}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'billing_default_input'}>
                                <TextInput title={'VAT number/Tax ID (optional)'}
                                           value={billingData.vat}
                                           name={'vat'}
                                           margin={'5px 0 5px 0'}
                                           onchange={e => {
                                               changeBillingData(e.target.name, e.target.value)
                                           }}/>
                            </div>
                            <div className={'billing_default_input'}>
                                <div className={'billing_double_input'}>
                                    <div>
                                        <TextInput title={'Email to receive bills'}
                                                   value={billingData.email}
                                                   type={'email'}
                                                   name={'email'}
                                                   margin={'5px 0 5px 0'}
                                                   onchange={e => {
                                                       changeBillingData(e.target.name, e.target.value)
                                                   }}/>
                                    </div>
                                    <div>
                                        <TextInput title={'Phone'}
                                                   value={billingData.phone}
                                                   name={'phone'}
                                                   type={'number'}
                                                   margin={'5px 0 5px 0'}
                                                   onchange={e => {
                                                       changeBillingData(e.target.name, e.target.value)
                                                   }}/>
                                    </div>
                                </div>
                            </div>
                            <MainButton margin={'20px 0 0 '} width={'155px'} button={'accent'} text={"Save"}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>);
};

export default withRouter(Profile)
