import React, {useEffect, useState} from 'react';

function TooltipCustom(props) {
    const [length, setLength] = useState(false)

    useEffect(() => {

        if (props.text && props.text.length > 20) props.text.split(' ').forEach(word => {
            if (word.length > 20) {
                setLength(true)
            }
        })
        else if (props.status || props.grant) setLength(false)
    }, [props.grant, props.status, props.text])

    return (
        <div className={'custom_tooltip_wrapper'} onClick={() => props.onclick()}>
            <div style={props.style ? props.style : null} className={'custom_tooltip_content'}>
                <span className={'tooltip_text'}
                      style={length ? null : {wordBreak: 'unset'}}>{props.text ? props.text : props.status || props.grant || props.license ?
                    props.status === 'active' ? 'License is installed and works successfully.' :
                        props.status === 'pending' ? 'License is not installed yet.' :
                            props.status === 'inactive' ? 'License not working or expired' :
                                props.grant === 'Identification' ? 'Your device can only Identify persons.' :
                                    props.grant === 'Management' ? 'Your device can Create, Update or Delete person’s data.' :
                                        props.grant === 'IdentificationKey' ? 'Your API Key can only Identify persons.' :
                                            props.grant === 'ManagementKey' ? 'Your API Key can Create, Update or Delete person’s data.' :
                                                props.grant === 'IdentificationDash' ? 'Your device can only Identify persons.' :
                                                    props.grant === 'ManagementDash' ? 'Your device can Create, Update or Delete person’s data.' :
                                                        props.license === 'Permanent' ? 'The license is active while your account is active.' :
                                                            props.license === 'Subscription' ? 'Subscription License is active while your subscription is active.' :
                                                                props.license === 'Subscription based' ? 'Subscription based License is active while your subscription is active.' :
                                                                    '' : ''} </span>
            </div>
            <div className={'custom_tooltip_triangle'}></div>
        </div>

    );
}

export default TooltipCustom;
