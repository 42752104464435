import React, {useEffect, useRef, useState} from "react";
// import API from "../API";
import Layout from "../components/Layout";
import "../components/style/license.css"
import useOutsideClick from '../components/useOutsideClick'
import generate_key from '../assets/generate_key.svg'
import copy_element from '../assets/copy_command_icon.svg'
import edit_element from '../assets/edit_element.svg'
import {Keys} from "../helper";
import API from "../API";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import TextInput from "../components/TextInput";
import SearchInput from "../components/SearchInput";
import CustomCheckbox from "../components/CustomCheckbox";
import MainButton from "../components/MainButton";
import LicenseStatuses from "../components/LicenseStatuses";
import CloseIcon from "../components/CloseIcon";
import stopIcon from "../assets/stop_icon.svg";
import attentionIcon from "../assets/attention_icon.svg";
import copyIconPopup from '../assets/copy_command_wrapper.svg'
import TooltipCustom from "../components/TooltipCustom";
import Pagination from "react-js-pagination";
import filterIcon from '../assets/filter_icon.svg'
import deleteTagIcon from '../assets/delete_tag_icon.svg'
import deleteTagIconBlue from '../assets/delete_tag_icon_blue.svg'
import loaderGif from '../assets/loader.gif'

import CustomRadioButton from "../components/CustomRadioButton";


const Licenses = props => {
    const [addLicenseModal, changeAddLicenseModal] = useState(false);
    const [checkBoxLicense, changeCheckBoxLicense] = useState(false);
    const [activationCode, changeActivationCode] = useState('');
    const [errorActivationCode, changeErrorActivationCode] = useState(false);
    const [licenseArray, changeLicenseArray] = useState(false);
    const [editLicense, changeEditLicense] = useState(false);
    const [activeEditData, setActiveEditData] = useState({grants: [], description: "", accessKey: null})
    const [tagsArr, setTagsArr] = useState({tags: []})
    const [popupAlert, setPopupAlert] = useState('')
    const [popupAlertText, setPopupAlertText] = useState('')
    const [searchStr, changeSearchStr] = useState('')
    const [activeAddData, setActiveAddData] = useState({
        grants: ['Identification', 'Management'],
        description: "",
        accessKey: null
    })
    const [tagsAddArr, setTagsAddArr] = useState({tags: []})
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteKey, setDeleteKey] = useState('')
    const [regenerateKey, setRegenerateKey] = useState('')
    const [installationCode, setInstallationCode] = useState('')
    // const [copyModal, setCopyModal] = useState(false)
    const [regenerateModal, setRegenerateModal] = useState(false)
    const [regenerateCopy, setRegenerateCopy] = useState(false)
    const [installationModal, setInstallationModal] = useState(false)
    const [activeTagValue, setActiveTagValue] = useState('')
    const [activeEditTagValue, setActiveEditTagValue] = useState('')
    const [descriptionError, setDescriptionError] = useState(false)
    const [tagsError, setTagsError] = useState(false)
    const [noLicenses, setNoLicenses] = useState(false)
    const [searchProtoArray, setSearchProtoArray] = useState([])
    const [descriptionTooltip, setDescriptionTooltip] = useState()
    const [descriptionEditError, setDescriptionEditError] = useState(false)
    const [tagsEditError, setTagsEditError] = useState(false)
    const [statusTooltip, setStatusTooltip] = useState()
    const [licenseTooltip, setLicenseTooltip] = useState()
    const [tagsTooltip, setTagsTooltip] = useState()
    const [grantIdeTooltip, setGrantIdeTooltip] = useState()
    const [grantManTooltip, setMenIdeTooltip] = useState()
    const [activePage, setActivePage] = useState(1)
    const [allItems, setAllItems] = useState()
    const [filterModal, setFilterModal] = useState(false)
    const [filterData, setFilterData] = useState(
        {
            Active: false,
            Pending: false,
            Inactive: false,
            AllGrants: false,
            Identification: false,
            Management: false,
            type: 0
        }
    )
    const [searchData, setSearchData] = useState([])
    const [searchFocus, setSearchFocus] = useState(false)
    const [loader, setLoader] = useState(true)
    const [limitsData, setLimitsData] = useState([])
    const [activationCodeError, setActivaionCodeError] = useState(false)
    const [licensesUsed, setLicensesUsed] = useState(0)

    let ref = useRef();

    useEffect(() => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);

        API.post('/account/limit/list/get', {accountKey: accountKey})
            .then(res => {
                res.data.limits.forEach(limit => {
                    setLimitsData(limit)
                })
            })
    }, [])


    useOutsideClick(ref, () => {
        console.log(ref)
        if (regenerateModal) {
            setRegenerateModal(false)
        }
        if (regenerateCopy) {
            setRegenerateCopy(false)
        }
        if (installationModal) {
            setInstallationModal(false)
        }
        if (deleteModal) {
            setDeleteModal(false)
        }
        if (filterModal) {
            setFilterModal(false)
        }
    });

    let handleSubmitLicense = e => {
        e.preventDefault();
        if (activationCode) {
            changeAddLicenseModal(false)
        } else {
            changeErrorActivationCode(true)
        }
    };

    useEffect(() => {
        if (props.createLicense) {
            changeAddLicenseModal(props.createLicense)
            props.showCreateLicense(false)
        }
        // eslint-disable-next-line
    }, [props.createLicense])

    useEffect(() => {
        if (addLicenseModal) {
            document.getElementById('lastAdd').addEventListener('focusout', () => {
                document.getElementById('firstAdd').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                    changeAddLicenseModal(false)
                }
            })
        } else if (regenerateModal) {
            document.getElementById('lastReg').addEventListener('focusout', () => {
                document.getElementById('firstReg').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setRegenerateModal(false)
                }
            })
        } else if (regenerateCopy) {
            document.getElementById('lastInst').addEventListener('focusout', () => {
                document.getElementById('firstInst').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setRegenerateCopy(false)
                }
            })
        } else if (installationModal) {
            document.getElementById('lastInst').addEventListener('focusout', () => {
                document.getElementById('firstInst').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setInstallationModal(false)
                }
            })
        } else if (deleteModal) {
            document.getElementById('lastDel').addEventListener('focusout', () => {
                document.getElementById('firstDel').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setDeleteModal(false)
                }
            })
        } else if (editLicense) {
            document.getElementById('lastEdit').addEventListener('focusout', () => {
                document.getElementById('firstEdit').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    changeEditLicense(false)
                }
            })
        }

    }, [addLicenseModal, regenerateModal, regenerateCopy, installationModal, deleteModal, editLicense]);

    useEffect(() => {

        if (activeAddData.description.length >= 50) {
            setDescriptionError(true)
        } else setDescriptionError(false)

        if (tagsAddArr.tags?.length >= 5) {
            setTagsError(true)
        } else setTagsError(false)

        if (activeEditData.description.length >= 50) {
            setDescriptionEditError(true)
        } else setDescriptionEditError(false)

        if (tagsArr.tags?.length >= 5) {
            setTagsEditError(true)
        } else setTagsEditError(false)

    }, [activeAddData, tagsAddArr.tags, activeEditData, tagsArr.tags])

    useEffect(() => {
        getLicenses()
        // eslint-disable-next-line
    }, [activePage, filterData, searchData])

    const changePage = (page) => {
        setActivePage(page)
        window.scrollTo(0, 0)
    }

    // const handleChange = (tags) => {
    //     setTagsArr(prevState => ({...prevState, tags: tags}))
    //     console.log(activeEditData)
    // }

    const handleEdit = () => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);

        let localArr = activeEditData.grants
        localArr.forEach((grant, index) => {
            if (grant === 'Management') {
                localArr[index] = "PERSON_MANAGER"
            } else if (grant === 'Identification') {
                localArr[index] = 'RECOGNITION'
            }
        })

        API.post('/key/edit', {
            accessKey: activeEditData.accessKey,
            accountKey: accountKey,
            description: activeEditData.description,
            grants: localArr,
            tags: tagsArr.tags
        })
            .then(res => {
                setPopupAlert(1);
                setPopupAlertText('Success')
                getLicenses()
            })
    }

    const getLicenses = () => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        let statusTemp = [];
        let grantsTemp = [];
        let typeTemp = [];


        Object.entries(filterData).forEach(item => {
            switch (item[0]) {
                case 'Active':
                    if (item[1]) {
                        statusTemp.push('ACTIVE')
                    }
                    break;
                case 'Pending':
                    if (item[1]) {
                        statusTemp.push('PENDING')
                    }
                    break;
                case 'Inactive':
                    if (item[1]) {
                        statusTemp.push('INACTIVE')
                    }
                    break;
                case 'AllGrants':
                    if (item[1] && filterData.Management && filterData.Identification) {
                    } else if (item[1]) {
                        grantsTemp.push('PERSON_MANAGER')
                        grantsTemp.push('RECOGNITION')
                    }
                    break;
                case 'Management':
                    if (item[1] && !filterData.AllGrants) {
                        grantsTemp.push('PERSON_MANAGER')
                    }
                    break;
                case 'Identification':
                    if (item[1] && !filterData.AllGrants) {
                        grantsTemp.push('RECOGNITION')
                    }
                    break;
                case 'type':
                    if (item[1] === 0) {
                        typeTemp.push('SUBSCRIPTION');
                        typeTemp.push('PERMANENT');
                    } else if (item[1] === 1) {
                        typeTemp.push('SUBSCRIPTION')
                    } else if (item[1] === 2) {
                        typeTemp.push('PERMANENT')
                    }
                    break;
                default:
                    break;
            }
        })

        API.post('/key/list', {
            "accountKey": accountKey,
            types: typeTemp,
            pagination: {pageSize: 10, pageNumber: activePage - 1},
            grants: grantsTemp,
            statuses: statusTemp,
            tags: searchData
        })
            .then(res => {
                setSearchProtoArray(res.data.keys)
                setAllItems(res.data.pagination.totalElements)
                changeLicenseArray(res.data);
                setLoader(false)
            })
    }

    useEffect(() => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);

        API.post('/key/list', {
            "accountKey": accountKey,
            types: ['SUBSCRIPTION', 'PERMANENT'],
            pagination: {pageSize: 10, pageNumber: activePage - 1},
            grants: [],
            statuses: [],
            tags: []
        })
            .then(res => {
                setLicensesUsed(res.data.pagination.totalElements)
                if (res.data.keys.length === 0) {
                    setNoLicenses(true)
                }
            })
        // eslint-disable-next-line
    }, [])

    const generateLicense = () => {
        if (checkBoxLicense && activationCode.length !== 34) {
            setActivaionCodeError(true)
            return
        } else {
            setActivaionCodeError(false)
        }

        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        let localArr = activeAddData.grants
        localArr.forEach((grant, index) => {
            if (grant === 'Management') {
                localArr[index] = "PERSON_MANAGER"
            } else if (grant === 'Identification') {
                localArr[index] = 'RECOGNITION'
            }
        })
        API.post('/key/generate', {
            activationCode: activationCode,
            accountKey: accountKey,
            tags: tagsAddArr.tags,
            grants: localArr,
            description: activeAddData.description,
            type: checkBoxLicense ? 'PERMANENT' : 'SUBSCRIPTION'
        })
            .then(res => {
                console.log(res)
                localArr = []
                document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                changeAddLicenseModal(false)
                changeCheckBoxLicense(false)
                setPopupAlert(1);
                changeActivationCode('')
                setLicensesUsed(licensesUsed + 1)
                setActiveAddData({grants: ['Identification', 'Management'], description: "", accessKey: null})
                setTagsAddArr({tags: []})
                getLicenses()
            })
            .catch(err => {
                console.log(err.response.data.message);
                if (err.response.status) {
                    setPopupAlertText(err.response.data.message)
                    setPopupAlert(3);
                }
            })
    }

    const regenerateLicense = (accessKey) => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        API.post('/key/regenerate', {keyIdentifier: accessKey, accountKey: accountKey})
            .then(res => {
                console.log(res)
                setPopupAlert(1);
                setPopupAlertText('Success')
                setInstallationCode(res.data.accessKey)
                setRegenerateCopy(true)
                setRegenerateModal(false)
                getLicenses()
            })
            .catch(err => {
                if (err.response.status) {
                    setPopupAlert(3);
                }
            })
    }

    const deleteLicense = (accessKey) => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        API.post('/key/delete', {accessKey: accessKey, accountKey: accountKey})
            .then(res => {
                console.log(res)
                setPopupAlert(1);
                setPopupAlertText('Success')
                setDeleteModal(false)
                setLicensesUsed(licensesUsed - 1)
                getLicenses()
            })
            .catch(err => {
                if (err.response.status) {
                    setPopupAlert(3);
                }
            })
    }

    const copyInstallation = (accessKey) => {
        navigator.clipboard.writeText(`bash <(curl -s ${process.env.REACT_APP_API_URL}/installationscript/download/install_${accessKey}.sh)`).then(function () {
            setPopupAlert(1);
            setPopupAlertText('Copied successfully')
        }, function (err) {
            setPopupAlert(3);
            setPopupAlertText('Some error occurred')
        });
    }

    return (
        <Layout popupAlert={popupAlert} popupAlertText={popupAlertText} setPopupAlertText={() => setPopupAlertText('')}
                setPopupAlert={() => setPopupAlert(0)}>
            <div style={{maxWidth: '871px', margin: 'auto', gridTemplateColumns: '1fr'}}
                 className={'wrapper_profile_information'}>
                {deleteModal ?
                    <div className={'global_popup_wrapper'}>
                        <form style={{width: '475px'}} className={'global_popup'}
                              ref={ref}
                              onSubmit={handleSubmitLicense}>
                            <CloseIcon onclick={() => setDeleteModal(false)}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Delete License</span>
                            </div>
                            <div className={'delete_license_content'}>
                                <span className={'license_text'}>Are you sure you want to delete license ?</span>
                                <div className={'warning_block'}>
                                    <img src={stopIcon} alt=""/>
                                    <span>Your access to the device will be blocked.</span>
                                </div>
                                <div className={'delete_buttons'}>
                                    <MainButton autofocus={true} button={'accent'} id={'firstDel'} width={'155px'}
                                                text={"Keep going"}
                                                onclick={() => setDeleteModal(false)}/>
                                    <MainButton button={'danger'} width={'155px'} id={'lastDel'} text={"Delete"}
                                                onclick={() => deleteLicense(deleteKey)}/>
                                </div>
                            </div>
                        </form>
                    </div>
                    : null
                }
                {installationModal || regenerateCopy ?
                    <div className={'global_popup_wrapper'}>
                        <form style={{width: '705px'}} className={'global_popup'}
                              ref={ref}
                              onSubmit={handleSubmitLicense}>
                            <CloseIcon
                                onclick={regenerateCopy ? () => setRegenerateCopy(false) : () => setInstallationModal(false)}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>{regenerateCopy ? 'Regenerated license Key' : 'Installation command'}</span>
                            </div>
                            <div className={'delete_license_content'}>
                                <span
                                    className={'license_text'}>{regenerateCopy ? 'To replace your license Key on the device, insert the installation command into the command line when starting the device. License Key will be replaced automatically.' :
                                    'To install the software for 3D face analysis, insert the installation command into the command line when starting the device. License will be activated automatically.'}</span>
                                <div className={'installation_input_wrapper'}>
                                    <div className={'installation_input'}>
                                        <span>{`bash <(curl -s ${process.env.REACT_APP_API_URL}/installationscript/download/install_${installationCode}.sh)`}</span>
                                    </div>
                                    <MainButton
                                        wrapperStyle={{display: 'grid'}}
                                        autofocus={true}
                                        id={'firstInst'}
                                        onclick={() => copyInstallation(installationCode)}
                                        img={copyIconPopup} style={{
                                        margin: 'auto',
                                        width: '77px',
                                        textTransform: 'unset',
                                        fontWeight: 'normal'
                                    }} text={'Copy'} button={'download'}/>
                                </div>
                                <div className={'delete_buttons'}>
                                    <MainButton id={'lastInst'} button={'accent'} width={'155px'} text={"OK"}
                                                onclick={regenerateCopy ? () => setRegenerateCopy(false) : () => setInstallationModal(false)}/>
                                </div>
                            </div>
                        </form>
                    </div>
                    : null
                }
                {regenerateModal ?
                    <div className={'global_popup_wrapper'}>
                        <form style={{width: '475px'}} className={'global_popup'}
                              ref={ref}
                              onSubmit={handleSubmitLicense}>
                            <CloseIcon onclick={() => setRegenerateModal(false)}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Regenerate License</span>
                            </div>
                            <div className={'delete_license_content'}>
                                <span
                                    className={'license_text'}>Are you sure you want to regenerate license Key? </span>
                                <div className={'warning_block'}>
                                    <img src={attentionIcon} alt=""/>
                                    <span
                                        style={{color: '#FFAB00'}}>Your access to the device will be blocked.</span>
                                </div>
                                <div className={'delete_buttons'}>
                                    <MainButton autofocus={true} id={'firstReg'} button={'warning'} width={'155px'}
                                                text={"Regenerate key"}
                                                onclick={() => {
                                                    regenerateLicense(regenerateKey);
                                                    setTimeout(() => setRegenerateModal(false))
                                                }}/>
                                    <MainButton button={'cancel'} id={'lastReg'} width={'155px'} text={"Cancel"}
                                                onclick={() => setRegenerateModal(false)}/>
                                </div>
                            </div>
                        </form>
                    </div>
                    : null
                }
                {addLicenseModal ?
                    <div
                        ref={ref}
                        onClick={(e) => {
                            if (e.target === ref.current) {
                                document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                                changeAddLicenseModal(false)
                            }
                        }}
                        className={'global_popup_wrapper'}>
                        <form style={{width: '705px'}} id={'add_license'}
                              className={checkBoxLicense ? 'global_popup add_license_active' : 'global_popup'}
                              onSubmit={handleSubmitLicense}>
                            <CloseIcon onclick={() => {
                                document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                                changeAddLicenseModal(false)
                            }}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Add License</span>
                            </div>
                            <div style={{paddingBottom: '16px', margin: '16px', borderBottom: '1px solid #ECECEC'}}>
                                <div className={'license_text'}>
                                    A license will be generated within the limit for your subscription plan. If you
                                    want
                                    to
                                    add a permanent license, please insert the activation code in the required
                                    field, if
                                    not
                                    just continue.
                                </div>
                                <CustomCheckbox id={'firstAdd'} focus={true} margin={'16px 0 0'} value={checkBoxLicense}
                                                onclick={() => {
                                                    changeCheckBoxLicense(!checkBoxLicense)
                                                    changeActivationCode('')
                                                }} title={'I have activation code'}/>
                                {checkBoxLicense ?
                                    <div className={'wrapper_input_activation_code'}>
                                        <TextInput title={'Activation code'} id={'activation_code'}
                                                   value={activationCode}
                                                   error={errorActivationCode || activationCodeError}
                                                   disabled={!checkBoxLicense}
                                                   onchange={e => {
                                                       changeActivationCode(e.target.value);
                                                       changeErrorActivationCode(false)
                                                   }}/>
                                    </div>
                                    : null}
                            </div>

                            <div style={{padding: '0 16px 0'}}>
                                <div style={{paddingTop: 0}} className={'edit_grant_wrapper'}>
                                    <span>Grants</span>
                                    <div className={'edit_grant'}>
                                        <div>
                                            <CustomCheckbox value={activeAddData.grants.includes('Management')}
                                                            onclick={() => {
                                                                let localArr = activeAddData;
                                                                if (activeAddData.grants.length > 0) {
                                                                    if (activeAddData.grants.includes('Management')) {
                                                                        activeAddData.grants.forEach((grant, index) => {
                                                                            if (grant === 'Management') {
                                                                                localArr.grants.splice(index, 1)
                                                                                setActiveAddData(prevState => ({
                                                                                    ...prevState,
                                                                                    grants: localArr.grants
                                                                                }))
                                                                            }
                                                                        })
                                                                    } else {
                                                                        localArr.grants.push('Management')
                                                                        setActiveAddData(prevState => ({
                                                                            ...prevState,
                                                                            grants: localArr.grants
                                                                        }))
                                                                    }
                                                                } else {
                                                                    localArr.grants.push('Management')
                                                                    setActiveAddData(prevState => ({
                                                                        ...prevState,
                                                                        grants: localArr.grants
                                                                    }))
                                                                }
                                                            }} title={'Management'}/>
                                            <span>
                                                Your device can Create, Update or Delete person’s data.
                                            </span>
                                        </div>
                                        <div>
                                            <CustomCheckbox value={activeAddData.grants.includes('Identification')}
                                                            onclick={() => {
                                                                let localArr = activeAddData;
                                                                if (activeAddData.grants.length > 0) {
                                                                    if (activeAddData.grants.includes('Identification')) {
                                                                        activeAddData.grants.forEach((grant, index) => {
                                                                            if (grant === 'Identification') {
                                                                                localArr.grants.splice(index, 1)
                                                                                setActiveAddData(prevState => ({
                                                                                    ...prevState,
                                                                                    grants: localArr.grants
                                                                                }))
                                                                            }
                                                                        })
                                                                    } else {
                                                                        localArr.grants.push('Identification')
                                                                        setActiveAddData(prevState => ({
                                                                            ...prevState,
                                                                            grants: localArr.grants
                                                                        }))
                                                                    }
                                                                } else {
                                                                    localArr.grants.push('Identification')
                                                                    setActiveAddData(prevState => ({
                                                                        ...prevState,
                                                                        grants: localArr.grants
                                                                    }))
                                                                }
                                                            }} title={'Identification'}/>
                                            <span>
                                                Your device can only Identify persons.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className={'edit_grant_wrapper'}>
                                    <span>Description</span>
                                    <div className={'edit_description'}>
                                        <TextInput
                                            title={'Add additional information that you think will be useful to you in the future.'}
                                            style={{marginTop: '10px'}}
                                            value={activeAddData.description}
                                            onchange={e => {
                                                let test = e.target.value
                                                if (test.length <= 50) setActiveAddData(prevState => ({
                                                    ...prevState,
                                                    description: test
                                                }))
                                            }}/>
                                    </div>
                                    {descriptionError ? <span className={'license_error'}>Max length of description is 50 symbols</span> : null}
                                </div>
                                <div className={'edit_tags_wrapper'}>
                                    <span>Tags</span>
                                    <div className={'edit_description'}>
                                        <span>Add tags and assign keywords that matter to you. Use tags to search and structure licenses.</span>
                                        <div>
                                            <TagsInput value={tagsAddArr.tags} maxTags={5} onChange={(tags) => {
                                                console.log(tags)
                                                setTagsAddArr(prevState => ({
                                                    ...prevState,
                                                    tags: tags
                                                }))
                                            }}
                                                       inputProps={{
                                                           className: 'react-tagsinput-input',
                                                           placeholder: tagsAddArr.tags.length <= 4 ? 'Add a tag + Enter' : '',
                                                           disabled: tagsAddArr.tags.length <= 4 ? false : true,
                                                       }}
                                                       inputValue={activeTagValue}
                                                       onChangeInput={value => {
                                                           if (value.length <= 10) {
                                                               setActiveTagValue(value)
                                                           }
                                                       }}
                                            />
                                        </div>
                                        {tagsError ?
                                            <span className={'license_error'}>Max amount of tags is 5</span> : null}
                                    </div>
                                </div>
                            </div>
                            <MainButton id={'lastAdd'} width={'155px'} margin={'25px 16px'}
                                        onclick={() => generateLicense()}
                                        button={'accent'} text={"Add License"}/>
                        </form>
                    </div>
                    : null}
                {editLicense ?
                    <div
                        ref={ref}
                        onClick={(e) => {
                            if (e.target === ref.current) {
                                changeEditLicense(false)
                            }
                        }}
                        className={'global_popup_wrapper'}>
                        <form style={{width: '705px'}} className={'global_popup'}
                              onSubmit={handleSubmitLicense}>
                            <CloseIcon onclick={() => changeEditLicense(false)}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Edit License</span>
                            </div>
                            <div className={'edit_license_content'}>
                                <div className={'edit_grant_wrapper'}>
                                    <span>Grants</span>
                                    <div className={'edit_grant'}>
                                        <div>
                                            <CustomCheckbox
                                                focus={true}
                                                id={'firstEdit'}
                                                value={activeEditData.grants && activeEditData.grants.length > 0 && activeEditData.grants.includes('Management')}
                                                onclick={() => {
                                                    let localArr = activeEditData;
                                                    if (activeEditData.grants.includes('Management')) {
                                                        activeEditData.grants.forEach((grant, index) => {
                                                            if (grant === 'Management') {
                                                                localArr.grants.splice(index, 1)
                                                                setActiveEditData(prevState => ({
                                                                    ...prevState,
                                                                    grants: localArr.grants
                                                                }))
                                                            }
                                                        })
                                                    } else {
                                                        localArr.grants.push('Management')
                                                        setActiveEditData(prevState => ({
                                                            ...prevState,
                                                            grants: localArr.grants
                                                        }))
                                                    }
                                                }} title={'Management'}/>
                                            <span>
                                                Your device can Create, Update or Delete person’s data.
                                            </span>
                                        </div>
                                        <div>
                                            <CustomCheckbox
                                                value={activeEditData.grants && activeEditData.grants.length > 0 && activeEditData.grants.includes('Identification')}
                                                onclick={() => {
                                                    let localArr = activeEditData;
                                                    if (activeEditData.grants.includes('Identification')) {
                                                        activeEditData.grants.forEach((grant, index) => {
                                                            if (grant === 'Identification') {
                                                                localArr.grants.splice(index, 1)
                                                                setActiveEditData(prevState => ({
                                                                    ...prevState,
                                                                    grants: localArr.grants
                                                                }))
                                                            }
                                                        })
                                                    } else {
                                                        localArr.grants.push('Identification')
                                                        setActiveEditData(prevState => ({
                                                            ...prevState,
                                                            grants: localArr.grants
                                                        }))
                                                    }
                                                }} title={'Identification'}/>
                                            <span>
                                                Your device can only Identify persons.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className={'edit_grant_wrapper'}>
                                    <span>Description</span>
                                    <div className={'edit_description'}>
                                        <TextInput
                                            title={'Add additional information that you think will be useful to you in the future.'}
                                            style={{marginTop: '10px'}}
                                            value={activeEditData.description}
                                            onchange={e => {
                                                let test = e.target.value
                                                if (test.length <= 50) setActiveEditData(prevState => ({
                                                    ...prevState,
                                                    description: test
                                                }))
                                            }}/>
                                    </div>
                                    {descriptionEditError ? <span className={'license_error'}>Max length of description is 50 symbols</span> : null}
                                </div>
                                <div className={'edit_tags_wrapper'}>
                                    <span>Tags</span>
                                    <div className={'edit_description'}>
                                        <span>Add tags and assign keywords that matter to you. Use tags to search and structure licenses.</span>
                                        <div>
                                            <TagsInput value={tagsArr.tags} maxTags={5} onChange={(tags) => {
                                                console.log(tags)
                                                setTagsArr(prevState => ({
                                                    ...prevState,
                                                    tags: tags
                                                }))
                                            }}
                                                       inputProps={{
                                                           className: 'react-tagsinput-input',
                                                           placeholder: tagsArr.tags.length <= 4 ? 'Add a tag + Enter' : '',
                                                           disabled: tagsArr.tags.length <= 4 ? false : true,
                                                       }}
                                                       inputValue={activeEditTagValue}
                                                       onChangeInput={value => {
                                                           if (value.length <= 10) {
                                                               setActiveEditTagValue(value)
                                                           }
                                                       }}
                                            />
                                        </div>
                                        {tagsEditError ?
                                            <span className={'license_error'}>Max amount of tags is 5</span> : null}
                                    </div>
                                </div>
                            </div>
                            <MainButton id={'lastEdit'} width={'110px'} margin={'20px 20px'} onclick={() => {
                                handleEdit()
                                changeEditLicense(false)
                            }} button={'accent'} text={"Save"}/>
                        </form>
                    </div>
                    : null}
                <div className={'licenses_header'}>
                    <h2 style={{marginBottom: "0"}} className={"children-wrapper__page-header"}>Licenses</h2>
                    <div className={'licenses_header_data'}>
                        <span>{limitsData?.maxDeviceKeysNumber} license available</span>
                        <span
                            style={{marginLeft: '14px'}}>{licensesUsed ?
                            licensesUsed + " licenses used"
                            : licenseArray?.keys?.length + ' licenses used'}</span>
                    </div>
                </div>
                <div className={'wrapper_license_header'}>
                    <div className={'search_input_wrapper'}>
                        <SearchInput placeholder={'Search by tags'}
                                     value={searchStr}
                                     onfocusin={() => setSearchFocus(true)}
                                     onfocusout={() => setSearchFocus(false)}
                                     onsubmit={() => {
                                         if (searchFocus && searchStr.length > 0) {
                                             setSearchData(prevProps => ([...prevProps, searchStr]))
                                             changeSearchStr("")
                                         }
                                     }}
                                     onchange={(e) => {
                                         changeSearchStr(e.target.value);
                                         if (e.target.value) {
                                             setSearchProtoArray([])
                                             licenseArray && licenseArray.keys.forEach((item, index) => {
                                                 if (e.target.value && item.tags.find(tag => tag.toLowerCase().includes(e.target.value.toLowerCase()))) {
                                                     setSearchProtoArray(prevState => ([...prevState, item]))
                                                 }
                                             })
                                         }
                                     }}/>
                        <img onClick={() => setFilterModal(!filterModal)} src={filterIcon} alt=""/>
                        {filterModal ?
                            <div ref={ref} className={'filters_wrapper'}>
                                <div>
                                    <span>By status</span>
                                    <CustomCheckbox value={filterData.Active} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            Active: !filterData.Active
                                        }))
                                    }} title={'Active'}/>
                                    <CustomCheckbox value={filterData.Pending} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            Pending: !filterData.Pending
                                        }))
                                    }} title={'Pending'}/>
                                    <CustomCheckbox value={filterData.Inactive} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            Inactive: !filterData.Inactive
                                        }))
                                    }} title={'Inactive'}/>
                                </div>
                                <div>
                                    <span>By grants</span>
                                    <CustomRadioButton value={filterData.AllGrants} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            AllGrants: !filterData.AllGrants,
                                            Management: false,
                                            Identification: false,
                                        }))
                                    }} title={'Full grants'}/>
                                    <CustomRadioButton value={filterData.Management} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            Management: !filterData.Management,
                                            Identification: false,
                                            AllGrants: false
                                        }))
                                    }} title={'Management'}/>
                                    <CustomRadioButton value={filterData.Identification} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            Identification: !filterData.Identification,
                                            Management: false,
                                            AllGrants: false
                                        }))
                                    }} title={'Identification'}/>
                                </div>
                                <div>
                                    <span>By type</span>
                                    <CustomRadioButton value={filterData.type === 0} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            type: 0
                                        }))
                                    }} title={'All types'}/>
                                    <CustomRadioButton value={filterData.type === 1} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            type: filterData.type === 1 ? 0 : 1
                                        }))
                                    }} title={'Subscription'}/>
                                    <CustomRadioButton value={filterData.type === 2} onclick={() => {
                                        setFilterData(prevState => ({
                                            ...prevState,
                                            type: filterData.type === 2 ? 0 : 2
                                        }))
                                    }} title={'Permanent'}/>
                                </div>
                            </div>
                            : null
                        }
                    </div>

                    <MainButton button={'add'} text={'Add license'}
                                style={{
                                    width: '150px',
                                    fontWeight: 'normal',
                                    marginLeft: 'auto'
                                }} onclick={() => {
                        changeAddLicenseModal(true)
                        document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
                    }}/>
                </div>


                <div className={'search_value'}>
                    {filterData.Active ?
                        <div style={{backgroundColor: '#0CCC92'}}>
                            <span>Active</span>
                            <img onClick={() => {
                                setFilterData(prevState => ({
                                    ...prevState,
                                    Active: false
                                }))
                            }
                            } src={deleteTagIcon} alt="delete"/>
                        </div>
                        : null}
                    {filterData.Pending ?
                        <div style={{backgroundColor: '#FFAB00'}}>
                            <span>Pending</span>
                            <img onClick={() => {
                                setFilterData(prevState => ({
                                    ...prevState,
                                    Pending: false
                                }))
                            }
                            } src={deleteTagIcon} alt="delete"/>
                        </div>
                        : null}
                    {filterData.Inactive ?
                        <div style={{backgroundColor: '#FE3E3E'}}>
                            <span>Inactive</span>
                            <img onClick={() => {
                                setFilterData(prevState => ({
                                    ...prevState,
                                    Inactive: false
                                }))
                            }
                            } src={deleteTagIcon} alt="delete"/>
                        </div>
                        : null}
                    {filterData.AllGrants ?
                        <div style={{backgroundColor: '#5C9BFF'}}>
                            <span>All Grants</span>
                            <img onClick={() => {
                                setFilterData(prevState => ({
                                    ...prevState,
                                    AllGrants: false
                                }))
                            }
                            } src={deleteTagIcon} alt="delete"/>
                        </div>
                        : null}
                    {filterData.Identification ?
                        <div style={{backgroundColor: '#5C9BFF'}}>
                            <span>Identification</span>
                            <img onClick={() => {
                                setFilterData(prevState => ({
                                    ...prevState,
                                    Identification: false
                                }))
                            }
                            } src={deleteTagIcon} alt="delete"/>
                        </div>
                        : null}
                    {filterData.Management ?
                        <div style={{backgroundColor: '#5C9BFF'}}>
                            <span>Management</span>
                            <img onClick={() => {
                                setFilterData(prevState => ({
                                    ...prevState,
                                    Management: false
                                }))
                            }
                            } src={deleteTagIcon} alt="delete"/>
                        </div>
                        : null}
                    {filterData.type !== 0 ?
                        <div style={{backgroundColor: '#5C9BFF'}}>
                            <span>{filterData.type === 1 ? 'Subscription' :
                                'Temporary'}</span>
                            <img onClick={() => {
                                setFilterData(prevState => ({
                                    ...prevState,
                                    type: 0
                                }))
                            }
                            } src={deleteTagIcon} alt="delete"/>
                        </div>
                        : null}
                    {searchData && searchData.map((tag, index) => {
                        return <div style={{backgroundColor: '#EAF6FD'}}>
                            <span style={{color: '#5C9BFF', borderRight: '1px solid #5C9BFF'}}>{tag}</span>
                            <img onClick={() => {
                                setSearchData(currentTag => currentTag.filter((tag, i) => i !== index));
                            }
                            } src={deleteTagIconBlue} alt="delete"/>
                        </div>
                    })

                    }
                </div>

                <div className={'wrapper_all_license'}>
                    {searchProtoArray && searchProtoArray.length > 0 ?
                        searchProtoArray.map((item, index) => {
                                let localGrants = [];
                                item.grants.forEach((grant, key) => {
                                    switch (grant) {
                                        case 'PERSON_MANAGER':
                                            localGrants.push('Management');
                                            break;
                                        case 'RECOGNITION':
                                            localGrants.push('Identification');
                                            break;
                                        case 'KEY_MANAGER':
                                            break;
                                        default:
                                            break;
                                    }
                                })
                                return item.type === 'PERMANENT' || item.type === 'SUBSCRIPTION' ?
                                    <div className={'wrapper_every_license'} key={index}>
                                        <div className={'every_license_header'}>
                                            <div className={'wrapper_all_block_titles'}>
                                                <span>Status</span>
                                                <span>Grants</span>
                                                <span>License type</span>
                                                <span>Description</span>
                                                <span>Tags</span>
                                            </div>
                                            <div className={'wrapper_all_block_values'}>
                                                <div>
                                                    {statusTooltip === index ?
                                                        <TooltipCustom style={{width: '180px'}}
                                                                       status={item.status.toLowerCase()}/>
                                                        : null}
                                                    <LicenseStatuses onMouseOut={() => setStatusTooltip(false)}
                                                                     onMouseOver={() => setStatusTooltip(index)}
                                                                     status={item.status.toLowerCase()}/>
                                                </div>
                                                <div style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, max-content)',
                                                    gridGap: '4px'
                                                }}>
                                                    {localGrants.length > 0 && localGrants.map((grants, grants_index) => {
                                                        if (localGrants.length - 1 === grants_index) {
                                                            return <div
                                                                style={{width: 'max-content'}}><span
                                                                onMouseOver={() => setGrantIdeTooltip(index)}
                                                                onMouseOut={() => setGrantIdeTooltip(false)}>
                                                                {grantIdeTooltip === index ?
                                                                    <TooltipCustom
                                                                        style={grants === 'Identification' ? {width: '243px'} :
                                                                            grants === 'Management' ? {width: '270px'} : null}
                                                                        grant={grants}/>
                                                                    : null}
                                                                {grants}</span>
                                                            </div>
                                                        } else {
                                                            return <div
                                                                style={{width: 'max-content'}}><span
                                                                onMouseOver={() => setMenIdeTooltip(index)}
                                                                onMouseOut={() => setMenIdeTooltip(false)}
                                                            >
                                                                {grantManTooltip === index ?
                                                                    <TooltipCustom
                                                                        style={grants === 'Identification' ? {width: '243px'} :
                                                                            grants === 'Management' ? {width: '270px'} : null}
                                                                        grant={grants}/>
                                                                    : null}
                                                                {grants + ', '}</span></div>
                                                        }
                                                    })}
                                                </div>
                                                <div>
                                                    {licenseTooltip === index ?
                                                        <TooltipCustom
                                                            style={item.type === 'PERMANENT' ? {width: '172px'} :
                                                                item.type === "SUBSCRIPTION" ? {width: '226px'} :
                                                                    item.type === 'TEMPORARY' ? {width: '216px'} :
                                                                        {width: '172px'}}
                                                            license={item.type === 'PERMANENT' ? 'Permanent' :
                                                                item.type === "SUBSCRIPTION" ? 'Subscription based' :
                                                                    item.type === 'TEMPORARY' ? 'Temporary' : ''}/>
                                                        : null}
                                                    <span
                                                        onMouseOver={() => setLicenseTooltip(index)}
                                                        onMouseOut={() => setLicenseTooltip(false)}
                                                    >{item.type === 'PERMANENT' ? 'Permanent' : 'Subscription'}</span>
                                                </div>
                                                <div>
                                                    {descriptionTooltip === index ?
                                                        <TooltipCustom
                                                            style={item.description.length < 45 ? {width: 'max-content'} : {width: '302px'}}
                                                            text={item.description}/>
                                                        : null}
                                                    <span onMouseOver={() => setDescriptionTooltip(index)}
                                                          onMouseOut={() => setDescriptionTooltip(false)}>{item.description && item.description.length >= 22 ? item.description.substring(0, 22) + '...' : item.description}</span>
                                                </div>
                                                <div>
                                                    {tagsTooltip === index ?
                                                        <TooltipCustom
                                                            text={item.tags.length > 0 && item.tags.map((grants, grants_index) => {
                                                                if (item.tags.length - 1 === grants_index) return grants
                                                                else return grants + ', '
                                                            })}/>
                                                        : null}
                                                    <div
                                                        onMouseOver={() => setTagsTooltip(index)}
                                                        onMouseOut={() => setTagsTooltip(false)}
                                                        className={'tags_wrapper'}>
                                                        {item.tags.length > 0 && item.tags.map((grants, grants_index) => {
                                                            return <span key={grants_index}>{grants}</span>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'blured_tags'}></div>
                                        </div>
                                        <div className={'wrapper_license_body'}>
                                            <MainButton img={copy_element} style={{
                                                width: '200px',
                                                textTransform: 'unset',
                                                fontWeight: 'normal'
                                            }} text={'Installation Command'} button={'download'}
                                                        onclick={() => {
                                                            setInstallationCode(item.accessKey)
                                                            setInstallationModal(true)
                                                        }}
                                            />
                                            <MainButton img={edit_element} style={{
                                                width: '140px',
                                                textTransform: 'unset',
                                                fontWeight: 'normal'
                                            }} text={'Edit License'} button={'download'}
                                                        onclick={() => {
                                                            console.log(localGrants)
                                                            setActiveEditData(prevState => ({
                                                                ...prevState,
                                                                grants: localGrants && localGrants.length > 0 ? localGrants : []
                                                            }));
                                                            setActiveEditData(prevState => ({
                                                                ...prevState,
                                                                description: item.description ? item.description : ''
                                                            }));
                                                            setTagsArr(prevState => ({
                                                                ...prevState,
                                                                tags: item.tags && item.tags.length > 0 ? item.tags : []
                                                            }));
                                                            setActiveEditData(prevState => ({
                                                                ...prevState,
                                                                accessKey: item.accessKey
                                                            }));
                                                            changeEditLicense(true);
                                                            console.log(item.accessKey)
                                                        }}/>
                                            <MainButton img={generate_key} style={{
                                                width: '160px',
                                                textTransform: 'unset',
                                                fontWeight: 'normal'
                                            }} text={'Regenerate Key'} button={'download'}
                                                        onclick={() => {
                                                            setRegenerateModal(true)
                                                            setRegenerateKey(item.accessKey)
                                                        }}/>
                                            <MainButton imgStyle={{marginRight: '4px'}} style={{
                                                width: '150px',
                                                textTransform: 'unset',
                                                fontWeight: 'normal'
                                            }}
                                                        onclick={() => {
                                                            setDeleteModal(true)
                                                            setDeleteKey(item.accessKey)
                                                        }}
                                                        margin={'0 0 0 auto'} text={'Delete license'} button={'delete'}/>
                                        </div>
                                    </div>
                                    : null
                            }
                        ) : null}{loader ?
                    <div className={'no_licenses_wrapper'}>
                        <img src={loaderGif} alt="loader"/>
                    </div>
                    : noLicenses && !loader ?
                        <div className={'no_licenses_wrapper'}>
                            <div>
                                <span className={'no_licenses_header'}>Add your first license now!</span>
                                <span className={'no_licenses_description'}>We use two types of licenses, <b
                                    style={{color: '#818181'}}> temporary </b> and <b
                                    style={{color: '#818181'}}>permanent</b>. To add any of them, click the <br/> "<b
                                    style={{color: '#818181'}}>Add license</b>" button.</span>
                                <span className={'no_licenses_description'}>A <b
                                    style={{color: '#818181'}}>temporary</b> license will be generated within the limit for your subscription plan.</span>
                                <span className={'no_licenses_description'}>If you want to add a <b
                                    style={{color: '#818181'}}>permanent</b> license, please check the box  “<b
                                    style={{color: '#818181'}}>I have activation code</b>”<br/> and provide your activation code below.</span>
                            </div>
                        </div>
                        : searchProtoArray.length === 0 && !loader ? <div className={'no_licenses_wrapper'}>
                                <div>
                                    <span
                                        className={'no_licenses_header'}>We Didn't find anything for your search request</span>
                                    <span className={'no_licenses_description'}>Add tags and assign keywords that matter to you. Use tags to search <br/> and structure licenses.</span>
                                    <span className={'no_licenses_description'}>For better search results, please change the tag or filter settings.</span>
                                </div>
                            </div>
                            : null}
                </div>
                {licenseArray && licenseArray.pagination.totalElements > 10 ?
                    <div>
                        <Pagination
                            activePage={!activePage ? 1 : activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Math.ceil(allItems)}
                            activeClass={'active_pagination'}
                            prevPageText={'PREV'}
                            nextPageText={'NEXT'}
                            lastPageText={'LAST'}
                            firstPageText={'FIRST'}
                            pageRangeDisplayed={5}
                            onChange={changePage.bind(this)}
                        />
                    </div>
                    : null}
            </div>
        </Layout>
    );
};

export default Licenses
