import React, {useEffect, useRef, useState} from "react";
import API from "../API";
import Layout from "../components/Layout";
import {withRouter} from "react-router-dom";
import {Keys, PASSWORD_REGEX} from "../helper";
import "../components/style/profile.scss"
import warning_icon from "../assets/warning.svg";
import Pricing from "./Pricing";
import TextInput from "../components/TextInput";
import MainButton from "../components/MainButton";
import CloseIcon from "../components/CloseIcon";
import attentionIcon from "../assets/attention_icon.svg";


const Profile = () => {
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [upgradePlan, setUpgradePlan] = useState(false);
    const [password, setOldPassword] = useState('');
    const [newPassword, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isErrorPasswordRepeat, setIsErrorPasswordRepeat] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [passwordRepeatMatch, setPasswordRepeatMatch] = useState(false);
    const [popupAlert, setPopupAlert] = useState(0);
    const [cancelSubscription, setCancelSubscription] = useState(false)

    let ref = useRef();

    let outsideClickDetect = e => {
        if (e.target === ref.current && deleteConfirm) {
            setDeleteConfirm(false)
        }
        else if (e.target === ref.current && cancelSubscription) {
            setCancelSubscription(false)
        }
    };

    useEffect(() => {
        if (deleteConfirm) {
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setDeleteConfirm(false)
                }
            })
        } else if (cancelSubscription) {
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setCancelSubscription(false)
                }
            })
        }

    }, [deleteConfirm, cancelSubscription]);

    const deleteAccount = () => {
        API.post("account/delete", {"accountKey": localStorage.getItem(Keys.ACCOUNT_KEY)}).then((res) => {
                localStorage.removeItem(Keys.ACCOUNT_KEY);
                localStorage.removeItem(Keys.AUTHORIZATION_TOKEN);
                window.location.pathname = "/login";
            }
        )
    };

    const checkPasswordMatch = (password, passwordRepeat) => {
        if (passwordRepeat.length === password.length) {
            if (passwordRepeat !== password) {
                setErrorCode("PMATCH");
                setIsErrorPassword(true);
                setIsErrorPasswordRepeat(true)
            } else {
                setErrorCode("");
                setIsErrorPassword(false);
                setIsErrorPasswordRepeat(false)
            }
        } else setErrorCode("");

        setPasswordMatch(PASSWORD_REGEX.test(password));
        setPasswordRepeatMatch(PASSWORD_REGEX.test(passwordRepeat))
    };

    const changePassword = (e) => {
        e.preventDefault();
        if (passwordRepeat !== newPassword) {
            setIsErrorPassword(true);
            setIsErrorPasswordRepeat(true);
            setErrorCode("PMATCH");
            return;
        }
        setErrorCode("");
        API.shouldIntercept = false;
        API.post("account/password/change", {
            accountKey: localStorage.getItem(Keys.ACCOUNT_KEY),
            newPassword: newPassword,
            password: password
        }).then(r => {
                setPopupAlert(1);
                API.shouldIntercept = true;
                setTimeout(() => {
                    localStorage.removeItem(Keys.ACCOUNT_KEY);
                    localStorage.removeItem(Keys.AUTHORIZATION_TOKEN);
                    window.location.pathname = "/login";
                }, 4000)

            }
        ).catch(err => {
            setErrorCode(err.response.data.code);
            switch (err.response.data.code) {
                case "R_003":
                    setIsErrorPassword(true);
                    setIsErrorPasswordRepeat(true);
                    break;
                case "AUTH_002":
                    break;
                default:
                    break;
            }
            API.shouldIntercept = true
        })
    };

    const cancelSubscriptionReq = () => {
        API.post("billing/cancel-active-subscription", {})
            .then(res => {
                setPopupAlert(1);
                document.location.reload();
            })
        setCancelSubscription(false)
    }

    useEffect(() => {
        if (upgradePlan) {
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                    setUpgradePlan(false)
                }
            })
        }
    }, [upgradePlan]);

    return (
        <Layout popupAlert={popupAlert} setPopupAlert={() => setPopupAlert(0)}>
            {upgradePlan ?
                <>
                    <CloseIcon onclick={() => {
                        document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                        setUpgradePlan(false)
                    }}
                               style={{
                                   position: 'fixed',
                                   top: '24px',
                                   right: '24px',
                                   zIndex: '1000000',
                                   cursor: 'pointer'
                               }}/>
                    <Pricing goToDashboard={() => setUpgradePlan(false)} fixed={true}/>
                </>
                : null
            }
            {/*<div className={"login__button"} onClick={() => deleteAccountTest()}>Delete account</div>*/}
            <div className={'wrapper_profile_information'} style={{gridTemplateColumns: '1fr'}}>
                <div style={{width: "auto", justifySelf: 'center'}}>
                    <h2 className={"children-wrapper__page-header"}>Account settings</h2>
                    <div style={{padding: '0', width: '466px', marginTop: 0}} className="login__wrapper">
                        <div className={'block_header'}>
                            <span>Change password</span>
                        </div>
                        <form style={{padding: '16px'}} className="login-form"
                              onSubmit={(event) => changePassword(event)}>
                            <TextInput title={'Old password'} type={"password"}
                                       margin={'0 0 5px 0'} value={password}
                                       required={true}
                                       onchange={e => {
                                           setOldPassword(e.target.value)
                                       }}/>

                            {errorCode === "AUTH_002" ?
                                <p>
                                    Wrong password
                                </p> : null}
                            {errorCode === "R_003" ?
                                <p className="login-form--error">
                                    Your password should contain 1 uppercase letter, 1 lowercase
                                    letter, and 1 digit.
                                    The minimum allowed length is 8 characters. </p> : null}

                            <TextInput title={'New password'} type={"password"}
                                       margin={'5px 0 5px 0'} value={newPassword}
                                       error={isErrorPassword}
                                       required={true}
                                       onchange={e => {
                                           setIsErrorPassword(false);
                                           setIsErrorPasswordRepeat(false);
                                           setPassword(e.target.value);
                                           checkPasswordMatch(e.target.value, passwordRepeat);
                                       }}/>

                            {errorCode === "R_003" || errorCode === "BO_005" ?
                                <p className="login-form--error">
                                    {/*Your password should contain 1 uppercase letter, 1 lowercase*/}
                                    {/*letter, and 1 digit.*/}
                                    {/*The minimum allowed length is 8 characters.*/}
                                </p>
                                :
                                passwordMatch ? <p className="login-form__input--match">
                                    Password is good.
                                </p> : null}
                            <TextInput title={'Confirm new password'} type={"password"}
                                       value={passwordRepeat}
                                       error={isErrorPasswordRepeat}
                                       required={true}
                                       onchange={e => {
                                           setIsErrorPassword(false);
                                           setIsErrorPasswordRepeat(false);
                                           setPasswordRepeat(e.target.value)
                                           checkPasswordMatch(newPassword, e.target.value);
                                       }}/>

                            {errorCode === "PMATCH" ? <p className="login-form--error">
                                    Password don't match
                                </p> :
                                passwordRepeatMatch ? <p className="login-form__input--match">
                                    Password is good.
                                </p> : null}
                            <MainButton width={'155px'} margin={'25px 0 9px'} button={'accent'} type={'submit'} text={"Change"}/>
                        </form>

                    </div>
                    {deleteConfirm ?
                        <div className={'global_popup_wrapper'} ref={ref} onClick={outsideClickDetect}>
                            <div className={'global_popup'}>
                                <CloseIcon onclick={() => setDeleteConfirm(false)}/>
                                <div className={'wrapper_title_of_popup'}>
                                    <span>Delete account</span>
                                </div>
                                <div className={'description_wrapper'}>
                                    <span>Are you sure you want to delete account?</span>
                                </div>
                                <div className={'warning_wrapper'}>
                                    <img src={warning_icon} alt="warning_icon"/>
                                    <span>Your access to the service wil be blocked.</span>
                                </div>
                                <div className={'wrapper_btn'}>
                                    <MainButton onclick={()=>setDeleteConfirm(false)} button={'accent'} text={"Keep going"}/>
                                    <MainButton onclick={()=> deleteAccount()} button={'danger'} type={'submit'} text={"Delete"}/>
                                </div>
                            </div>
                        </div>
                        : null}

                    {cancelSubscription ?
                        <div className={'global_popup_wrapper'} ref={ref} onClick={outsideClickDetect}>
                            <div className={'global_popup'}>
                                <CloseIcon onclick={() => setCancelSubscription(false)}/>
                                <div className={'wrapper_title_of_popup'}>
                                    <span>Cancel subscription</span>
                                </div>
                                <div className={'description_wrapper'}>
                                    <span>Are you sure you want to cancel subscription?</span>
                                </div>
                                <div className={'warning_wrapper'}>
                                    <img src={attentionIcon} alt="warning_icon"/>
                                    <span style={{color: '#FFAB00'}}>Your permanent licenses will be deleted.</span>
                                </div>
                                <div className={'wrapper_btn'}>
                                    <MainButton onclick={()=>setCancelSubscription(false)} button={'accent'} text={"Keep going"}/>
                                    <MainButton onclick={() => cancelSubscriptionReq()} width={'215px'} button={'warning'} type={'submit'} text={"Cancel subscription"}/>
                                </div>
                            </div>
                        </div>
                        : null}

                    <div style={{padding: '0', gridGap: '0'}} className={'delete_profile__wrapper'}>
                        <div className={'block_header'}>
                  <span>
                      Cancel subscription
                  </span>
                        </div>
                        <div style={{padding: '16px'}}>
                            <span className={'delete_profile__description'}>Your data will not be deleted but the plan will no longer be available for use. Data is stored for 3 months and unless the payment is not proceeded it will be deleted.</span>
                            <br/>
                            <MainButton onclick={()=> setCancelSubscription(true)} width={'220px'} margin={'25px 0 9px'} button={'warning'} text={"Cancel subscription"}/>
                        </div>
                    </div>
                    <div style={{padding: '0', gridGap: '0'}} className={'delete_profile__wrapper'}>
                        <div className={'block_header'}>
                  <span>
                      Delete account
                  </span>
                        </div>
                        <div style={{padding: '16px'}}>
                        <span
                            className={'delete_profile__description'}>Your access to the account will be lost. Account data will be stored for the next month with a chance of recovery. To recover your "Client" account you need to submit a corresponding application.</span>
                            <MainButton width={'155px'} margin={'25px 0 9px'} onclick={()=>setDeleteConfirm(true)} button={'danger'} text={"Delete account"}/>
                        </div>
                    </div>
                </div>
                {/*<div className={'activate_license_wrapper'}>*/}
                {/*    <Plan upgradePlan={() => setUpgradePlan(true)}/>*/}
                {/*</div>*/}
            </div>
        </Layout>);
};

export default withRouter(Profile)
