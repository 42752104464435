import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import ProtectedAdminRoute from './ProtectedAdminRoute';

import Login from './pages/Login';
import Profile from './pages/Profile';
import ValidateSignup from './pages/ValidateSignup';
import ProfileUpdate from './pages/ProfileUpdate.js';
import Licenses from './pages/Licenses.js';
import Dashboard from './pages/Dashboard';
import Downloads from './pages/Downloads';
import Pricing from './pages/Pricing';
import Keys from './pages/Keys';
import PaymentTest from './pages/PaymentTest.js';
import Settings from './pages/Settings';
import Billing from './pages/Billing';
import { SignupPage } from './pages/Signup';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={SignupPage} />
      <Route
        exact
        path="/backoffice/registration/redirect/set"
        component={ValidateSignup}
      />
      <Route
        exact
        path="/backoffice/account/password/recovery/redirect/set"
        component={ValidateSignup}
      />
      <ProtectedAdminRoute path="/profile-update" component={ProfileUpdate} />
      <ProtectedAdminRoute exact path="/payment-test" component={PaymentTest} />
      <ProtectedAdminRoute exact path="/dashboard" component={Dashboard} />
      <ProtectedAdminRoute exact path="/keys" component={Keys} />
      <ProtectedAdminRoute exact path="/billing" component={Billing} />
      <ProtectedAdminRoute exact path="/licenses" component={Licenses} />
      <ProtectedAdminRoute exact path="/pricing" component={Pricing} />
      <ProtectedAdminRoute exact path="/download" component={Downloads} />
      <ProtectedAdminRoute exact path="/profile" component={Profile} />
      <ProtectedAdminRoute exact path="/settings" component={Settings} />
      <ProtectedAdminRoute
        exact
        path="/admin/events/:id/:id?"
        component={Login}
      />
      <ProtectedAdminRoute
        exact
        path="/admin/my-events/:id/:id?"
        component={Login}
      />
      <Redirect to="/404" />
    </Switch>
  </BrowserRouter>
);
export default Router;
