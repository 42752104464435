import React from 'react';
import "../components/style/login.scss"
import LoginLayout from "../components/LoginLayout";
import ProfileUpdateComponent from "../components/ProfileUpdateComponent";
import loaderGif from "../assets/loader.gif";

class ProfileUpdate extends React.Component {
    state = {
        shouldLoad: false
    }


    render() {
        return (
            <LoginLayout

                setIsLogIn={() => {
                window.location = "/login"
            }}
                         isLogIn={0}>
                { !this.state.shouldLoad ?
                <div className={'loader_wrapper'}>
                    <img src={loaderGif} alt="loader"/>
                </div>
                    : null }
             <ProfileUpdateComponent
                 setShouldLoad={(val) => {
                     this.setState({
                         shouldLoad: val
                     })
                 }}
                 redirectToPayment={()=>{
                     window.location="/dashboard"
                 }}
                 updateProfile={true}/>

            </LoginLayout>
        )
    }
}

export default (ProfileUpdate)
