import React, {useEffect, useRef, useState} from "react";
// import API from "../API";
import Layout from "../components/Layout";
import {withRouter} from "react-router-dom";
import API from "../API";
import {Keys} from "../helper";
import MainButton from "../components/MainButton";
import CloseIcon from "../components/CloseIcon";
import CustomCheckbox from "../components/CustomCheckbox";
import TextInput from "../components/TextInput";
import useOutsideClick from "../components/useOutsideClick";
import edit_element from "../assets/edit_element.svg";
import stopIcon from "../assets/stop_icon.svg";
import TooltipCustom from "../components/TooltipCustom";
import Pagination from "react-js-pagination";
import copyIconPopup from "../assets/copy_command_wrapper.svg";
import loaderGif from '../assets/loader.gif'

let style = {
    everyBlock: {
        background: '#fff',
        border: '1px solid #EBECF0',
        boxSizing: 'border-box',
        borderRadius: '5px',
        width: '875px',
    },
    lastBlock: {
        marginTop: '61px'
    },
    titleOfBlockText: {
        fontStyle: 'normal',
        fontSize: '13px',
        marginTop: '8px',
        lineHeight: '100%',
        color: '#333333'
    },
    wrapper_info_block: {
        display: 'grid',
        gridTemplateColumns: '340px 180px 1fr',
        padding: '16px',
        gridGap: '0 38px'
    },
    titleOfBlock: {
        padding: '16px 16px 8px'
    },
    descriptionOfBlock: {
        marginTop: '17px',
        padding: '0 16px'
    },
    descriptionOfBlockText: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        color: '#4E5D78',

    },
    textLinkWrapper: {
        padding: '4px',
        display: 'flex',
        borderTop: '1px solid rgba(0,0,0, .1)'
    },
    textLink: {
        cursor: 'pointer',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        color: '#5C9BFF',
        paddingLeft: '12px'
    },
};

const KeysPage = () => {
    const [data, changeData] = useState(null);
    const [popupAlert, setPopupAlert] = useState('')
    const [popupAlertText, setPopupAlertText] = useState('')
    const [addLicenseModal, changeAddLicenseModal] = useState(false)
    const [activeAddData, setActiveAddData] = useState({grants: ['Identification', 'Management'], description: ""})
    const [descriptionError, setDescriptionError] = useState(false)
    const [descriptionEditError, setDescriptionEditError] = useState(false)
    const [activeEditData, setActiveEditData] = useState({grants: [], description: "", accessKey: null})
    const [editLicense, changeEditLicense] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [downloadKey, setDownloadKey] = useState(false)
    const [deleteKey, setDeleteKey] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [descriptionTooltip, setDescriptionTooltip] = useState()
    const [grantIdeTooltip, setGrantIdeTooltip] = useState()
    const [grantManTooltip, setMenIdeTooltip] = useState()
    const [idTooltip, setIdTooltip] = useState()
    const [allItems, setAllItems] = useState()
    const [accessKey, setAccessKey] = useState()
    const [fullKey, setFullKey] = useState()
    const [loader, setLoader] = useState(true)

    let ref = useRef();

    useOutsideClick(ref, () => {
        console.log(ref)
        if (editLicense) {
            changeEditLicense(false)
        }
        else if (deleteModal) {
            setDeleteModal(false)
        }
        else if (downloadKey) {
            setDownloadKey(false)
        }
    });


    useEffect(() => {
        if (addLicenseModal) {
            document.getElementById('lastAdd').addEventListener('focusout', () => {
                document.getElementById('firstAdd').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    changeAddLicenseModal(false)
                }
            })
        } else if (editLicense) {
            console.log('1')
            document.getElementById('lastEdit').addEventListener('focusout', () => {
                document.getElementById('firstEdit').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    changeEditLicense(false)
                }
            })
        } else if (deleteModal) {
            console.log('1')
            document.getElementById('lastDel').addEventListener('focusout', () => {
                document.getElementById('firstDel').focus()
            })
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setDeleteModal(false)
                }
            })
        } else if (downloadKey) {
            if(fullKey) {
                document.getElementById('lastDown').addEventListener('focusout', () => {
                    document.getElementById('firstDown').focus()
                })
            }
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    setDownloadKey(false)
                }
            })
        }
    }, [addLicenseModal, editLicense, deleteModal, downloadKey, fullKey]);

    useEffect(() => {

        if (activeAddData.description.length >= 50) {
            console.log('1')
            setDescriptionError(true)
        } else setDescriptionError(false)

        if (activeEditData.description.length >= 50) {
            console.log('1')
            setDescriptionEditError(true)
        } else setDescriptionEditError(false)


    }, [activeAddData, activeEditData])

    useEffect(() => {
        getKeys()
        // eslint-disable-next-line
    }, [activePage]);

    const getKeys = () => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        API.post('/key/list', {
            "accountKey": accountKey,
            types: ['API'],
            pagination: {pageSize: 10, pageNumber: activePage - 1},
            grants: [],
            statuses: [],
            tags: []
        })
            .then(res => {
                setAllItems(res.data.pagination.totalElements)
                console.log(res)
                res.data.keys.forEach((key, index) => {
                    let newArr = []
                    key.grants.forEach((grant, indx) => {
                        if (grant === 'PERSON_MANAGER') {
                            newArr.push("Management")
                        } else if (grant === 'RECOGNITION') {
                            newArr.push('Identification')
                        }
                    })
                    res.data.keys[index].grants = newArr
                })
                changeData(res.data)
                setLoader(false)
            })
    }

    const changePage = (page) => {
        setActivePage(page)
        console.log(page)
    }

    // const regenerateLicense = (accessKey) => {
    //     const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
    //     API.post('/key/regenerate', {keyIdentifier: accessKey, accountKey: accountKey})
    //         .then(res => {
    //             console.log(res)
    //             setPopupAlert(1);
    //             setPopupAlertText('Success')
    //             API.post('/key/list', {"accountKey": accountKey})
    //                 .then(res => {
    //                     changeData(res.data)
    //                 })
    //
    //         })
    //         .catch(err => {
    //             if (err.response.status) {
    //                 setPopupAlert(3);
    //             }
    //         })
    // }

    const deleteLicense = (accessKey) => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        API.post('/key/delete', {accessKey: accessKey, accountKey: accountKey})
            .then(res => {
                setPopupAlert(1);
                setPopupAlertText('Success')
                setDeleteModal(false)
                getKeys()
            })
            .catch(err => {
                if (err.response.status) {
                    setPopupAlert(3);
                }
            })
    }

    const generateLicense = () => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        let localArr = activeAddData.grants
        localArr.forEach((grant, index) => {
            if (grant === 'Management') {
                localArr[index] = "PERSON_MANAGER"
            } else if (grant === 'Identification') {
                localArr[index] = 'RECOGNITION'
            }
        })
        API.post('/key/generate', {
            accountKey: accountKey,
            grants: localArr,
            description: activeAddData.description,
            type: 'API'
        })
            .then(res => {
                console.log(res)
                localArr = []
                changeAddLicenseModal(false)
                setPopupAlert(1);
                setActiveAddData({grants: ['Identification', 'Management'], description: ""})
                getKeys()
            })
            .catch(err => {
                console.log(err.response.data.message);
                changeAddLicenseModal(false)
                localArr = []
                setPopupAlert(1);
                setActiveAddData({grants: ['Identification', 'Management'], description: ""})
                if (err.response.status) {
                    setPopupAlertText(err.response.data.message)
                    setPopupAlert(3);
                }
            })
    }

    const handleEdit = () => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);

        let localArr = activeEditData.grants
        localArr.forEach((grant, index) => {
            if (grant === 'Management') {
                localArr[index] = "PERSON_MANAGER"
            } else if (grant === 'Identification') {
                localArr[index] = 'RECOGNITION'
            }
        })


        API.post('/key/edit', {
            accessKey: activeEditData.accessKey,
            accountKey: accountKey,
            description: activeEditData.description,
            grants: localArr,
        })
            .then(res => {
                setPopupAlert(1);
                setPopupAlertText('Success')
                console.log(localArr)
                console.log(res)
                getKeys()
            })
    }


    const downloadLicense = (accessKey) => {
        // const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        API.get(`/key/download/${accessKey}`)
            .then(res => {
                setFullKey(res.data)
                // setPopupAlert(1);
                // setPopupAlertText('Success')
            })
            .catch(err => {
                if (err.response.status && err.response.data.code !== 'OE_001' && err.response.status !== 400) {
                    setPopupAlert(3);
                }
            })
    }


    const copyInstallation = (accessKey, type) => {
        if (type === 'access key') {
            navigator.clipboard.writeText(`${accessKey}`).then(function () {
                setPopupAlert(1);
                setPopupAlertText('Copied successfully')
            }, function (err) {
                setPopupAlert(3);
                setPopupAlertText('Some error occurred')
            });
        } else if (type === 'full key') {
            navigator.clipboard.writeText(accessKey).then(function () {
                setPopupAlert(1);
                setPopupAlertText('Copied successfully')
            }, function (err) {
                setPopupAlert(3);
                setPopupAlertText('Some error occurred')
            });
        }
    }


    let handleSubmitLicense = e => {
        e.preventDefault();
    };

    return (
        <Layout popupAlert={popupAlert} popupAlertText={popupAlertText} setPopupAlertText={() => setPopupAlertText('')}
                setPopupAlert={() => setPopupAlert(0)}>
            <div style={{display: 'grid', margin: 'auto', width: '875px', gridGap: '17px'}}>
                {deleteModal ?
                    <div className={'global_popup_wrapper'}>
                        <form style={{width: '475px'}} className={'global_popup'}
                              ref={ref}
                              onSubmit={handleSubmitLicense}
                        >
                            <CloseIcon onclick={() => setDeleteModal(false)}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Delete API Key</span>
                            </div>
                            <div className={'delete_license_content'}>
                                <span className={'license_text'}>Are you sure, want to delete key ?</span>
                                <div className={'warning_block'}>
                                    <img src={stopIcon} alt=""/>
                                    <span>Your access to the API will be blocked.</span>
                                </div>
                                <div className={'delete_buttons'}>
                                    <MainButton autofocus={true} button={'accent'} id={'firstDel'} width={'155px'}
                                                text={"Keep going"}
                                                onclick={() => setDeleteModal(false)}/>
                                    <MainButton button={'danger'} width={'155px'} id={'lastDel'} text={"Delete"}
                                                onclick={() => deleteLicense(deleteKey)}/>
                                </div>
                            </div>
                        </form>
                    </div>
                    : null
                }
                {downloadKey ?
                    <div className={'global_popup_wrapper'}>
                        <form style={{width: '705px'}} className={'global_popup'}
                              ref={ref}
                              onSubmit={handleSubmitLicense}
                        >
                            <CloseIcon
                                onclick={() => setDownloadKey(false)}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Get API Key</span>
                            </div>
                            <div className={'delete_license_content'}>
                                <span style={{color: '#818181', fontSize: '13px'}} className={'license_text'}>Copy the API key or download it as a file and use it to sign requests. For more detailed information, study the technical documentation in the section Developers.</span>
                                <span style={{marginBottom: '-22px', fontSize: '13px', color: '#4E5D78'}}>API key</span>
                                <div className={'installation_input_wrapper'}>
                                    <div style={{height: '45px', background: '#EAF6FD', border: '1px solid #E2E4E9'}} className={'installation_input'}>
                                        <span style={{color: '#4E5D78'}}>{accessKey}</span>
                                    </div>
                                    <MainButton
                                        wrapperStyle={{display: 'grid'}}
                                        autofocus={true}
                                        id={'firstDown'}
                                        onclick={() => copyInstallation(accessKey, 'access key')}
                                        img={copyIconPopup} style={{
                                        margin: 'auto',
                                        width: '77px',
                                        textTransform: 'unset',
                                        fontWeight: 'normal'
                                    }} text={'Copy'} button={'download'}/>
                                </div>
                                {fullKey ?
                                    <>
                                <span style={{marginBottom: '-22px', fontSize: '13px', color: '#4E5D78'}}>Signing Key</span>
                                <div className={'installation_input_wrapper'}>
                                    <div style={{height: '171px', background: '#EAF6FD', border: '1px solid #E2E4E9'}} className={'installation_input'}>
                                        <span style={{color: '#4E5D78'}}>{fullKey}</span>
                                    </div>
                                    <MainButton
                                        wrapperStyle={{display: 'grid'}}
                                        onclick={() => copyInstallation(fullKey, 'full key')}
                                        img={copyIconPopup} style={{
                                        margin: 'auto',
                                        width: '77px',
                                        textTransform: 'unset',
                                        fontWeight: 'normal'
                                    }} text={'Copy'} button={'download'}/>
                                </div>
                                        <div className={'delete_buttons'}>
                                            <MainButton id={'lastDown'} button={'accent'} width={'262px'} text={"download api key as file"}
                                                        onclick={() => {
                                                            setDownloadKey(false)
                                                            API.get(`${process.env.REACT_APP_API_URL}/key/download/${accessKey}`)
                                                                .then(res => {
                                                                    console.log(res)
                                                                })
                                                                .catch(res => {
                                                                    if (res.response.status) {
                                                                        setPopupAlertText(res.response.data.message)
                                                                        setPopupAlert(3);
                                                                    }
                                                                })
                                                        }}/>
                                        </div>
                                    </>
                                    : null }
                            </div>
                        </form>
                    </div>
                    : null
                }
                {editLicense ?
                    <div className={'global_popup_wrapper'}>
                        <form style={{width: '705px'}} className={'global_popup'}
                              ref={ref}
                              onSubmit={handleSubmitLicense}
                        >
                            <CloseIcon onclick={() => changeEditLicense(false)}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Edit API Key</span>
                            </div>
                            <div style={{padding: '20px 20px 0'}} className={'edit_license_content'}>
                                <div style={{padding: '0 0 18px'}} className={'edit_grant_wrapper'}>
                                    <span>Grants</span>
                                    <div className={'edit_grant'}>
                                        <div>
                                            <CustomCheckbox
                                                focus={true}
                                                id={'firstEdit'}
                                                value={activeEditData.grants && activeEditData.grants.length > 0 && activeEditData.grants.includes('Management')}
                                                onclick={() => {
                                                    let localArr = activeEditData;
                                                    if (activeEditData.grants.includes('Management')) {
                                                        activeEditData.grants.forEach((grant, index) => {
                                                            if (grant === 'Management') {
                                                                localArr.grants.splice(index, 1)
                                                                setActiveEditData(prevState => ({
                                                                    ...prevState,
                                                                    grants: localArr.grants
                                                                }))
                                                            }
                                                        })
                                                    } else {
                                                        localArr.grants.push('Management')
                                                        setActiveEditData(prevState => ({
                                                            ...prevState,
                                                            grants: localArr.grants
                                                        }))
                                                    }
                                                }} title={'Management'}/>
                                            <span>
                                                Your API Key can Create, Update or Remove persons.
                                            </span>
                                        </div>
                                        <div>
                                            <CustomCheckbox
                                                value={activeEditData.grants && activeEditData.grants.length > 0 && activeEditData.grants.includes('Identification')}
                                                onclick={() => {
                                                    let localArr = activeEditData;
                                                    if (activeEditData.grants.includes('Identification')) {
                                                        activeEditData.grants.forEach((grant, index) => {
                                                            if (grant === 'Identification') {
                                                                localArr.grants.splice(index, 1)
                                                                setActiveEditData(prevState => ({
                                                                    ...prevState,
                                                                    grants: localArr.grants
                                                                }))
                                                            }
                                                        })
                                                    } else {
                                                        localArr.grants.push('Identification')
                                                        setActiveEditData(prevState => ({
                                                            ...prevState,
                                                            grants: localArr.grants
                                                        }))
                                                    }
                                                }} title={'Identification'}/>
                                            <span>
                                                Your API Key can only Identify persons.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{padding: '18px 0 0', borderBottom: 'none'}}
                                     className={'edit_grant_wrapper'}>
                                    <span>Description</span>
                                    <div className={'edit_description'}>
                                        <TextInput
                                            title={'Add additional information that you think will be useful to you in the future.'}
                                            style={{marginTop: '10px'}}
                                            value={activeEditData.description}
                                            onchange={e => {
                                                let test = e.target.value
                                                if (test.length <= 50) setActiveEditData(prevState => ({
                                                    ...prevState,
                                                    description: test
                                                }))
                                            }}/>
                                    </div>
                                    {descriptionEditError ? <span className={'license_error'}>Max length of description is 50 symbols</span> : null}
                                </div>

                            </div>
                            <MainButton id={'lastEdit'} width={'155px'} margin={'20px 20px'} onclick={() => {
                                handleEdit()
                                changeEditLicense(false)
                            }} button={'accent'} text={"Save API Key"}/>
                        </form>
                    </div>
                    : null}
                {addLicenseModal ?
                    <div
                        ref={ref}
                        onClick={(e) => {
                            if (e.target === ref.current) {
                                changeAddLicenseModal(false)
                            }
                        }}
                        className={'global_popup_wrapper'}>
                        <form style={{width: '705px'}} id={'add_license'} className={'global_popup'}
                              onSubmit={handleSubmitLicense}
                        >
                            <CloseIcon onclick={() => {
                                changeAddLicenseModal(false)
                            }}/>
                            <div className={'wrapper_title_of_popup'}>
                                <span>Add API Key</span>
                            </div>

                            <div style={{padding: '20px 20px 0'}}>
                                <div style={{paddingTop: 0}} className={'edit_grant_wrapper'}>
                                    <span>Grants</span>
                                    <div className={'edit_grant'}>
                                        <div>
                                            <CustomCheckbox value={activeAddData.grants.includes('Management')}
                                                            id={'firstAdd'}
                                                            onclick={() => {
                                                                let localArr = activeAddData;
                                                                if (activeAddData.grants.length > 0) {
                                                                    if (activeAddData.grants.includes('Management')) {
                                                                        activeAddData.grants.forEach((grant, index) => {
                                                                            if (grant === 'Management') {
                                                                                localArr.grants.splice(index, 1)
                                                                                setActiveAddData(prevState => ({
                                                                                    ...prevState,
                                                                                    grants: localArr.grants
                                                                                }))
                                                                            }
                                                                        })
                                                                    } else {
                                                                        localArr.grants.push('Management')
                                                                        setActiveAddData(prevState => ({
                                                                            ...prevState,
                                                                            grants: localArr.grants
                                                                        }))
                                                                    }
                                                                } else {
                                                                    localArr.grants.push('Management')
                                                                    setActiveAddData(prevState => ({
                                                                        ...prevState,
                                                                        grants: localArr.grants
                                                                    }))
                                                                }
                                                            }} title={'Management'}/>
                                            <span>
                                                Your API Key can Create, Update or Remove person.
                                            </span>
                                        </div>
                                        <div>
                                            <CustomCheckbox value={activeAddData.grants.includes('Identification')}
                                                            onclick={() => {
                                                                let localArr = activeAddData;
                                                                if (activeAddData.grants.length > 0) {
                                                                    if (activeAddData.grants.includes('Identification')) {
                                                                        activeAddData.grants.forEach((grant, index) => {
                                                                            if (grant === 'Identification') {
                                                                                localArr.grants.splice(index, 1)
                                                                                setActiveAddData(prevState => ({
                                                                                    ...prevState,
                                                                                    grants: localArr.grants
                                                                                }))
                                                                            }
                                                                        })
                                                                    } else {
                                                                        localArr.grants.push('Identification')
                                                                        setActiveAddData(prevState => ({
                                                                            ...prevState,
                                                                            grants: localArr.grants
                                                                        }))
                                                                    }
                                                                } else {
                                                                    localArr.grants.push('Identification')
                                                                    setActiveAddData(prevState => ({
                                                                        ...prevState,
                                                                        grants: localArr.grants
                                                                    }))
                                                                }
                                                            }} title={'Identification'}/>
                                            <span>
                                                Your API Key can only Identify person.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{padding: '18px 0 0', borderBottom: 'none'}}
                                     className={'edit_grant_wrapper'}>
                                    <span>Description</span>
                                    <div className={'edit_description'}>
                                        <TextInput
                                            title={'Add additional information that you think will be useful to you in the future.'}
                                            style={{marginTop: '10px'}}
                                            value={activeAddData.description}
                                            onchange={e => {
                                                let test = e.target.value
                                                if (test.length <= 50) setActiveAddData(prevState => ({
                                                    ...prevState,
                                                    description: test
                                                }))
                                            }}/>
                                    </div>
                                    {descriptionError ? <span className={'license_error'}>Max length of description is 50 symbols</span> : null}
                                </div>
                            </div>
                            <MainButton id={'lastAdd'} width={'155px'} margin={'25px 20px'}
                                        onclick={() => generateLicense()}
                                        button={'accent'} text={"Add API Key"}/>
                        </form>
                    </div>
                    : null}
                <div className={'wrapper_profile_information'} style={{gridTemplateColumns: '1fr 1fr'}}>
                    <h2 style={{marginBottom: 0}} className={"children-wrapper__page-header"}>API Keys</h2>
                    <MainButton button={'add'} text={'Add API Key'}
                                style={{
                                    width: '148px',
                                    marginLeft: 'auto'
                                }} onclick={() => {
                        changeAddLicenseModal(true)
                    }}/>
                </div>
                {data && data.keys.length > 0 ? data.keys.map((item, index) => {
                        let localGrants = [];
                        item.grants.forEach((grant, key) => {
                            localGrants.push(grant)

                            // switch (grant) {
                            //     case 'KEY_MANAGER':
                            //         localGrants.push('Management');
                            //         break;
                            //     case 'RECOGNITION':
                            //         localGrants.push('Identification');
                            //         break;
                            //     case 'PERSON_MANAGER':
                            //         break;
                            //     default:
                            //         break;
                            // }

                        })

                        return <div style={style.everyBlock} key={index}>
                            <div style={style.wrapper_info_block}>
                                <span style={style.descriptionOfBlockText}>API key</span>
                                <span style={style.descriptionOfBlockText}>Grants</span>
                                <span style={style.descriptionOfBlockText}>Description</span>
                                <div style={{position: 'relative'}}>
                                    {idTooltip === index ?
                                        <TooltipCustom style={{width: '234px'}}
                                                       text={'This is your unique API Key identifier.'}/>
                                        : null}
                                    <span
                                        onMouseOver={() => setIdTooltip(index)}
                                        onMouseOut={() => setIdTooltip(false)}
                                        style={style.titleOfBlockText}>{item.accessKey}</span>
                                </div>
                                <div style={{
                                    position: 'relative',
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2, max-content)',
                                    gridGap: '4px'
                                }}>
                                    {localGrants.length > 0 && localGrants.map((grants, grants_index) => {
                                        if (localGrants.length - 1 === grants_index) {
                                            return <div
                                                style={{position: 'relative', width: 'max-content'}}><span
                                                style={style.titleOfBlockText}
                                                onMouseOver={() => setGrantIdeTooltip(index)}
                                                onMouseOut={() => setGrantIdeTooltip(false)}>
                                                                {grantIdeTooltip === index ?
                                                                    <TooltipCustom
                                                                        style={grants === 'Identification' ? {width: '243px'} :
                                                                            grants === 'Management' ? {width: '270px'} : null}
                                                                        grant={grants + 'Key'}/>
                                                                    : null}
                                                {grants}</span>
                                            </div>
                                        } else {
                                            return <div
                                                style={{position: 'relative', width: 'max-content'}}><span
                                                style={style.titleOfBlockText}
                                                onMouseOver={() => setMenIdeTooltip(index)}
                                                onMouseOut={() => setMenIdeTooltip(false)}
                                            >
                                                                {grantManTooltip === index ?
                                                                    <TooltipCustom
                                                                        style={grants === 'Identification' ? {width: '243px'} :
                                                                            grants === 'Management' ? {width: '270px'} : null}
                                                                        grant={grants + 'Key'}/>
                                                                    : null}
                                                {grants + ', '}</span></div>
                                        }
                                    })}
                                </div>
                                <div style={{position: 'relative'}}>
                                    {descriptionTooltip === index ?
                                        <TooltipCustom
                                            style={item.description.length < 45 ? {width: 'max-content'} : {width: '302px'}}
                                            text={item.description}/>
                                        : null}
                                    <span
                                        style={style.titleOfBlockText}
                                        onMouseOver={() => setDescriptionTooltip(index)}
                                        onMouseOut={() => setDescriptionTooltip(false)}>{item.description && item.description.length >= 30 ? item.description.substring(0, 30) + '...' : item.description}</span>
                                </div>
                            </div>
                            <div className={'api_buttons'} style={style.textLinkWrapper}>
                                <MainButton button={'download'} text={'Get API Key'} style={{
                                    width: '125px',
                                    textTransform: 'unset',
                                    fontWeight: 'normal'
                                }} onclick={() => {
                                    downloadLicense(item.accessKey)
                                    setAccessKey(item.accessKey)
                                    setDownloadKey(true)
                                }}/>
                                <MainButton img={edit_element} style={{
                                    width: '130px',
                                    textTransform: 'unset',
                                    fontWeight: 'normal'
                                }} text={'Edit API Key'} button={'download'}
                                            onclick={() => {
                                                console.log(localGrants)
                                                setActiveEditData(prevState => ({
                                                    ...prevState,
                                                    grants: localGrants && localGrants.length > 0 ? localGrants : []
                                                }));
                                                setActiveEditData(prevState => ({
                                                    ...prevState,
                                                    description: item.description ? item.description : ''
                                                }));
                                                setActiveEditData(prevState => ({
                                                    ...prevState,
                                                    accessKey: item.accessKey
                                                }));
                                                changeEditLicense(true);
                                                console.log(item.accessKey)
                                            }}/>
                                <MainButton imgStyle={{marginRight: '4px'}} style={{
                                    width: '140px',
                                    textTransform: 'unset',
                                    fontWeight: 'normal'
                                }}
                                            onclick={() => {
                                                setDeleteModal(true)
                                                setDeleteKey(item.accessKey)
                                            }}
                                            margin={'0 0 0 auto'} text={'Delete API Key'} button={'delete'}/>

                            </div>
                        </div>
                    }) :
                    loader ?
                        <div className={'no_licenses_wrapper'}>
                            <img src={loaderGif} alt=""/>
                        </div>
                        :
                    <div className={'no_licenses_wrapper'}>
                        <div>
                            <span className={'no_licenses_header'}>Add your first API Key now!</span>
                            <span className={'no_licenses_description'}>To authorize and work with our API you need to use your unique key <br/> identifier in the signature of requests.</span>
                            <span className={'no_licenses_description'}>Use different keys for different types of devices or senders.</span>
                        </div>
                    </div>
                }
                {data && data.pagination.totalElements > 10 ?
                <div>
                    <Pagination
                        activePage={!activePage ? 1 : activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={Math.ceil(allItems)}
                        activeClass={'active_pagination'}
                        prevPageText={'PREV'}
                        nextPageText={'NEXT'}
                        lastPageText={'LAST'}
                        firstPageText={'FIRST'}
                        pageRangeDisplayed={5}
                        onChange={changePage.bind(this)}
                    />
                </div>
                    : null }
            </div>
        </Layout>);
};

export default withRouter(KeysPage)
