import React, {useState} from "react";
// import API from "../API";
import Layout from "../components/Layout";
import {withRouter} from "react-router-dom";
// import {Keys} from "../helper";
import ProfileUpdateComponent from "../components/ProfileUpdateComponent";
import "../components/style/profile.scss"
import Pricing from "./Pricing";
import CloseIcon from "../components/CloseIcon";

const Profile = () => {
    const [popupAlert, setPopupAlert] = useState(0)
    const [upgradePlan, setUpgradePlan] = useState(false);




    // function deleteAccountTest() {
    //     API.post("account/delete", {"accountKey": localStorage.getItem(Keys.ACCOUNT_KEY)}).then((res) => {
    //             localStorage.removeItem(Keys.ACCOUNT_KEY);
    //             localStorage.removeItem(Keys.AUTHORIZATION_TOKEN);
    //             window.location.pathname = "/login";
    //         }
    //     )
    // }

    return (
        <Layout popupAlert={popupAlert} setPopupAlert={()=>setPopupAlert(0)}>
            {upgradePlan ?
                <>
                    <CloseIcon onclick={() => {
                        document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                        setUpgradePlan(false)
                    }}
                               style={{
                                   position: 'fixed',
                                   top: '24px',
                                   right: '24px',
                                   zIndex: '1000000',
                                   cursor: 'pointer'
                               }}/>
                               <Pricing goToDashboard={() => setUpgradePlan(false)} fixed={true}/>
                </>
                : null
            }
            {/*<div className={"login__button"} onClick={() => deleteAccountTest()}>Delete account</div>*/}
            <div className={'wrapper_profile_information'} style={{gridTemplateColumns: '1fr'}}>
                <div style={{width: "auto", justifySelf: 'center'}}>
                    <h2 className={"children-wrapper__page-header"}>Profile settings</h2>
                    <ProfileUpdateComponent width={'466px'} popupAlert={(e) => setPopupAlert(e)} redirectToPayment={() => {
                    }}/>
                </div>
                {/*<div className={'activate_license_wrapper'}>*/}

                {/*<Plan upgradePlan={() =>setUpgradePlan(true)}/>*/}
                {/*</div>*/}
            </div>
        </Layout>);
};

export default withRouter(Profile)
