import React, {useState} from 'react';
import "../../components/style/login.scss"
import axios from "axios"
import TextInput from "../TextInput";
import MainButton from "../MainButton";
import atttentionIcon from "../../assets/attention_icon.svg";


const PasswordReset = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isErrorPasswordRepeat, setIsErrorPasswordRepeat] = useState(false);
    const [errorCode, setErrorCode] = useState("");

    const handleSubmit = event => {
        event.preventDefault();

        if (passwordRepeat !== password) {
            setIsErrorPassword(true)
            setIsErrorPasswordRepeat(true)
            setErrorCode("PMATCH")
            return;
        }
        setErrorCode("");
        setEmail(email.toLowerCase());
        axios.post(process.env.REACT_APP_API_URL + "/account/password/recovery/redirect/send", {email, password})
            .then(() => {
                props.changeToConfirm(email)
            }).catch((error) => {
            console.log(error.response.data);
            console.log(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
            setErrorCode(error.response.data.code);
            switch (error.response.data.code) {
                case "R_003":
                    setIsErrorPassword(true);
                    break;
                case "BO_005":
                    setIsErrorPassword(true);
                    break;
                default:
                    break;
            }
        });
    };

    const checkPasswordMatch = (password, passwordRepeat) => {
        if (passwordRepeat.length === password.length) {
            if (passwordRepeat !== password) {
                setErrorCode("PMATCH");
                setIsErrorPassword(true)
                setIsErrorPasswordRepeat(true)
            } else {
                setErrorCode("");
                setIsErrorPassword(false)
                setIsErrorPasswordRepeat(false)
            }
        } else setErrorCode("");
    }

    return (
        <div className="login__wrapper">
            <form className="login-form" onSubmit={(event) => handleSubmit(event)}>
                <h2 className={'sign_up_text_big'}>Reset your password</h2>
                <div style={{marginBottom: '0px'}} className="login-form__change">
                    <p style={{marginBottom: '12px'}} className={'sign_up_text_bold'}>Please enter your email address below, Check your email box for proceeding</p>
                </div>
                <div className={"login-form__change__button login-form__change__button--reset"}
                     style={{padding: "0!important", marginBottom: '22px'}}
                     onClick={() => {
                         props.changeToLogin()
                     }}>Back to sign in
                </div>
                <TextInput title={'Email address'} type={"email"}
                           margin={'0 0 10px 0'} value={email}
                           error={isErrorEmail}
                           required={true}
                           onchange={e => {
                               setIsErrorEmail(false);
                               setEmail(e.target.value)
                           }}/>
                <div className={'password_warning'}>
                    <img src={atttentionIcon} alt=""/>
                    <div>
                        <span>Notice, your password should contain:</span>
                        <ul>
                            <li><span>8 or more characters</span></li>
                            <li><span>1 digit</span></li>
                            <li><span>1 uppercase letter</span></li>
                            <li><span>1 lowercase letter</span></li>
                        </ul>
                    </div>
                </div>
                <TextInput title={'New password'} type={"password"}
                           margin={'0 0 10px 0'} value={password}
                           error={isErrorPassword}
                           required={true}
                           onchange={e => {
                               setErrorCode("");
                               setIsErrorPassword(false);
                               setIsErrorPasswordRepeat(false);
                               setPassword(e.target.value)
                               checkPasswordMatch(e.target.value, passwordRepeat);
                           }}/>
                {errorCode === "R_003" || errorCode === "BO_005" ?
                    <p className="login-form--error">
                        Your password should contain 1 uppercase letter, 1 lowercase
                        letter, and 1 digit.
                        The minimum allowed length is 8 characters.
                    </p> :  null}

                <TextInput title={'Confirm new password'} type={"password"}
                           margin={'0 0 35px 0'} value={passwordRepeat}
                           error={isErrorPasswordRepeat}
                           required={true}
                           onchange={e => {
                               setErrorCode("");
                               setIsErrorPassword(false);
                               setIsErrorPasswordRepeat(false);
                               setPasswordRepeat(e.target.value)
                               checkPasswordMatch(password, e.target.value);
                           }}/>

                { errorCode === "PMATCH" ?   <p className="login-form--error">
                Password don't match
            </p> :null}
                <MainButton type={'submit'} button={'main_big'} text={'RESET PASSWORD'}/>
            </form>

        </div>

    );
};

export default (PasswordReset)
