import React, { useState } from 'react';
import '../style/login.scss';
import axios from 'axios';
import { PASSWORD_REGEX } from '../../helper';
import TextInput from '../TextInput';
import CustomCheckbox from '../CustomCheckbox';
import MainButton from '../MainButton';
import atttentionIcon from '../../assets/attention_icon.svg';
import { useHistory } from 'react-router-dom';
import ConfirmEmail from '../login/ConfirmEmail';

export const Signup = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  const [isErrorPasswordRepeat, setIsErrorPasswordRepeat] = useState(false);
  const [firstCheckBoxSet, setFirstCheckBoxSet] = useState(false);
  const [secondCheckBoxSet, setSecondCheckBoxSet] = useState(false);
  const [showCheckBoxError, setShowCheckBoxError] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordRepeatMatch, setPasswordRepeatMatch] = useState(false);
  const [confirmForm, setConfirmForm] = useState(false);
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!firstCheckBoxSet) {
      setShowCheckBoxError(true);
      return;
    }

    if (passwordRepeat !== password) {
      setIsErrorPassword(true);
      setIsErrorPasswordRepeat(true);
      setErrorCode('PMATCH');
      return;
    }
    setErrorCode('');

    setEmail(email.toLowerCase());
    axios
      .post(process.env.REACT_APP_API_URL + '/registration/redirect/send', {
        email: email,
        limitTemplateKey: null,
        password: password,
        paymentPeriod: 'MONTH',
      })
      .then((res) => {
        setEmail(email);
        setConfirmForm(true);
      })
      .catch((err) => {
        setErrorCode(err.response.data.code);
        switch (err.response.data.code) {
          case 'R_003':
            setIsErrorPassword(true);
            setIsErrorPasswordRepeat(true);
            break;
          case 'BO_002':
            setErrorCode('ACC_EXISTS');
            setTimeout(() => {
              history.push('/signup', { from: '/login' });
            }, 3000);
            break;
          default:
            break;
        }
        // setIsErrorEmail(true);
      });
  };
  const checkPasswordMatch = (password, passwordRepeat) => {
    if (passwordRepeat.length === password.length) {
      if (passwordRepeat !== password) {
        setErrorCode('PMATCH');
        setIsErrorPassword(true);
        setIsErrorPasswordRepeat(true);
      } else {
        setErrorCode('');
        setIsErrorPassword(false);
        setIsErrorPasswordRepeat(false);
      }
    } else setErrorCode('');
    setPasswordMatch(PASSWORD_REGEX.test(password));
    setPasswordRepeatMatch(PASSWORD_REGEX.test(passwordRepeat));
  };

  return (
    <>
      {!confirmForm ? (
        <div className="login__wrapper">
          <form
            className="login-form"
            onSubmit={(event) => handleSubmit(event)}
          >
            <h1 className={'sign_up_text_big'}>Sign up</h1>
            <h2 className={'sign_up_text_bold'}>
              Get free account and requests
            </h2>
            <div className="login-form__change">
              <p className={'grey_text_login'}>Already have an account?</p>
              <div
                className={'login-form__change__button'}
                onClick={() => {
                  history.push('/login');
                }}
              >
                Sign in
              </div>
            </div>
            <TextInput
              title={'Email address'}
              type={'email'}
              margin={'0 0 10px 0'}
              value={email}
              error={isErrorEmail}
              required={true}
              onchange={(e) => {
                setIsErrorEmail(false);
                setEmail(e.target.value);
              }}
            />
            <div className={'password_warning'}>
              <img src={atttentionIcon} alt="" />
              <div>
                <span>Notice, your password should contain:</span>
                <ul>
                  <li>
                    <span>8 or more characters</span>
                  </li>
                  <li>
                    <span>1 digit</span>
                  </li>
                  <li>
                    <span>1 uppercase letter</span>
                  </li>
                  <li>
                    <span>1 lowercase letter</span>
                  </li>
                </ul>
              </div>
            </div>
            <TextInput
              title={'Password'}
              type={'password'}
              margin={'0 0 10px 0'}
              value={password}
              error={isErrorPassword}
              required={true}
              onchange={(e) => {
                setIsErrorPassword(false);
                setIsErrorPasswordRepeat(false);
                setPassword(e.target.value);
                checkPasswordMatch(e.target.value, passwordRepeat);
              }}
            />

            {errorCode === 'R_003' || errorCode === 'BO_005' ? (
              <p className="login-form--error">
                Your password does not meet requirements
              </p>
            ) : passwordMatch ? (
              <p className="login-form__input--match">Password is good.</p>
            ) : null}

            <TextInput
              title={'Confirm password'}
              type={'password'}
              margin={'0 0 15px 0'}
              value={passwordRepeat}
              error={isErrorPasswordRepeat}
              required={true}
              onchange={(e) => {
                setIsErrorPassword(false);
                setIsErrorPasswordRepeat(false);
                setPasswordRepeat(e.target.value);
                checkPasswordMatch(password, e.target.value);
              }}
            />

            {errorCode === 'PMATCH' ? (
              <p className="login-form--error">Password don't match</p>
            ) : errorCode === 'ACC_EXISTS' ? (
              <p className="login-form--error">This account already exists.</p>
            ) : passwordRepeatMatch ? (
              <p className="login-form__input--match">Password is good.</p>
            ) : null}

            <CustomCheckbox
              margin={'0 0 10px 0'}
              value={firstCheckBoxSet}
              onclick={() => {
                setFirstCheckBoxSet(!firstCheckBoxSet);
                setShowCheckBoxError(false);
                if (!secondCheckBoxSet && !firstCheckBoxSet) {
                  setSecondCheckBoxSet(true);
                }
              }}
              title={
                <p
                  style={{
                    marginBottom: 0,
                    fontSize: '12px',
                    lineHeight: '15px',
                  }}
                >
                  I have read and agree to the{' '}
                  <a style={{ fontSize: '12px' }} href={'www.google.com'}>
                    Terms of Service
                  </a>{' '}
                  and <a href={'www.google.com'}>Privacy Policy</a>.
                </p>
              }
            />

            <CustomCheckbox
              value={secondCheckBoxSet}
              onclick={() => {
                setSecondCheckBoxSet(!secondCheckBoxSet);
              }}
              title={
                <p style={{ fontSize: '12px', lineHeight: '15px' }}>
                  Yes, I want to receive news, events and offers. Please, keep
                  me updated.
                </p>
              }
            />
            {showCheckBoxError ? (
              <p
                style={{ marginBottom: 0, marginTop: 0 }}
                className="login-form--error"
              >
                Please select first checkbox.
              </p>
            ) : null}
            <MainButton
              margin={'36px 0 0'}
              button={'main_big'}
              type={'submit'}
              text={'SIGN UP'}
            />
          </form>
        </div>
      ) : (
        <ConfirmEmail email={email} />
      )}
    </>
  );
};
