import React, { useEffect, useRef, useState } from 'react';
import '../components/style/order.css';
import Layout from '../components/Layout';
// import API from "../API";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import '../components/style/profile.scss';
import '../components/style/billing.css';
import CardSection from '../components/CardElement';
import stripeImg from '../assets/secured_stripe.svg';
import '../components/style/billing.css';
import { Keys } from '../helper';
import API from '../API';
import TextInput from './TextInput';
import TextBox from './TextBox';
// eslint-disable-next-line
import CustomCheckbox from './CustomCheckbox';
import MainButton from './MainButton';
import CloseIcon from './CloseIcon';
import error_icon from '../assets/warning.svg';
import success_icon from '../assets/success_icon.svg';
import warning_icon from '../assets/attention_icon.svg';
import Moment from 'moment';

const OrderPopup = (props) => {
  // eslint-disable-next-line
  const [checkBoxLicense, changeCheckBoxLicense] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [billingData, setBillingData] = useState({
    cardName: '',
    company: '',
    companyProfile: '',
    address1: '',
    address2: '',
    city: '',
    zipCode: '',
    country: '',
    state: '',
    vat: '',
    email: '',
    phone: '',
  });

  let ref = useRef();

  let outsideClickDetect = (e) => {
    if (e.target === ref.current && successPopup) {
      // setSuccessPopup(false)
    } else if (e.target === ref.current && errorPopup) {
      setErrorPopup(false);
    } else if (e.target === ref.current && warningPopup) {
      setWarningPopup(false);
    }
  };

  const changeBillingData = (name, value) => {
    setBillingData((prevState) => ({ ...prevState, [name]: value }));
  };

  const stripe = useStripe();
  const elements = useElements();
  // const [popupAlert, setPopupAlert] = useState(0);
  const payWithStripe = (customerId, priceId, paymentPeriod, key) => {
    if (!stripe || !elements) {
      alert('error');
      return;
    }

    setButtonLoader(true);
    const cardElement = elements.getElement(CardElement);
    setTimeout(() => setButtonLoader(false), 10000);

    return stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      .then((result) => {
        if (result.error) {
          console.log(result);
          throw result;
        } else {
          API.post('/limit/upgrade', {
            accountKey: customerId,
            limitTemplateKey: key,
            paymentPeriod: paymentPeriod,
            stripePaymentMethodKey: result.paymentMethod.id,
          })
            .then((r) => {
              console.log(r);
              setButtonLoader(false);
              if (r.data.status === 'incomplete') {
                stripe
                  .confirmCardPayment(r.data.paymentSecretKey, {
                    payment_method: result.paymentMethod.id,
                  })
                  .then((result) => {
                    if (result.error) {
                      throw result;
                    } else {
                      if (result.paymentIntent.status === 'succeeded') {
                        setSuccessPopup(true);
                      }
                    }
                  })
                  .catch((error) => {
                    // displayError(error);
                    console.error('payment confirmation error');
                    // TODO be sure that it handles 'require payment method'
                    console.error(error);
                    showError(error.error.code);
                  });
              } else if (r.data.status === 'active') {
                setSuccessPopup(true);
              } else {
                console.log(result);
                throw result;
                //todo show notification card was declined, please try another one.
              }
            })
            .catch((r) => {
              console.log(`/limit/upgrade error: ${r}`);
              if (r.response.data.code === 'STP_002') {
                showError(r.response.data.message);
              }
              setButtonLoader(false);
            });
        }
      })
      .catch((res) => {
        setButtonLoader(false);
        console.log(`createPaymentMethod error: ${res}`);
        showError(res.error.code);
      });
  };

  const showError = (error) => {
    switch (error) {
      case 'authentication_required':
        setErrorText(
          'The card was declined as the transaction requires authentication.'
        );
        setWarningPopup(true);
        break;
      case 'approve_with_id':
        setErrorText('The payment cannot be authorized.');
        setWarningPopup(true);
        break;
      case 'call_issuer':
        setErrorText('The card has been declined for an unknown reason.');
        setWarningPopup(true);
        break;
      case 'card_not_supported':
        setErrorText('The card has been declined for an unknown reason.');
        setWarningPopup(true);
        break;
      case 'card_velocity_exceeded':
        setErrorText('The card does not support this type of purchase.');
        setWarningPopup(true);
        break;
      case 'currency_not_supported':
        setErrorText(
          'The customer has exceeded the balance or credit limit available on their card.'
        );
        setWarningPopup(true);
        break;
      case 'do_not_honor':
        setErrorText('The card does not support the specified currency.');
        setWarningPopup(true);
        break;
      case 'do_not_try_again':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'duplicate_transaction':
        setErrorText(
          'A transaction with identical amount and credit card information was submitted very recently.'
        );
        setWarningPopup(true);
        break;
      case 'expired_card':
        setErrorText('The card has expired.');
        setErrorPopup(true);
        break;
      case 'fraudulent':
        setErrorText(
          'The payment has been declined as Stripe suspects it is fraudulent.'
        );
        setWarningPopup(true);
        break;
      case 'generic_decline':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'incorrect_number':
        setErrorText('The card number is incorrect.');
        setErrorPopup(true);
        break;
      case 'incorrect_cvc':
        setErrorText('The CVC number is incorrect.');
        setErrorPopup(true);
        break;
      case 'incorrect_pin':
        setErrorText(
          'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.'
        );
        setErrorPopup(true);
        break;
      case 'incorrect_zip':
        setErrorText('The ZIP/postal code is incorrect.');
        setErrorPopup(true);
        break;
      case 'insufficient_funds':
        setErrorText(
          'The card has insufficient funds to complete the purchase.'
        );
        setErrorPopup(true);
        break;
      case 'invalid_account':
        setErrorText(
          'The card, or account the card is connected to, is invalid.'
        );
        setErrorPopup(true);
        break;
      case 'invalid_amount':
        setErrorText(
          'The payment amount is invalid, or exceeds the amount that is allowed.'
        );
        setWarningPopup(true);
        break;
      case 'invalid_cvc':
        setErrorText('The CVC number is incorrect.');
        setWarningPopup(true);
        break;
      case 'invalid_expiry_year':
        setErrorText('The expiration year invalid.');
        setErrorPopup(true);
        break;
      case 'invalid_number':
        setErrorText('The card number is incorrect.');
        setErrorPopup(true);
        break;
      case 'invalid_pin':
        setErrorText(
          'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.'
        );
        setErrorPopup(true);
        break;
      case 'issuer_not_available':
        setErrorText(
          'The card issuer could not be reached, so the payment could not be authorized.'
        );
        setErrorPopup(true);
        break;
      case 'lost_card':
        setErrorText(
          'The payment has been declined because the card is reported lost.'
        );
        setErrorPopup(true);
        break;
      case 'merchant_blacklist':
        setErrorText(
          "The payment has been declined because it matches a value on the Stripe user's block list."
        );
        setErrorPopup(true);
        break;
      case 'new_account_information_available':
        setErrorText(
          'The card, or account the card is connected to, is invalid.'
        );
        setErrorPopup(true);
        break;
      case 'no_action_taken':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'not_permitted':
        setErrorText('The payment is not permitted.');
        setErrorPopup(true);
        break;
      case 'offline_pin_required':
        setErrorText('The card has been declined as it requires a PIN.');
        setErrorPopup(true);
        break;
      case 'online_or_offline_pin_required':
        setErrorText('The card has been declined as it requires a PIN.');
        setErrorPopup(true);
        break;
      case 'pickup_card':
        setErrorText(
          'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).'
        );
        setErrorPopup(true);
        break;
      case 'pin_try_exceeded':
        setErrorText('The allowable number of PIN tries has been exceeded.');
        setWarningPopup(true);
        break;
      case 'processing_error':
        setErrorText('An error occurred while processing the card.');
        setWarningPopup(true);
        break;
      case 'reenter_transaction':
        setErrorText(
          'The payment could not be processed by the issuer for an unknown reason.'
        );
        setErrorPopup(true);
        break;
      case 'restricted_card':
        setErrorText(
          'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).'
        );
        setErrorPopup(true);
        break;
      case 'revocation_of_all_authorizations':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'revocation_of_authorization':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'security_violation':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'service_not_allowed':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'stolen_card':
        setErrorText(
          'The payment has been declined because the card is reported stolen.'
        );
        setErrorPopup(true);
        break;
      case 'stop_payment_order':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'testmode_decline':
        setErrorText('A Stripe test card number was used.');
        setWarningPopup(true);
        break;
      case 'transaction_not_allowed':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'try_again_later':
        setErrorText('The card has been declined for an unknown reason.');
        setErrorPopup(true);
        break;
      case 'withdrawal_count_limit_exceeded':
        setErrorText(
          'The customer has exceeded the balance or credit limit available on their card.'
        );
        setWarningPopup(true);
        break;
      case 'payment_intent_authentication_failure':
        setErrorText(
          'We are unable to authenticate your payment method. Please choose a different payment method and try again.'
        );
        setWarningPopup(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {}, [props.activeData]);

  return (
    <Layout>
      <div className={'order_wrapper'}>
        <CloseIcon
          onclick={() => props.closePopup()}
          wrapperStyle={{
            position: 'fixed',
            zIndex: '1000000',
            height: '70px',
            width: '70px',
            cursor: 'pointer',
          }}
        />
        {successPopup ? (
          <div
            className={'global_popup_wrapper'}
            ref={ref}
            onClick={outsideClickDetect}
          >
            <div className={'global_popup'}>
              <CloseIcon onclick={() => {}} />
              <div className={'wrapper_title_of_popup'}>
                <span>Successful payment</span>
              </div>
              <div style={{ marginTop: '24px' }} className={'warning_wrapper'}>
                <img src={success_icon} alt="warning_icon" />
                <span style={{ color: '#0CCC92' }}>
                  You already have access to advanced features.
                </span>
              </div>
              <div className={'wrapper_btn'}>
                <MainButton
                  onclick={() => {
                    // props.goToDashboard()
                    window.location.href = '/dashboard';
                  }}
                  button={'accent'}
                  width={'185px'}
                  text={'Go to dashboard'}
                />
              </div>
            </div>
          </div>
        ) : null}
        {errorPopup ? (
          <div
            className={'global_popup_wrapper'}
            ref={ref}
            onClick={outsideClickDetect}
          >
            <div className={'global_popup'}>
              <CloseIcon onclick={() => setErrorPopup(false)} />
              <div className={'wrapper_title_of_popup'}>
                <span>Unsuccessful payment</span>
              </div>
              <div style={{ marginTop: '24px' }} className={'warning_wrapper'}>
                <img src={error_icon} alt="warning_icon" />
                <span style={{ color: '#FE3E3E' }}>
                  {errorText ? errorText : 'Payment failed, please try again'}
                </span>
              </div>
              <div className={'wrapper_btn'}>
                <MainButton
                  onclick={() => setErrorPopup(false)}
                  button={'accent'}
                  width={'135px'}
                  text={'Go back'}
                />
              </div>
            </div>
          </div>
        ) : null}
        {warningPopup ? (
          <div
            className={'global_popup_wrapper'}
            ref={ref}
            onClick={outsideClickDetect}
          >
            <div className={'global_popup'}>
              <CloseIcon onclick={() => setWarningPopup(false)} />
              <div className={'wrapper_title_of_popup'}>
                <span>Unsuccessful payment</span>
              </div>
              <div style={{ marginTop: '24px' }} className={'warning_wrapper'}>
                <img src={warning_icon} alt="warning_icon" />
                <span style={{ color: '##FFAB00' }}>
                  {errorText
                    ? errorText
                    : 'Not enough money on the card. Top up the card or use another one.'}
                </span>
              </div>
              <div className={'wrapper_btn'}>
                <MainButton
                  onclick={() => setWarningPopup(false)}
                  button={'accent'}
                  width={'135px'}
                  text={'Go back'}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={'order_content'}>
          <span>Subscribe to {props.activeData.plan}</span>
          <div className={'payment_details'}>
            <div className={'payment_details_header'}>
              <span>Payment details</span>
            </div>
            <div>
              <CardSection style={{ marginBottom: '40px', display: 'unset' }} />
            </div>
            <div>
              <TextInput
                title={'Name on card'}
                required={true}
                value={billingData.cardName}
                name={'cardName'}
                margin={'5px 0 5px 0'}
                onchange={(e) => {
                  changeBillingData(e.target.name, e.target.value);
                }}
              />
            </div>
            {/*<CustomCheckbox value={checkBoxLicense} onclick={() => {*/}
            {/*    changeCheckBoxLicense(!checkBoxLicense)*/}
            {/*}} title={'I want to pay as company and need an invoice'} />*/}
            {checkBoxLicense ? (
              <div className={'billing_details_wrapper'}>
                <div>Billing Details</div>
                <div className={'billing_details_content'}>
                  <div>
                    <TextInput
                      title={'Company'}
                      value={billingData.company}
                      name={'company'}
                      margin={'5px 0 5px 0'}
                      onchange={(e) => {
                        changeBillingData(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <TextBox
                      title={'Company Profile'}
                      rows={3}
                      value={billingData.companyProfile}
                      name={'companyProfile'}
                      margin={'5px 0 5px 0'}
                      onchange={(e) => {
                        changeBillingData(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <TextInput
                      title={'Address line 1'}
                      value={billingData.address1}
                      name={'address1'}
                      margin={'5px 0 5px 0'}
                      onchange={(e) => {
                        changeBillingData(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <TextInput
                      title={'Address line 2'}
                      value={billingData.address2}
                      name={'address2'}
                      margin={'5px 0 5px 0'}
                      onchange={(e) => {
                        changeBillingData(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <div className={'billing_double_input_big'}>
                      <div>
                        <TextInput
                          title={'City'}
                          value={billingData.city}
                          name={'city'}
                          margin={'5px 0 5px 0'}
                          onchange={(e) => {
                            changeBillingData(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <TextInput
                          title={'Zip-code'}
                          value={billingData.zipCode}
                          name={'zipCode'}
                          type={'number'}
                          margin={'5px 0 5px 0'}
                          onchange={(e) => {
                            changeBillingData(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'billing_double_input_big'}>
                      <div>
                        <TextInput
                          title={'Country'}
                          value={billingData.country}
                          name={'country'}
                          margin={'5px 0 5px 0'}
                          onchange={(e) => {
                            changeBillingData(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <TextInput
                          title={'State'}
                          value={billingData.state}
                          name={'state'}
                          margin={'5px 0 5px 0'}
                          onchange={(e) => {
                            changeBillingData(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <TextInput
                      title={'VAT number/Tax ID (optional)'}
                      value={billingData.vat}
                      name={'vat'}
                      margin={'5px 0 5px 0'}
                      onchange={(e) => {
                        changeBillingData(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <div className={'billing_double_input_big'}>
                      <div>
                        <TextInput
                          title={'Email to receive bills'}
                          value={billingData.email}
                          name={'email'}
                          type={'email'}
                          margin={'5px 0 5px 0'}
                          onchange={(e) => {
                            changeBillingData(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <TextInput
                          title={'Phone'}
                          value={billingData.phone}
                          name={'phone'}
                          type={'number'}
                          margin={'5px 0 5px 0'}
                          onchange={(e) => {
                            changeBillingData(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={'payment_footer'}>
              <MainButton
                loader={buttonLoader}
                onclick={
                  buttonLoader
                    ? null
                    : () =>
                        payWithStripe(
                          localStorage.getItem(Keys.ACCOUNT_KEY),
                          props.activeData.limitTemplateKey,
                          props.activeData.paymentPeriod,
                          props.activeData.key
                        )
                }
                button={'accent'}
                text={`confirm and pay $${props.activeData.price}`}
              />
              <img src={stripeImg} alt="" />
            </div>
          </div>
          <div className={'purchase_summary'}>
            <div className={'summary_title'}>
              <span>Purchase Summary</span>
            </div>
            <div className={'summary_content'}>
              <div style={{ margin: '22px 0 6px' }}>
                <span style={{ color: '#0A1F44' }}>
                  {props.activeData.plan}
                </span>
                <span className={'summary_price'}>
                  ${props.activeData.price}
                </span>
              </div>
              <div>
                <span>3D Face analysis licenses:</span>
                <span>{props.activeData.licenses}</span>
              </div>
              <div>
                <span>API requests included:</span>
                <span>{props.activeData.requests}</span>
              </div>
              <div>
                <span>Number of users in DB:</span>
                <span>{props.activeData.users}</span>
              </div>
              <div style={{ margin: '22px 0 16px' }}>
                <span>Tax</span>
                <span className={'summary_price'}>$0.00</span>
              </div>
            </div>
            <div className={'summary_total'}>
              <span>Total:</span>
              <span
                style={{ fontSize: '17px !important', fontWeight: '600' }}
                className={'summary_price'}
              >
                ${props.activeData.price}
              </span>
            </div>
            <div className={'summary_description'}>
              <span>
                Your subscription is billed{' '}
                {props.activeData.type === 'MONTH' ? 'monthly' : 'yearly'} and
                will automatically renew on{' '}
                <b>
                  {Moment()
                    .add(
                      1,
                      props.activeData.type === 'MONTH' ? 'months' : 'years'
                    )
                    .format('ll')}
                </b>
                . You can cancel any time before this date.
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrderPopup;
