import React from "react";
import {Route, Redirect} from "react-router-dom";
import {Keys} from "./helper";

const ProtectedAdminRoute = ({
                                 component: Component,
                                 ...rest
                             }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (localStorage.getItem(Keys.AUTHORIZATION_TOKEN)) {
                    return <Component {...props}  location={props.location}/>;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }
            }}
        />
    );
};

export default ProtectedAdminRoute;
