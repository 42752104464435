import React, { useEffect, useRef, useState } from 'react';
// import API from "../API";
import Layout from '../components/Layout';
import '../components/style/dasboard.css';
import '../components/style/profile.scss';
import Pricing from './Pricing';
import MainButton from '../components/MainButton';
import CloseIcon from '../components/CloseIcon';
import API from '../API';

import warningImg from '../assets/attention_icon.svg';
import LicenseStatuses from '../components/LicenseStatuses';
import UsedDataComponent from '../components/UsedDataComponent';
import SelectDropdown from '../components/SelectDropdown';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import MultipleSelect from '../components/MultipleSelect';
import { connect } from 'react-redux';

import { showCreateLicense } from '../store/actions';
import { Keys } from '../helper';
import TooltipCustom from '../components/TooltipCustom';
import CustomCheckbox from '../components/CustomCheckbox';
import TextInput from '../components/TextInput';
import TagsInput from 'react-tagsinput';
import loaderGif from '../assets/loader.gif';
import Moment from 'moment';

const requestTypesEnum = {
  CREATE_PERSON: 'Create person',
  UPDATE_PERSON: 'Update person',
  DELETE_PERSON: 'Remove person',
  GET_PERSON: 'Get person by id',
  RECOGNIZE: 'Identify person',
  LIVENESS_CHECK: 'Verify person',
};

function createChartItem(
  name,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomer,
  recognizeCustomer,
  livenesCheck
) {
  let item = {};
  item.name = name;
  item[requestTypesEnum.CREATE_PERSON] = createCustomer;
  item[requestTypesEnum.UPDATE_PERSON] = updateCustomer;
  item[requestTypesEnum.DELETE_PERSON] = deleteCustomer;
  item[requestTypesEnum.GET_PERSON] = getCustomer;
  item[requestTypesEnum.RECOGNIZE] = recognizeCustomer;
  item[requestTypesEnum.LIVENESS_CHECK] = livenesCheck;
  return item;
}

function getFieldName(value) {
  for (var i in requestTypesEnum) {
    if (requestTypesEnum[i] === value) {
      return i;
    }
  }
  return undefined;
}

// calculates period in seconds from selected value
function getPeriodFromPeriodName(periodName) {
  switch (periodName) {
    case 'Last 24 hours':
      return 24 * 60 * 60;
    case 'Last 7 days':
      return 24 * 60 * 60 * 7;
    case 'Last 4 weeks':
      return 24 * 60 * 60 * 7 * 4;
    case 'Last 12 months':
      return 24 * 60 * 60 * 30 * 12;
    default:
      return null;
  }
}

// calculates date format to show on graph from selected value
function getDateFormatFromPeriodName(periodName) {
  switch (periodName) {
    case 'Last 24 hours':
      return 'MMM Do HH:mm';
    case 'Last 7 days':
      return 'MMM Do';
    case 'Last 4 weeks':
      return 'MMM Do';
    case 'Last 12 months':
      return 'MMM YYYY';
    default:
      return null;
  }
}

const logData = [
  {
    time: '12/03/2020 12:30pm',
    source: '3D Kit',
    id: 'key_c02bb038a4b74060a280f571b496c2e652ppnof4er',
    grants: ['Management', 'Identification'],
    status: 'Failed',
  },
  {
    time: '12/03/2020 12:30pm',
    source: '3D Kit',
    id: 'key_7f5ce3c83bf645e19b4ad1a39cc7257ariztoghlxk',
    grants: ['Management', 'Identification'],
    status: 'Failed',
  },
  {
    time: '12/03/2020 12:30pm',
    source: 'API',
    id: 'key_edf8afffde5744ccb7e5b15288a2c5b8wgxdhryeja',
    grants: ['Management', 'Identification'],
    status: 'Successfully',
  },
  {
    time: '12/03/2020 12:30pm',
    source: '3D Kit',
    id: 'key_e09d8015ba0e433e98459d90dfb66fe5zjekmflm8t',
    grants: ['Identification'],
    status: 'Successfully',
  },
  {
    time: '12/03/2020 12:30pm',
    source: '3D Kit',
    id: 'key_06ae4f56b3e34abf946fdd949e80f6b4trfvwz7bip',
    grants: ['Management', 'Identification'],
    status: 'Failed',
  },
  {
    time: '12/03/2020 12:30pm',
    source: '3D Kit',
    id: 'key_a32455dafc7e4b90882bc9ca080651e0m4wqej8jiz',
    grants: ['Identification'],
    status: 'Successfully',
  },
  {
    time: '12/03/2020 12:30pm',
    source: '3D Kit',
    id: 'key_98edda2914f1416cb80580d310115be58evf6j98ao',
    grants: ['Management', 'Identification'],
    status: 'Failed',
  },
  {
    time: '12/03/2020 12:30pm',
    source: 'API',
    id: 'key_6b757965595243fc91194227a24bccd5m0otm3jhzl',
    grants: ['Management', 'Identification'],
    status: 'Successfully',
  },
  {
    time: '12/03/2020 12:30pm',
    source: '3D Kit',
    id: 'key_63bbc14d5d6e4a0a8d588a1c8c1a4937xbuqcg01h1',
    grants: ['Identification'],
    status: 'Successfully',
  },
  {
    time: '12/03/2020 12:30pm',
    source: 'API',
    id: 'key_61043a3a648f403bb97531d3f349afa1wvenznkogb',
    grants: ['Management', 'Identification'],
    status: 'Successfully',
  },
];

const dataPieChartDay = [
  { name: requestTypesEnum.CREATE_PERSON, value: 60 },
  { name: requestTypesEnum.UPDATE_PERSON, value: 6 },
  { name: requestTypesEnum.DELETE_PERSON, value: 2 },
  { name: requestTypesEnum.GET_PERSON, value: 34 },
  { name: requestTypesEnum.RECOGNIZE, value: 8 },
  { name: requestTypesEnum.LIVENESS_CHECK, value: 7 },
];

const dataPieChartWeek = [
  { name: requestTypesEnum.CREATE_PERSON, value: 124 },
  { name: requestTypesEnum.UPDATE_PERSON, value: 14 },
  { name: requestTypesEnum.DELETE_PERSON, value: 10 },
  { name: requestTypesEnum.GET_PERSON, value: 162 },
  { name: requestTypesEnum.RECOGNIZE, value: 10 },
  { name: requestTypesEnum.LIVENESS_CHECK, value: 19 },
];

const dataPieChartMonth = [
  { name: requestTypesEnum.CREATE_PERSON, value: 299 },
  { name: requestTypesEnum.UPDATE_PERSON, value: 24 },
  { name: requestTypesEnum.DELETE_PERSON, value: 21 },
  { name: requestTypesEnum.GET_PERSON, value: 482 },
  { name: requestTypesEnum.RECOGNIZE, value: 28 },
  { name: requestTypesEnum.LIVENESS_CHECK, value: 48 },
];

const dataPieChartYear = [
  { name: requestTypesEnum.CREATE_PERSON, value: 499 },
  { name: requestTypesEnum.UPDATE_PERSON, value: 40 },
  { name: requestTypesEnum.DELETE_PERSON, value: 36 },
  { name: requestTypesEnum.GET_PERSON, value: 762 },
  { name: requestTypesEnum.RECOGNIZE, value: 53 },
  { name: requestTypesEnum.LIVENESS_CHECK, value: 78 },
];

// const COLORS = ['#0CCC92', '#5C9BFF', '#FE3E3E', '#FFAB00', '#8ED6FF', '#FA00FF'];

var dataChartRequestsYear = [];
dataChartRequestsYear.push(createChartItem('1', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('2', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('3', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('4', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('5', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('6', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('7', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('8', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('9', 0, 0, 0, 0, 0, 0));
dataChartRequestsYear.push(createChartItem('10', 50, 4, 5, 80, 10, 10));
dataChartRequestsYear.push(createChartItem('11', 150, 12, 10, 200, 15, 20));
dataChartRequestsYear.push(createChartItem('12', 299, 24, 21, 482, 28, 48));

var dataChartRequestsMonth = [];
dataChartRequestsMonth.push(createChartItem('1', 40, 0, 2, 80, 4, 0));
dataChartRequestsMonth.push(createChartItem('2', 55, 0, 4, 100, 6, 13));
dataChartRequestsMonth.push(createChartItem('3', 80, 10, 5, 140, 8, 16));
dataChartRequestsMonth.push(createChartItem('4', 124, 14, 10, 162, 10, 19));

var dataChartRequestsDay = [];
dataChartRequestsDay.push(createChartItem('1', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('2', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('3', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('4', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('5', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('6', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('7', 5, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('8', 6, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('9', 4, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('10', 7, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('11', 8, 2, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('12', 9, 4, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('13', 10, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('14', 6, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('15', 5, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('16', 0, 0, 0, 7, 2, 0));
dataChartRequestsDay.push(createChartItem('17', 0, 0, 0, 8, 4, 0));
dataChartRequestsDay.push(createChartItem('18', 0, 0, 0, 9, 2, 0));
dataChartRequestsDay.push(createChartItem('19', 0, 0, 0, 10, 0, 0));
dataChartRequestsDay.push(createChartItem('20', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('21', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('22', 0, 0, 0, 0, 0, 0));
dataChartRequestsDay.push(createChartItem('23', 0, 0, 0, 0, 0, 2));
dataChartRequestsDay.push(createChartItem('24', 0, 0, 0, 0, 0, 5));

var dataChartRequestsWeek = [];
dataChartRequestsWeek.push(createChartItem('1', 5, 0, 3, 10, 0, 0));
dataChartRequestsWeek.push(createChartItem('2', 7, 0, 5, 14, 0, 0));
dataChartRequestsWeek.push(createChartItem('3', 10, 0, 0, 20, 0, 0));
dataChartRequestsWeek.push(createChartItem('4', 12, 3, 0, 24, 0, 3));
dataChartRequestsWeek.push(createChartItem('5', 14, 5, 0, 28, 0, 4));
dataChartRequestsWeek.push(createChartItem('6', 16, 0, 0, 32, 2, 5));
dataChartRequestsWeek.push(createChartItem('7', 60, 6, 2, 34, 8, 7));

const dataChartDBDay = [
  {
    name: '1',
    Users: 278,
  },
  {
    name: '2',
    Users: 298,
  },
  {
    name: '3',
    Users: 298,
  },
  {
    name: '4',
    Users: 298,
  },
  {
    name: '5',
    Users: 298,
  },
  {
    name: '6',
    Users: 298,
  },
  {
    name: '7',
    Users: 293,
  },
  {
    name: '8',
    Users: 283,
  },
  {
    name: '9',
    Users: 278,
  },
  {
    name: '10',
    Users: 278,
  },
  {
    name: '11',
    Users: 298,
  },
  {
    name: '12',
    Users: 293,
  },
  {
    name: '13',
    Users: 288,
  },
  {
    name: '14',
    Users: 283,
  },
  {
    name: '15',
    Users: 278,
  },
  {
    name: '16',
    Users: 278,
  },
  {
    name: '17',
    Users: 278,
  },
  {
    name: '18',
    Users: 278,
  },
  {
    name: '19',
    Users: 278,
  },
  {
    name: '20',
    Users: 278,
  },
  {
    name: '21',
    Users: 278,
  },
  {
    name: '22',
    Users: 278,
  },
  {
    name: '23',
    Users: 278,
  },
  {
    name: '24',
    Users: 278,
  },
];

const dataChartDBYear = [
  {
    name: '1',
    Users: 0,
  },
  {
    name: '2',
    Users: 0,
  },
  {
    name: '3',
    Users: 0,
  },
  {
    name: '4',
    Users: 0,
  },
  {
    name: '5',
    Users: 0,
  },
  {
    name: '6',
    Users: 0,
  },
  {
    name: '7',
    Users: 0,
  },
  {
    name: '8',
    Users: 0,
  },
  {
    name: '9',
    Users: 0,
  },
  {
    name: '10',
    Users: 45,
  },
  {
    name: '11',
    Users: 185,
  },
  {
    name: '12',
    Users: 463,
  },
];

const dataChartDBMonth = [
  {
    name: '1',
    Users: 38,
  },
  {
    name: '2',
    Users: 89,
  },
  {
    name: '3',
    Users: 164,
  },
  {
    name: '4',
    Users: 278,
  },
];

const dataChartDBWeek = [
  {
    name: '1',
    Users: 7,
  },
  {
    name: '2',
    Users: 20,
  },
  {
    name: '3',
    Users: 50,
  },
  {
    name: '4',
    Users: 90,
  },
  {
    name: '5',
    Users: 140,
  },
  {
    name: '6',
    Users: 200,
  },
  {
    name: '7',
    Users: 278,
  },
];

const Dashboard = (props) => {
  const [upgradePlan, setUpgradePlan] = useState(false);
  // eslint-disable-next-line
  const [testData, setTestData] = useState(true);
  const [timePeriodRequests, setTimePeriodRequests] = useState('Last 24 hours');
  const [timePeriodPie, setTimePeriodPie] = useState('Last 24 hours');
  const [timePeriodUsers, setTimePeriodUsers] = useState('Last 24 hours');
  const [dataChart, setDataChart] = useState(dataChartRequestsMonth);
  const [identLogData, setIdentLogData] = useState(logData);
  const [dataPie, setDataPie] = useState(dataPieChartMonth);
  const [dataDB, setDataDB] = useState(dataChartDBMonth);
  const [requestTypeList, setRequestTypeList] = useState([
    requestTypesEnum.CREATE_PERSON,
    requestTypesEnum.UPDATE_PERSON,
    requestTypesEnum.DELETE_PERSON,
    requestTypesEnum.GET_PERSON,
    requestTypesEnum.RECOGNIZE,
    requestTypesEnum.LIVENESS_CHECK,
  ]);
  const [allRequestsByType, setAllRequestsByType] = useState();
  const [grantManTooltip, setMenIdeTooltip] = useState();
  const [grantIdeTooltip, setGrantIdeTooltip] = useState();
  const [descriptionTooltip, setDescriptionTooltip] = useState();
  const [ApiTooltip, setApiTooltip] = useState();
  const [statusTooltip, setStatusTooltip] = useState();
  const [createLicense, setCreateLicense] = useState();
  const [activeAddData, setActiveAddData] = useState({
    grants: ['Identification', 'Management'],
    description: '',
    accessKey: null,
  });
  const [tagsAddArr, setTagsAddArr] = useState({ tags: [] });
  const [checkBoxLicense, changeCheckBoxLicense] = useState(true);
  const [activationCode, changeActivationCode] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const [activeTagValue, setActiveTagValue] = useState('');
  const [errorActivationCode, changeErrorActivationCode] = useState(false);
  const [popupAlertText, setPopupAlertText] = useState('');
  const [popupAlert, setPopupAlert] = useState('');
  const [requestLoader, setRequestLoader] = useState(false);
  const [pieLoader, setPieLoader] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const [limitsData, setLimitsData] = useState();

  let ref = useRef();

  useEffect(() => {
    if (createLicense) {
      document.getElementById('lastAdd').addEventListener('focusout', () => {
        document.getElementById('firstAdd').focus();
      });
      document.addEventListener('keydown', (e) => {
        if (e.which === 27) {
          document.getElementsByTagName('body')[0].style.overflowY = 'auto';
          setCreateLicense(false);
        }
      });
    }
  }, [createLicense]);

  useEffect(() => {
    if (testData) {
      switch (timePeriodRequests) {
        case 'Last 12 months':
          setDataChart(dataChartRequestsYear);
          break;
        case 'Last 4 weeks':
          setDataChart(dataChartRequestsMonth);
          break;
        case 'Last 7 days':
          setDataChart(dataChartRequestsWeek);
          break;
        case 'Last 24 hours':
          setDataChart(dataChartRequestsDay);
          break;
        default:
          break;
      }
    }
  }, [timePeriodRequests, testData]);

  useEffect(() => {
    if (activeAddData.description.length >= 50) {
      setDescriptionError(true);
    } else setDescriptionError(false);

    if (tagsAddArr.tags?.length >= 5) {
      setTagsError(true);
    } else setTagsError(false);
  }, [activeAddData, tagsAddArr.tags]);

  useEffect(() => {
    if (testData) {
      switch (timePeriodUsers) {
        case 'Last 12 months':
          setDataDB(dataChartDBYear);
          break;
        case 'Last 4 weeks':
          setDataDB(dataChartDBMonth);
          break;
        case 'Last 7 days':
          setDataDB(dataChartDBWeek);
          break;
        case 'Last 24 hours':
          setDataDB(dataChartDBDay);
          break;
        default:
          break;
      }
    }
  }, [timePeriodUsers, testData]);

  useEffect(() => {
    if (testData) {
      switch (timePeriodPie) {
        case 'Last 12 months':
          setDataPie(dataPieChartYear);
          setAllRequestsByType(
            dataPieChartYear.reduce((a, b) => ({ value: a.value + b.value }))
          );
          break;
        case 'Last 4 weeks':
          setDataPie(dataPieChartMonth);
          setAllRequestsByType(
            dataPieChartMonth.reduce((a, b) => ({ value: a.value + b.value }))
          );
          break;
        case 'Last 7 days':
          setDataPie(dataPieChartWeek);
          setAllRequestsByType(
            dataPieChartWeek.reduce((a, b) => ({ value: a.value + b.value }))
          );
          break;
        case 'Last 24 hours':
          setDataPie(dataPieChartDay);
          setAllRequestsByType(
            dataPieChartDay.reduce((a, b) => ({ value: a.value + b.value }))
          );
          break;
        default:
          break;
      }
    } else {
      setTimeout(() =>
        setAllRequestsByType(
          dataPie.reduce((a, b) => ({ value: a.value + b.value }))
        )
      );
    }
  }, [timePeriodPie, testData, dataPie]);

  useEffect(() => {
    const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
    setTimeout(() => {
      setPieLoader(true);
      setUserLoader(true);
      setRequestLoader(true);
    }, 3000);

    API.post('/account/limit/list/get', { accountKey: accountKey }).then(
      (res) => {
        res.data.limits.forEach((limit) => {
          setLimitsData(limit);
        });
      }
    );
  }, [testData]);

  useEffect(() => {
    if (upgradePlan) {
      document.addEventListener('keydown', (e) => {
        if (e.which === 27) {
          document.getElementsByTagName('body')[0].style.overflowY = 'auto';
          setUpgradePlan(false);
        }
      });
    }
  }, [upgradePlan]);

  const generateLicense = () => {
    if (checkBoxLicense && activationCode.length !== 34) {
      changeErrorActivationCode(true);
      return;
    } else {
      changeErrorActivationCode(false);
    }

    const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
    let localArr = activeAddData.grants;
    localArr.forEach((grant, index) => {
      if (grant === 'Management') {
        localArr[index] = 'PERSON_MANAGER';
      } else if (grant === 'Identification') {
        localArr[index] = 'RECOGNITION';
      }
    });
    API.post('/key/generate', {
      activationCode: activationCode,
      accountKey: accountKey,
      tags: tagsAddArr.tags,
      grants: localArr,
      description: activeAddData.description,
      type: checkBoxLicense ? 'PERMANENT' : 'SUBSCRIPTION',
    })
      .then((res) => {
        localArr = [];
        setCreateLicense(false);
        changeCheckBoxLicense(true);
        setPopupAlert(1);
        changeActivationCode('');
        setActiveAddData({
          grants: ['Identification', 'Management'],
          description: '',
          accessKey: null,
        });
        document.getElementsByTagName('body')[0].style.overflowY = 'auto';
        setTagsAddArr({ tags: [] });
        window.location = '/licenses';
      })
      .catch((err) => {
        if (err.response.status) {
          setPopupAlertText(err.response.data.message);
          setPopupAlert(3);
        }
      });
  };

  let handleSubmitLicense = (e) => {
    e.preventDefault();
    if (activationCode) {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
      setCreateLicense(false);
    } else {
      changeErrorActivationCode(true);
    }
  };

  useEffect(() => {
    if (!testData) {
      setRequestLoader(false);
      let requestPeriod = getPeriodFromPeriodName(timePeriodRequests);
      let dateFormatString = getDateFormatFromPeriodName(timePeriodRequests);
      let request_list = [];
      requestTypeList.forEach((request) =>
        request_list.push(getFieldName(request))
      );
      if (requestPeriod && request_list) {
        console.log(request_list);
        API.get('/dashboard/graph', {
          params: {
            period: requestPeriod,
            request_types: request_list.join(', '),
          },
        }).then((res) => {
          res.data.map((obj) => {
            obj[requestTypesEnum.CREATE_PERSON] =
              obj[getFieldName(requestTypesEnum.CREATE_PERSON)];
            obj[requestTypesEnum.DELETE_PERSON] =
              obj[getFieldName(requestTypesEnum.DELETE_PERSON)];
            obj[requestTypesEnum.UPDATE_PERSON] =
              obj[getFieldName(requestTypesEnum.UPDATE_PERSON)];
            obj[requestTypesEnum.GET_PERSON] =
              obj[getFieldName(requestTypesEnum.GET_PERSON)];
            obj[requestTypesEnum.RECOGNIZE] =
              obj[getFieldName(requestTypesEnum.RECOGNIZE)];
            obj[requestTypesEnum.LIVENESS_CHECK] =
              obj[getFieldName(requestTypesEnum.LIVENESS_CHECK)];

            //adjust dates according to local date time
            obj.name = Moment.utc(obj.name).local().format(dateFormatString);

            delete obj[getFieldName(requestTypesEnum.CREATE_PERSON)];
            delete obj[getFieldName(requestTypesEnum.DELETE_PERSON)];
            delete obj[getFieldName(requestTypesEnum.UPDATE_PERSON)];
            delete obj[getFieldName(requestTypesEnum.GET_PERSON)];
            delete obj[getFieldName(requestTypesEnum.RECOGNIZE)];
            delete obj[getFieldName(requestTypesEnum.LIVENESS_CHECK)];

            return obj;
          });
          setDataChart(res.data);
          setTimeout(() => setRequestLoader(true), 2000);
        });
      }
    }
  }, [testData, timePeriodRequests, requestTypeList]);

  useEffect(() => {
    if (!testData) {
      setPieLoader(false);
      let piePeriod = getPeriodFromPeriodName(timePeriodPie);
      if (piePeriod) {
        API.get('/dashboard/piechart', { params: { period: piePeriod } }).then(
          (res) => {
            res.data.map((obj) => {
              console.log(`${obj.name} -> ${requestTypesEnum[obj.name]}`);
              obj.name = requestTypesEnum[obj.name];
              return obj;
            });
            setDataPie(res.data);
            setTimeout(() => setPieLoader(true), 2000);
          }
        );
      }
    }
  }, [testData, timePeriodPie]);

  useEffect(() => {
    if (!testData) {
      setUserLoader(false);
      let usersPeriod = getPeriodFromPeriodName(timePeriodUsers);
      let dateFormatString = getDateFormatFromPeriodName(timePeriodUsers);
      if (usersPeriod) {
        API.get('/dashboard/users-in-db-graph', {
          params: { period: usersPeriod },
        }).then((res) => {
          res.data.map((obj) => {
            //adjust dates according to local date time
            obj.name = Moment.utc(obj.name).local().format(dateFormatString);
            return obj;
          });
          setDataDB(res.data);
          setTimeout(() => setUserLoader(true), 3000);
        });
      }
    }
  }, [testData, timePeriodUsers]);

  useEffect(() => {
    if (!testData) {
      API.get('/dashboard/identification-log', {
        params: { period: 50000 },
      }).then((res) => {
        setIdentLogData(res.data);
      });
    }
  }, [testData]);

  const deleteTestData = () => {
    API.post('/dashboard/test-data', { userTestData: false }).then((res) => {
      setTestData(false);
    });
  };

  useEffect(() => {
    API.get('/dashboard/test-data').then((res) => {
      setTestData(res.data.useTestData);
    });
  }, []);

  const getColor = (name) => {
    switch (name) {
      case requestTypesEnum.UPDATE_PERSON:
        return '#0CCC92';
      case requestTypesEnum.LIVENESS_CHECK:
        return '#5C9BFF';
      case requestTypesEnum.CREATE_PERSON:
        return '#FE3E3E';
      case requestTypesEnum.RECOGNIZE:
        return '#F2C94C';
      case requestTypesEnum.GET_PERSON:
        return '#8ED6FF';
      case requestTypesEnum.DELETE_PERSON:
        return '#FA00FF';
      default:
        break;
    }
  };

  function RenderGraphLine(requestTypeList, requestType) {
    if (requestTypeList.includes(requestType)) {
      let color = getColor(requestType);
      return (
        <Line
          type="monotone"
          dot={{ stroke: color, strokeWidth: 2, fill: color }}
          dataKey={requestType}
          stroke={color}
          activeDot={{ stroke: color, strokeWidth: 3, r: 6 }}
        />
      );
    }
    return null;
  }

  return (
    <Layout
      popupAlert={popupAlert}
      popupAlertText={popupAlertText}
      setPopupAlertText={() => setPopupAlertText('')}
      setPopupAlert={() => setPopupAlert(0)}
    >
      {upgradePlan ? (
        <>
          <CloseIcon
            onclick={() => {
              document.getElementsByTagName('body')[0].style.overflowY = 'auto';
              setUpgradePlan(false);
            }}
            wrapperStyle={{
              position: 'fixed',
              zIndex: '1000000',
              cursor: 'pointer',
              height: '70px',
              width: '70px',
            }}
          />
          <Pricing goToDashboard={() => setUpgradePlan(false)} fixed={true} />
        </>
      ) : null}
      {createLicense ? (
        <div
          ref={ref}
          onClick={(e) => {
            if (e.target === ref.current) {
              document.getElementsByTagName('body')[0].style.overflowY = 'auto';
              setCreateLicense(false);
            }
          }}
          className={'global_popup_wrapper'}
        >
          <form
            style={{ width: '705px' }}
            id={'add_license'}
            className={'global_popup'}
            onSubmit={handleSubmitLicense}
          >
            <CloseIcon
              onclick={() => {
                document.getElementsByTagName('body')[0].style.overflowY =
                  'auto';
                setCreateLicense(false);
              }}
            />
            <div className={'wrapper_title_of_popup'}>
              <span>Add License</span>
            </div>
            <div
              style={{
                paddingBottom: '16px',
                margin: '16px',
                borderBottom: '1px solid #ECECEC',
              }}
            >
              <div className={'license_text'}>
                A license will be generated within the limit for your
                subscription plan. If you want to add a permanent license,
                please insert the activation code in the required field, if not
                just continue.
              </div>
              <CustomCheckbox
                id={'firstAdd'}
                margin={'16px 0 0'}
                value={checkBoxLicense}
                onclick={() => {
                  changeCheckBoxLicense(!checkBoxLicense);
                  changeActivationCode('');
                }}
                title={'I have activation code'}
              />
              {checkBoxLicense ? (
                <div className={'wrapper_input_activation_code'}>
                  <TextInput
                    title={'Activation code'}
                    id={'activation_code'}
                    value={activationCode}
                    error={errorActivationCode}
                    focus={true}
                    disabled={!checkBoxLicense}
                    onchange={(e) => {
                      changeActivationCode(e.target.value);
                      changeErrorActivationCode(false);
                    }}
                  />
                </div>
              ) : null}
            </div>

            <div style={{ padding: '0 16px 0' }}>
              <div style={{ paddingTop: 0 }} className={'edit_grant_wrapper'}>
                <span>Grants</span>
                <div className={'edit_grant'}>
                  <div>
                    <CustomCheckbox
                      value={activeAddData.grants.includes('Management')}
                      onclick={() => {
                        let localArr = activeAddData;
                        if (activeAddData.grants.length > 0) {
                          if (activeAddData.grants.includes('Management')) {
                            activeAddData.grants.forEach((grant, index) => {
                              if (grant === 'Management') {
                                localArr.grants.splice(index, 1);
                                setActiveAddData((prevState) => ({
                                  ...prevState,
                                  grants: localArr.grants,
                                }));
                              }
                            });
                          } else {
                            localArr.grants.push('Management');
                            setActiveAddData((prevState) => ({
                              ...prevState,
                              grants: localArr.grants,
                            }));
                          }
                        } else {
                          localArr.grants.push('Management');
                          setActiveAddData((prevState) => ({
                            ...prevState,
                            grants: localArr.grants,
                          }));
                        }
                      }}
                      title={'Management'}
                    />
                    <span>
                      Your API Key can Create, Update or Remove person.
                    </span>
                  </div>
                  <div>
                    <CustomCheckbox
                      value={activeAddData.grants.includes('Identification')}
                      onclick={() => {
                        let localArr = activeAddData;
                        if (activeAddData.grants.length > 0) {
                          if (activeAddData.grants.includes('Identification')) {
                            activeAddData.grants.forEach((grant, index) => {
                              if (grant === 'Identification') {
                                localArr.grants.splice(index, 1);
                                setActiveAddData((prevState) => ({
                                  ...prevState,
                                  grants: localArr.grants,
                                }));
                              }
                            });
                          } else {
                            localArr.grants.push('Identification');
                            setActiveAddData((prevState) => ({
                              ...prevState,
                              grants: localArr.grants,
                            }));
                          }
                        } else {
                          localArr.grants.push('Identification');
                          setActiveAddData((prevState) => ({
                            ...prevState,
                            grants: localArr.grants,
                          }));
                        }
                      }}
                      title={'Identification'}
                    />
                    <span>Your API Key can only Identify person.</span>
                  </div>
                </div>
              </div>

              <div className={'edit_grant_wrapper'}>
                <span>Description</span>
                <div className={'edit_description'}>
                  <TextInput
                    title={
                      'Add additional information that you think will be useful to you in the future.'
                    }
                    style={{ marginTop: '10px' }}
                    value={activeAddData.description}
                    onchange={(e) => {
                      let test = e.target.value;
                      if (test.length <= 50)
                        setActiveAddData((prevState) => ({
                          ...prevState,
                          description: test,
                        }));
                    }}
                  />
                </div>
                {descriptionError ? (
                  <span className={'license_error'}>
                    Max length of description is 50 symbols
                  </span>
                ) : null}
              </div>
              <div className={'edit_tags_wrapper'}>
                <span>Tags</span>
                <div className={'edit_description'}>
                  <span>
                    Add tags and assign keywords that matter to you. Use tags to
                    search and structure licenses.
                  </span>
                  <div>
                    <TagsInput
                      value={tagsAddArr.tags}
                      maxTags={5}
                      onChange={(tags) => {
                        console.log(tags);
                        setTagsAddArr((prevState) => ({
                          ...prevState,
                          tags: tags,
                        }));
                      }}
                      inputProps={{
                        className: 'react-tagsinput-input',
                        placeholder:
                          tagsAddArr.tags.length <= 4
                            ? 'Add a tag + Enter'
                            : '',
                        disabled: tagsAddArr.tags.length > 4,
                      }}
                      inputValue={activeTagValue}
                      onChangeInput={(value) => {
                        if (value.length <= 10) {
                          setActiveTagValue(value);
                        }
                      }}
                    />
                  </div>
                  {tagsError ? (
                    <span className={'license_error'}>
                      Max amount of tags is 5
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <MainButton
              id={'lastAdd'}
              width={'155px'}
              margin={'25px 16px'}
              onclick={() => generateLicense()}
              button={'accent'}
              text={'Add License'}
            />
          </form>
        </div>
      ) : null}
      <div className={'dashboard_wrapper'}>
        {testData ? (
          <div className={'test_data'}>
            <img src={warningImg} alt="" />
            <span>
              There are test data at your dashboard right now. When you will
              send first request to the API all test date deletes automaticaly.
            </span>
            <div style={{ marginLeft: 'auto' }}>
              <MainButton
                button={'delete'}
                text={'Delete test data'}
                onclick={() => deleteTestData()}
                style={{
                  width: '155px',
                  textTransform: 'unset',
                  margin: '13px',
                }}
              />
            </div>
          </div>
        ) : null}
        <div className={'user_data_wrapper'}>
          <div className={'user_data_plan'}>
            <span>{testData ? 'Free' : limitsData?.title}</span>
            <LicenseStatuses status={'active'} />
            <span className={'gray_text'}>
              <span
                style={{
                  fontSize: '22px',
                  fontWeight: '500',
                  lineHeight: '30px',
                  color: '#0CCC92',
                }}
              >
                $
                {testData
                  ? '0.00'
                  : limitsData?.paymentPeriod === 'YEAR'
                  ? (limitsData?.amount / 12).toFixed(0)
                  : limitsData?.amount.toFixed(2)}
              </span>{' '}
              / month
            </span>
            <MainButton
              onclick={() => setUpgradePlan(true)}
              style={{ width: '100%', marginTop: '4px' }}
              text={'Update Plan'}
            />
          </div>
          <div className={'user_data_stats'}>
            <div className={'used_data_grid'}>
              <div className={'data'}>
                <span className={'gray_text'}>Requests used</span>
                <span className={'stats_text'}>
                  {testData ? '18000000' : limitsData?.subscriptionRequestsUsed}
                </span>
              </div>
              <UsedDataComponent
                margin={'0 0 6px auto'}
                usedData={
                  testData
                    ? 90
                    : (
                        (limitsData?.subscriptionRequestsUsed /
                          limitsData?.maxRequestsNumber) *
                        100
                      ).toFixed(0)
                }
              />
            </div>
            <div className={'data'}>
              <span className={'gray_text'}>Request limit</span>
              <span className={'stats_text_bottom'}>
                {testData ? '18000000' : limitsData?.maxRequestsNumber}
              </span>
            </div>
          </div>
          <div className={'user_data_stats'}>
            <div className={'used_data_grid'}>
              <div className={'data'}>
                <span className={'gray_text'}>Users in DB used</span>
                <span className={'stats_text'}>
                  {testData ? '2' : limitsData?.personsInDb}
                </span>
              </div>
              <UsedDataComponent
                margin={'0 0 6px auto'}
                usedData={
                  testData
                    ? '10'
                    : limitsData?.personsInDb === 0 ||
                      limitsData?.maxPersonsNumber === 0
                    ? 0
                    : (
                        (limitsData?.personsInDb /
                          limitsData?.maxPersonsNumber) *
                        100
                      ).toFixed(0)
                }
              />
            </div>
            <div className={'data'}>
              <span className={'gray_text'}>Users limit in DB</span>
              <span className={'stats_text_bottom'}>
                {testData ? '100' : limitsData?.maxPersonsNumber}
              </span>
            </div>
          </div>
          <div className={'user_data_stats'}>
            <div className={'used_data_grid'}>
              <div className={'data'}>
                <span className={'gray_text'}>License used</span>
                <span className={'stats_text'}>
                  {testData ? '0' : limitsData?.subscriptionDevices}
                </span>
              </div>
              <UsedDataComponent
                margin={'0 0 6px auto'}
                usedData={
                  testData
                    ? '0'
                    : limitsData?.subscriptionDevices === 0 ||
                      limitsData?.maxDeviceKeysNumber === 0
                    ? 0
                    : (
                        (limitsData?.subscriptionDevices /
                          limitsData?.maxDeviceKeysNumber) *
                        100
                      ).toFixed(0)
                }
              />
            </div>
            <div className={'data'}>
              <span className={'gray_text'}>Subscribe license</span>
              <span className={'stats_text_bottom'}>
                {testData ? '0' : limitsData?.maxDeviceKeysNumber}
              </span>
            </div>
          </div>
        </div>
        <div className={'chart_wrapper chart_wrapper1'}>
          {!requestLoader ? (
            <div>
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
                className={'no_licenses_wrapper'}
              >
                <img src={loaderGif} alt="loader" />
              </div>
            </div>
          ) : null}
          <div className={'chart_header'}>
            <span>Requests used</span>
            <div className={'dropdowns_wrapper'}>
              <MultipleSelect
                width={'237px'}
                dropdownStyle={{ height: '254px', overflowY: 'hidden' }}
                placeholder={'Choose request data'}
                removeValue={(data) => {
                  if (requestTypeList.length > 1) {
                    setRequestTypeList((requestList) =>
                      requestList.filter((request) => request !== data)
                    );
                  }
                }}
                value={
                  requestTypeList.length
                    ? requestTypeList.join(', ').length > 24
                      ? requestTypeList.join(', ').substring(0, 24) + '...'
                      : requestTypeList.join(', ')
                    : ''
                }
                dataList={[
                  requestTypesEnum.CREATE_PERSON,
                  requestTypesEnum.UPDATE_PERSON,
                  requestTypesEnum.DELETE_PERSON,
                  requestTypesEnum.GET_PERSON,
                  requestTypesEnum.RECOGNIZE,
                  requestTypesEnum.LIVENESS_CHECK,
                ]}
                valueList={requestTypeList}
                setValueList={(data) =>
                  setRequestTypeList((prevState) => [...prevState, data])
                }
              />
              <SelectDropdown
                placeholder={'Choose data period'}
                width={'178px'}
                dataList={[
                  'Last 24 hours',
                  'Last 7 days',
                  'Last 4 weeks',
                  'Last 12 months',
                ]}
                dropdownStyle={{ height: '153.5px', overflowY: 'hidden' }}
                value={timePeriodRequests}
                setVal={(data) => setTimePeriodRequests(data)}
              />
            </div>
          </div>
          <LineChart
            width={895}
            height={370}
            data={dataChart}
            margin={{ top: 55, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              axisLine={{ stroke: '#E2E4E9' }}
              tickLine={{ stroke: '#E2E4E9' }}
              tick={{ stroke: '#f8faff', strokeWidth: 0.5 }}
              dataKey="name"
            />
            <YAxis
              axisLine={{ stroke: '#E2E4E9' }}
              tickLine={{ stroke: '#E2E4E9' }}
              tickCount={6}
              tick={{ stroke: '#f8faff', strokeWidth: 0.5 }}
              minTickGap={'1'}
              domain={[0, 'dataMax']}
            />
            <Tooltip itemSorter={(item) => -1 * item.value} />
            <Legend />
            {RenderGraphLine(requestTypeList, requestTypesEnum.CREATE_PERSON)}
            {RenderGraphLine(requestTypeList, requestTypesEnum.UPDATE_PERSON)}
            {RenderGraphLine(requestTypeList, requestTypesEnum.DELETE_PERSON)}
            {RenderGraphLine(requestTypeList, requestTypesEnum.GET_PERSON)}
            {RenderGraphLine(requestTypeList, requestTypesEnum.RECOGNIZE)}
            {RenderGraphLine(requestTypeList, requestTypesEnum.LIVENESS_CHECK)}
          </LineChart>
        </div>
        <div className={'pie_chart_wrapper'}>
          <div className={'pie_chart_block'}>
            <div className={'pie_chart_header'}>
              <span style={{ marginTop: '8px' }}>Requests by types</span>
              <SelectDropdown
                placeholder={'Choose data period'}
                width={'178px'}
                dataList={[
                  'Last 24 hours',
                  'Last 7 days',
                  'Last 4 weeks',
                  'Last 12 months',
                ]}
                dropdownStyle={{ height: '153.5px', overflowY: 'hidden' }}
                value={timePeriodPie}
                setVal={(data) => setTimePeriodPie(data)}
              />
            </div>
            <div className={'pie_chart_content'}>
              {!pieLoader ? (
                <div>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 9999,
                    }}
                    className={'no_licenses_wrapper'}
                  >
                    <img src={loaderGif} alt="loader" />
                  </div>
                </div>
              ) : null}
              <PieChart width={170} height={170}>
                <Pie
                  data={dataPie}
                  cx={80}
                  cy={80}
                  innerRadius={65}
                  outerRadius={84}
                  fill="#8884d8"
                  paddingAngle={0}
                >
                  {dataPie.map((entry, index) => (
                    <Cell fill={getColor(entry.name)} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <div className={'pie_chart_data'}>
                {dataPie.map((data, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{ backgroundColor: getColor(data.name) }}
                      ></div>
                      <div>
                        <span>{data.name}:</span>
                        <div>
                          <span>
                            {allRequestsByType && allRequestsByType.value
                              ? (
                                  (data.value / allRequestsByType.value) *
                                  100
                                ).toFixed(1) %
                                  (
                                    (data.value / allRequestsByType.value) *
                                    100
                                  ).toFixed(0) ===
                                0
                                ? (
                                    (data.value / allRequestsByType.value) *
                                    100
                                  ).toFixed(0) + '%'
                                : (
                                    (data.value / allRequestsByType.value) *
                                    100
                                  ).toFixed(1) + '%'
                              : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={'licenses_types_wrapper'}>
            <span>Licenses Types</span>
            <div className={'licenses_data'}>
              <div>
                <span>Total number:</span>
                <span>
                  {testData
                    ? '10'
                    : limitsData?.permanentDevices +
                      limitsData?.subscriptionDevices}
                </span>
              </div>
              <div>
                <span>Subscription based</span>
                <span>{testData ? '0' : limitsData?.subscriptionDevices}</span>
              </div>
              {/*<div>*/}
              {/*    <span>Available in plan</span>*/}
              {/*    <span>{testData ? '0' : limitsData?.maxDeviceKeysNumber}</span>*/}
              {/*</div>*/}
              <div>
                <span>Permanent</span>
                <span>{testData ? '10' : limitsData?.permanentDevices}</span>
              </div>
            </div>
            <div className={'licenses_footer'}>
              <span>
                To activate a permanent license, use the activation code.
              </span>
              <MainButton
                onclick={() => {
                  document.getElementsByTagName('body')[0].style.overflowY =
                    'hidden';
                  setCreateLicense(true);
                }}
                margin={'11px 0 0'}
                text={'Activate license'}
              />
            </div>
          </div>
        </div>
        <div className={'chart_wrapper chart_wrapper2'}>
          {!userLoader ? (
            <div>
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
                className={'no_licenses_wrapper'}
              >
                <img src={loaderGif} alt="loader" />
              </div>
            </div>
          ) : null}
          <div className={'chart_header'}>
            <span>Users in DB</span>
            <div className={'dropdowns_wrapper'}>
              <SelectDropdown
                placeholder={'Choose data period'}
                width={'178px'}
                dataList={[
                  'Last 24 hours',
                  'Last 7 days',
                  'Last 4 weeks',
                  'Last 12 months',
                ]}
                dropdownStyle={{ height: '153.5px', overflowY: 'hidden' }}
                value={timePeriodUsers}
                setVal={(data) => setTimePeriodUsers(data)}
              />
            </div>
          </div>
          <LineChart
            width={895}
            height={370}
            data={dataDB}
            margin={{ top: 55, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              axisLine={{ stroke: '#E2E4E9' }}
              tickLine={{ stroke: '#E2E4E9' }}
              tick={{ stroke: '#f8faff', strokeWidth: 0.5 }}
              dataKey="name"
            />
            <YAxis
              axisLine={{ stroke: '#E2E4E9' }}
              tickLine={{ stroke: '#E2E4E9' }}
              interval={0}
              tick={{ stroke: '#f8faff', strokeWidth: 0.5 }}
              minTickGap={1}
              tickCount={6}
              domain={[0, 'dataMax']}
            />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dot={{ stroke: '#FFAB00', strokeWidth: 2, fill: '#FFAB00' }}
              dataKey="Users"
              stroke="#FFAB00"
              activeDot={{ stroke: '#FFAB00', strokeWidth: 3, r: 6 }}
            />
          </LineChart>
        </div>
        <div className={'chart_wrapper log_wrapper'}>
          <div className={'log_header'}>
            <span>Identifications log</span>
          </div>
          <div className={'log_content'}>
            <div className={'log_content_header'}>
              <span>Time</span>
              <span>Source</span>
              <span>Request ID</span>
              <span>Grants</span>
              <span>Status</span>
            </div>
            {identLogData.map((log, key) => {
              let local = Moment.utc(log.time)
                .local()
                .format('YYYY-MM-DD HH:mm:ss');
              return (
                <div key={key}>
                  <div>
                    <span>{local}</span>
                  </div>
                  <div>
                    {ApiTooltip === key ? (
                      <TooltipCustom
                        style={{ width: 'max-content' }}
                        text={log.sourceId}
                      />
                    ) : null}
                    <span
                      className={'source'}
                      onMouseOver={() => setApiTooltip(key)}
                      onMouseOut={() => setApiTooltip(false)}
                    >
                      {log.source}
                    </span>
                  </div>
                  <div>
                    {descriptionTooltip === key ? (
                      <TooltipCustom
                        style={{ width: 'max-content' }}
                        text={log.id}
                      />
                    ) : null}
                    <span
                      onMouseOver={() => setDescriptionTooltip(key)}
                      onMouseOut={() => setDescriptionTooltip(false)}
                    >
                      {log.id.substring(0, 37) + '...'}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, max-content)',
                      gridGap: '4px',
                    }}
                  >
                    {log.grants &&
                      log.grants.length > 0 &&
                      log.grants.map((grants, grants_index) => {
                        if (log.grants.length - 1 === grants_index) {
                          return (
                            <div style={{ width: 'max-content' }}>
                              <span
                                onMouseOver={() => setGrantIdeTooltip(key)}
                                onMouseOut={() => setGrantIdeTooltip(false)}
                              >
                                {grantIdeTooltip === key ? (
                                  <TooltipCustom
                                    style={
                                      grants === 'Identification'
                                        ? { width: '243px' }
                                        : grants === 'Management'
                                        ? { width: '270px' }
                                        : null
                                    }
                                    grant={
                                      log.source === 'API'
                                        ? grants + 'Key'
                                        : grants + 'Dash'
                                    }
                                  />
                                ) : null}
                                {grants}
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div style={{ width: 'max-content' }}>
                              <span
                                onMouseOver={() => setMenIdeTooltip(key)}
                                onMouseOut={() => setMenIdeTooltip(false)}
                              >
                                {grantManTooltip === key ? (
                                  <TooltipCustom
                                    style={
                                      grants === 'Identification'
                                        ? { width: '243px' }
                                        : grants === 'Management'
                                        ? { width: '270px' }
                                        : null
                                    }
                                    grant={
                                      log.source === 'API'
                                        ? grants + 'Key'
                                        : grants + 'Dash'
                                    }
                                  />
                                ) : null}
                                {grants + ', '}
                              </span>
                            </div>
                          );
                        }
                      })}
                  </div>
                  <div>
                    {statusTooltip === key &&
                    log.status === 'Failed' &&
                    !testData ? (
                      <TooltipCustom
                        style={{ width: 'max-content' }}
                        text={
                          <span>
                            Error code: {log.errorCode} <br /> Error message:{' '}
                            {log.errorMessage}
                          </span>
                        }
                      />
                    ) : null}
                    <span
                      style={
                        log.status === 'Failed'
                          ? { color: '#FE3E3E' }
                          : { color: '#0CCC92' }
                      }
                      className={'status'}
                      onMouseOver={() => setStatusTooltip(key)}
                      onMouseOut={() => setStatusTooltip(false)}
                    >
                      {log.status}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    createLicense: state.shop.createLicense,
  };
};

const mapDispatchToProps = {
  showCreateLicense,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
