import React from 'react';
import '../../components/style/login.scss';
import MainButton from '../MainButton';

const ConfirmEmail = (props) => {
  const email = props.email;
  const domain = email.split('@')[1];

  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.href = `http://${domain}`;
  };

  return (
    <div style={{ width: '430px', padding: '30px' }} className="login__wrapper">
      <form className="login-form" onSubmit={(event) => handleSubmit(event)}>
        <h2 style={{ fontWeight: '500' }}>Confirm your email address</h2>
        <div style={{ marginBottom: 0 }} className="login-form__change">
          <p style={{ fontSize: '17px', lineHeight: '20px', color: '#0A1F44' }}>
            Your confirmation link was sent to your email.
          </p>
        </div>
        <div className="login__email">{email}</div>
        <MainButton
          margin={'16px 0 0'}
          button={'main_big'}
          type={'submit'}
          text={`GO TO ${domain}`}
        />
      </form>
    </div>
  );
};

export default ConfirmEmail;
