import React, { useEffect } from 'react';
import Notification from './Notification';

const Header = (props) => {
  useEffect(() => {
    if (props.popupAlert) {
      setTimeout(() => props.setPopupAlert(false), 4000);
    }
    if (props.popupAlertText && props.setPopupAlertText) {
      setTimeout(() => props.setPopupAlertText(''), 4000);
    }
  });

  return (
    <header className={'dashboardHeader'}>
      {props.popupAlert === 1 ||
      props.popupAlert === 2 ||
      props.popupAlert === 3 ? (
        <Notification
          alert={
            props.popupAlert === 1
              ? 'success'
              : props.popupAlert === 2
              ? 'warning'
              : props.popupAlert === 3
              ? 'error'
              : null
          }
          text={props.popupAlertText ? props.popupAlertText : null}
          onclick={() => {
            props.setPopupAlert(false);
            if (props.setPopupAlertText && props.popupAlertText)
              props.setPopupAlertText('');
          }}
        />
      ) : null}
    </header>
  );
};

export default Header;
