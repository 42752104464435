import React, {useEffect, useState} from 'react';
import "../components/style/pricing.css"
import pricingActive from '../assets/pricing_active.svg'
import pricingInactive from '../assets/pricing_inactive.svg'
import {withRouter} from "react-router-dom";
import Layout from "../components/Layout";
import API from "../API";
import OrderPopup from "../components/OrderPopup";
import MainButton from "../components/MainButton";
import loaderGif from "../assets/loader.gif";
import Moment from 'moment';

const Pricing = (props) => {
    const [perMonth, setPerMonth] = useState(true);
    const [activePlanData, setActivePlanData] = useState(undefined)
    const [planData, setPlanData] = useState(undefined)
    const [paymentPage, setPaymentPage] = useState(false)
    const [plans, setPlans] = useState([])
    const [planPrice, setPlanPrice] = useState([])
    const [loader, setLoader] = useState(true)


    useEffect(() => {
        API.post('/limit/template/available/get')
            .then(res => {
                console.log(res)
                setPlans(res.data.limitTemplates)
            })
        API.post('/limit/price/list')
            .then(res => {
                console.log(res)
                setPlanPrice(res.data.prises)
            })
        API.post('/account/limit/list/get', {accountKey: localStorage.getItem('RiddletagAccountKey')})
            .then(res => {
                console.log(res)
                setPlanData(res.data.limits)
                setTimeout(() => setLoader(false), 500)
            })
    }, [])


    useEffect(() => {
        document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    })

    useEffect(() => {
        if (paymentPage) {
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                    setTimeout(()=>setPaymentPage(false), 500)
                }
            })
        }
    }, [paymentPage]);

    console.log(plans)
    return (
        <Layout>
            {paymentPage ? <OrderPopup goToDashboard={() => props.goToDashboard()} closePopup={() => setPaymentPage(false)} activeData={activePlanData}/> : null}
            {loader ?
                <div className={'loader_wrapper'}>
                    <img src={loaderGif} alt="loader"/>
                </div>
            : null}
            <div style={props.fixed ? {
                position: 'fixed',
                top: '0',
                left: '0',
                height: '100%',
                width: '100%',
                background: '#F9F9F9',
                zIndex: '1001',
                justifyContent: 'unset',
                overflowY: 'auto',
                padding: '78px 38px',
                alignContent: 'unset'
            } : null} className={'pricing_wrapper'}>
                <div className={'pricing_content'}>
                    <div className={'pricing_top'}>
                        <h2 style={{marginBottom: 0}} className={'children-wrapper__page-header'}>Pricing plans</h2>
                        <div style={!perMonth ? {gridTemplateColumns: 'repeat(4, max-content)'} : null} className={'top_buttons'}>
                            {perMonth ? null :
                                <span style={{borderRadius: '15px', padding: '5px 20px', marginRight: '36px', background: '#F1FF54', color: '#333333', border: '1px solid #CFDD2A'}} className={'active_plan'}>Save 17%</span> }
                            <span className={'billing_type'}>Billing Type: </span>
                            <MainButton style={{height: '30px', width: '100px', textTransform: 'unset'}} onclick={() => setPerMonth(true)}
                                        button={perMonth ? 'main' : 'cancel'} text={"Monthly"}/>
                            <MainButton  style={{height: '30px', width: '90px', textTransform: 'unset'}} onclick={() => setPerMonth(false)}
                                        button={!perMonth ? 'main' : 'cancel'} text={"Annual"}/>
                        </div>
                    </div>
                    <div style={props.fixed ? {height: "fit-content"} : null} className={'pricing_body'}>
                        {plans.map((plan, index) => {
                            let localPriceTest = {};
                            let localPriceTestMonth = {}
                            let tempPlan = {};
                            let localKey = null;
                            let localType = '';
                            planPrice.forEach((price, i) => {
                                planData && planData.forEach((activePlan) => {
                                    console.log(planData)
                                    if (activePlan.key === price.key) {
                                        tempPlan = activePlan
                                    }
                                })
                                if (price.limitTemplateKey === plan.key) {
                                    if (perMonth) {
                                        if (price.paymentPeriod === "MONTH") {
                                            localPriceTest = price
                                            localKey = price.key
                                            localType = price.paymentPeriod
                                        }
                                    } else {
                                        if (price.paymentPeriod === "YEAR") {
                                            localPriceTest = price
                                            localKey = price.key
                                            localType = price.paymentPeriod
                                        }
                                        else if (price.paymentPeriod === "MONTH") {
                                            localPriceTestMonth = price
                                        }
                                    }
                                }
                            })
                            console.log(plans)
                            return  <div className="pricing_col" key={index}>
                                <div className="pricing_head">
                                    <div className={'plan_name_wrapper'}>
                                        <span className={'plan_name'}>{plan.title}</span>
                                        {tempPlan.key === localPriceTest.key  ?
                                            <span className={'active_plan'}>Active</span> :
                                            <span style={{opacity: 0}} className={'active_plan'}>...</span>
                                        }
                                    </div>
                                    <div style={plan.isFree ? {gridTemplateColumns: '1fr'} : null}
                                         className={'pricing_price'}>
                                        <div className={'price_col'}>
                                            <span
                                                className={'price_head'}>{plan.isFree ? 'Fixed limits in plan' : perMonth ? 'Billed monthly' : 'Billed annually'}</span>
                                            <span className={'price'}>
                                    {!plan.isFree ? localPriceTest && localPriceTest.amount ? '$' + ((localPriceTest.amount / 100 )/ (!perMonth ? 12 : 1)).toFixed(0) : null : 'No time limit'}
                                                {plan.title === 'Basic' || plan.title === 'Business' ?
                                                    <span className={'per_month_text'}> /month</span> : null}</span>
                                        </div>
                                        {plan.isFree ? null :
                                            <div className={'price_col pricing_col_last'}>
                                                <span className={'price_head'}>{perMonth ? `Request\u00A0cost` : 'Save\u00A0per\u00A0year'}</span>
                                                <span className={'request_price'}>{perMonth ? localPriceTest && localPriceTest.amount ? '$' + (((localPriceTest.amount / 100 / (!perMonth ? 12 : 1)) / plan.maxRequestsNumber).toFixed(plan.title === 'Basic' || !perMonth ? 2 : 3)) : null :
                                                    localPriceTest && localPriceTest.amount && localPriceTestMonth && localPriceTestMonth.amount ? '$' + ((((localPriceTestMonth.amount / 100 )).toFixed(0) - ((localPriceTest.amount / 100 / 12)).toFixed(0)) * 12) : null
                                                } </span>
                                            </div>
                                        }
                                    </div>
                                    <div className={'pricing_head_info'}>
                                        <span>3D Face analysis software:</span>
                                        <span>{plan.maxDeviceKeysNumber}</span>
                                    </div>
                                    <div className={'pricing_head_info'}>
                                        <span>API requests:</span>
                                        <span>{plan.maxRequestsNumber < 100000 ? plan.maxRequestsNumber : (plan.maxRequestsNumber / 1000) + 'k'}</span>
                                    </div>
                                    <div className={'pricing_head_info'}>
                                        <span>Number users in DB:</span>
                                        <span>{plan.maxPersonsNumber < 100000 ? plan.maxPersonsNumber : (plan.maxPersonsNumber / 1000) + 'k'}</span>
                                    </div>
                                </div>
                                <div className={'pricing_features'}>
                                    <div className={'feature'}>
                                        <img src={pricingActive} alt=""/>
                                        <span>Person Identification 1:N</span>
                                    </div>
                                    <div className={'feature'}>
                                        <img src={pricingActive} alt=""/>
                                        <span>Person Verification 1:1</span>
                                    </div>
                                    <div className={'feature'}>
                                        <img src={pricingActive} alt=""/>
                                        <span>Persons comparison</span>
                                    </div>
                                    <div className={'feature'}>
                                        <img src={pricingActive} alt=""/>
                                        <span>Liveness check</span>
                                    </div>
                                    <div className={'feature'}>
                                        <img src={pricingInactive} alt=""/>
                                        <span>Mobile SDK</span>
                                    </div>
                                    <div className={'feature'}>
                                        <img src={pricingInactive} alt=""/>
                                        <span>Double encryption</span>
                                    </div>
                                </div>
                                <div className={'pricing_footer'}>
                                    {tempPlan.key === localPriceTest.key || plan.title === 'Free plan'  ?
                                        <div className={'active_plan_footer'}>
                                            <div>
                                                <span>{plan.title === 'Free plan' ? 'Start:' : 'Start:'}</span>
                                                {plan.title === 'Free plan' ? null :
                                                    <span>{Moment(tempPlan.startDateTime).format('MMMM Do YYYY, HH:mm:ss')}</span>
                                                }
                                            </div>
                                            <div>
                                                <span>{plan.title === 'Free plan' ? 'End:' : 'End:'}</span>
                                                {plan.title === 'Free plan' ? <span>Until you run out of requests</span> :
                                                    <span>{Moment(tempPlan.expirationDateTime).format('MMMM Do YYYY, HH:mm:ss')}</span>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <MainButton onclick={() => {
                                            setActivePlanData({
                                                plan: plan.title,
                                                price: (localPriceTest.amount / 100),
                                                licenses: plan.maxDeviceKeysNumber,
                                                requests: plan.maxRequestsNumber,
                                                users: plan.maxPersonsNumber,
                                                key: localKey,
                                                type: localType
                                            });
                                            setPaymentPage(true)
                                        }} button={'main'} text={'Upgrade plan'}/>
                                    }
                                </div>
                            </div>
                        })}
                        <div className="pricing_col">
                            <div className="pricing_head">
                                <div className={'plan_name_wrapper'}>
                                    <span className={'plan_name'}>Enterprise</span>
                                    <span style={{opacity: 0}} className={'active_plan'}>...</span>
                                </div>
                                <div style={{gridTemplateColumns: "1fr"}} className={'pricing_price'}>
                                    <div className={'price_col'}>
                                        <span className={'price_head'}>Meets your business needs</span>
                                        <span style={{marginTop: 0}} className={'price'}>Custom price</span>
                                    </div>
                                </div>
                                <div className={'pricing_head_info'}>
                                    <span>3D Face analysis software:</span>
                                    <span>from 30</span>
                                </div>
                                <div className={'pricing_head_info'}>
                                    <span>API requests:</span>
                                    <span>Custom</span>
                                </div>
                                <div className={'pricing_head_info'}>
                                    <span>Number users in DB:</span>
                                    <span>Custom</span>
                                </div>
                            </div>
                            <div className={'pricing_features'}>
                                <div className={'feature'}>
                                    <img src={pricingActive} alt=""/>
                                    <span>Person Identification 1:N</span>
                                </div>
                                <div className={'feature'}>
                                    <img src={pricingActive} alt=""/>
                                    <span>Person Verification 1:1</span>
                                </div>
                                <div className={'feature'}>
                                    <img src={pricingActive} alt=""/>
                                    <span>Persons comparison</span>
                                </div>
                                <div className={'feature'}>
                                    <img src={pricingActive} alt=""/>
                                    <span>Liveness check</span>
                                </div>
                                <div className={'feature'}>
                                    <img src={pricingActive} alt=""/>
                                    <span>Mobile SDK</span>
                                </div>
                                <div className={'feature'}>
                                    <img src={pricingActive} alt=""/>
                                    <span>Double encryption</span>
                                </div>
                            </div>
                            <div className={'pricing_footer'}>
                                <MainButton onclick={() => window.open('https://share.hsforms.com/1biyzZXo7ToulNyOmM8DCFw3hfoa')} button={'main'} text={"Get a quote"}/>
                            </div>
                        </div>
                    </div>
                    <div className={'pricing_annual_info'}>
                        <span>*Please note that the limits specified in the pricing plans are valid only for one month.</span>
                        <span>**The Free plan is valid until the end of the limit and is not limited in time.</span>
                    </div>
                </div>
            </div>
        </Layout>

    )
}

export default withRouter(Pricing)















