export const SHOW_CREATE_LICENSE = 'SHOW_CREATE_LICENSE';
export const SET_REDIRECT_MODAL = 'SET_REDIRECT_MODAL';

export const showCreateLicense = text => ({
    type: SHOW_CREATE_LICENSE,
    payload: text
});

export const setRedirectModal = text => ({
    type: SET_REDIRECT_MODAL,
    payload: text
});
