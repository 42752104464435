import React, { useEffect, useState } from 'react';
import '../components/style/login.scss';
import { withRouter } from 'react-router-dom';
import { Signup } from '../components/signup';
import LoginComponent from '../components/login/LoginComponent';
import PasswordReset from '../components/login/PasswordReset';
import ConfirmEmail from '../components/login/ConfirmEmail';
import LoginLayout from '../components/LoginLayout';
import { Keys } from '../helper';
import API from '../API';

const LoginPage = (props) => {
  const [isLogIn, setIsLogIn] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [email, setEmail] = useState('');

  const checkIfReset = () => {
    const url = window.location;
    const shouldSignup = new URLSearchParams(url.search).get('signup');
    if (shouldSignup) setIsLogIn(false);
    else {
      const shouldReset = new URLSearchParams(url.search).get('reset');
      if (shouldReset) setIsReset(true);
    }
  };

  useEffect(() => {
    checkIfReset();
  }, []);

  useEffect(() => {
    const url = window.location;
    const token = new URLSearchParams(url.search).get('token');
    const type = new URLSearchParams(url.search).get('type');
    if (token && type) {
      if (type === 'registration') {
        API.get(`/registration/redirect/set?token=${token}`).then((res) => {
          localStorage.setItem(
            Keys.AUTHORIZATION_TOKEN,
            res.data.authorizationToken
          );
          localStorage.setItem(Keys.ACCOUNT_KEY, res.data.accountKey);
        });
      } else if (type === 'forgotPassword') {
        API.get(`/account/password/recovery/redirect/set?token=${token}`).then(
          (res) => {
            localStorage.setItem(
              Keys.AUTHORIZATION_TOKEN,
              res.data.authorizationToken
            );
            localStorage.setItem(Keys.ACCOUNT_KEY, res.data.accountKey);
          }
        );
      }
    }
  }, []);

  return (
    <LoginLayout
      isReset={isReset}
      setIsReset={(e) => setIsReset(e)}
      email={email}
      setEmail={() => setEmail('')}
      setIsLogInCustom={(e) => setIsLogIn(e)}
      setIsLogIn={() => setIsLogIn(!isLogIn)}
      isLogIn={isLogIn}
    >
      {isLogIn ? (
        isReset ? (
          email ? (
            <ConfirmEmail email={email} />
          ) : (
            <PasswordReset
              changeToLogin={() => setIsReset(false)}
              changeToConfirm={(email) => setEmail(email)}
            />
          )
        ) : (
          <LoginComponent
            changeToSignUp={() => setIsLogIn(false)}
            changeToReset={() => setIsReset(true)}
          />
        )
      ) : email ? (
        <ConfirmEmail email={email} />
      ) : (
        <Signup
          changeToLogIn={() => setIsLogIn(true)}
          changeToConfirm={(email) => setEmail(email)}
        />
      )}
    </LoginLayout>
  );
};

export default withRouter(LoginPage);
