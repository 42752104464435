import React from "react"
import {Keys} from "../helper";
import API from "../API";
import TextInput from "./TextInput";
import MainButton from "./MainButton";
import loaderGif from "../assets/loader.gif";


class ProfileUpdateComponent extends React.Component {

    state = {
        shouldRender: !this.props.updateProfile,
        companyName: "",
        firstName: "",
        lastName: "",
        industry: "",
        phone: 0,
        title: "",
        buttonDisabled: true,
        loader: true
    };

    componentDidMount() {
        this.getData()
    }

    //
    // checkIfEdited = (prevState) => {
    //     if(prevState.companyName !== this.state.companyName || prevState.firstName !== this.state.firstName ||
    //         prevState.lastName !== this.state.lastName || prevState.industry !== this.state.industry ||
    //         prevState.phone !== this.state.phone || prevState.title !== this.state.title) {
    //         this.setState({
    //             buttonDisabled: false
    //         })
    //     }
    // }

    getData = () => {
        const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY);
        API.post(`account/get`, {accountKey})
            .then((res) => {
                if (res.data.companyName) this.props.redirectToPayment();
                else {
                    this.props.setShouldLoad(true);
                }

                let newState = {};
                for (const [key, value] of Object.entries(res.data)) {
                    if (value) {
                        if (key === "phone") newState[key] = Number(value);
                        else newState[key] = value
                    }
                }
                localStorage.setItem(Keys.EMAIL, res.data.email);
                const firstInitial = res.data.lastName && res.data.lastName.charAt(0) ? res.data.lastName.charAt(0) : "";
                const lastInitial = res.data.firstName && res.data.firstName.charAt(0) ? res.data.firstName.charAt(0) : "";
                const initials = firstInitial + lastInitial;
                localStorage.setItem(Keys.INITIALS, initials);
                newState.accountKey = accountKey;

                this.setState({
                    shouldRender: true,
                })
                this.setState(newState);
                setTimeout(() => this.setState({loader: false}), 2000)
            })
    };

    handleSubmit = (event) => {
        event.preventDefault();
        API.post("/account/set", this.state).then(() => {
            localStorage.setItem(Keys.EMAIL, this.state.email);
            const firstInitial = this.state.lastName && this.state.lastName.charAt(0) ? this.state.lastName.charAt(0) : "";
            const lastInitial = this.state.firstName && this.state.firstName.charAt(0) ? this.state.firstName.charAt(0) : "";
            const initials = firstInitial + lastInitial;
            localStorage.setItem(Keys.INITIALS, initials);
            if (this.props.updateProfile) window.location = "/dashboard";
            else {
                this.props.popupAlert(1);
                this.getData()
            }
        })
    };

    render() {
        if (!this.state.shouldRender) return (<div/>);
        else
            return (
                <div className="login__wrapper"
                     style={this.props.width ?  this.props.updateProfile ?
                         {
                         marginTop: "109px",
                         padding: '0',
                         width: this.props.width,
                     } : {
                             marginTop: "0",
                             padding: '0',
                             width: this.props.width,
                         } : this.props.updateProfile ? {padding: '0', marginTop: '109px'} : {padding: '0', marginTop: '0'}}>
                    {this.state.loader ?
                        <div style={{height: '565px'}} className={'mini_loader_wrapper'}>
                            <img src={loaderGif} alt="loader"/>
                        </div>
                        :
                        <>
                            {this.props.updateProfile ?
                                <h2 style={{fontWeight: '400', padding: '36px 34px 12px', marginBottom: 0}}>Complete your profile!</h2>
                                :
                                <div className={'block_header'}>
                                    <span> {this.props.updateProfile ? "Complete your profile!" : "Profile information"}</span>
                                </div>
                            }

                        <form style={this.props.updateProfile ? {padding: '0 36px 30px'} : {padding: '16px'}} className="login-form"
                              onSubmit={(event) => this.handleSubmit(event)}>
                            <TextInput title={'First name *'} required={true} margin={'0 0 10px 0'}
                                       value={this.state.firstName}
                                       onchange={e => {
                                           this.setState({firstName: e.target.value})
                                           this.setState({buttonDisabled: false})
                                       }}/>
                            < TextInput title={'Last name *'} required={true} margin={'0 0 10px 0'}
                                        value={this.state.lastName}
                                        onchange={e => {
                                            this.setState({lastName: e.target.value})
                                            this.setState({buttonDisabled: false})
                                        }}/>
                            <TextInput title={'Title *'} required={true} margin={'0 0 10px 0'} value={this.state.title}
                                       onchange={e => {
                                           this.setState({title: e.target.value})
                                           this.setState({buttonDisabled: false})
                                       }}/>
                            <TextInput title={'Company name *'} required={true} margin={'0 0 10px 0'}
                                       value={this.state.companyName}
                                       onchange={e => {
                                           this.setState({companyName: e.target.value})
                                           this.setState({buttonDisabled: false})
                                       }}/>
                            <TextInput title={'Industry'} margin={'0 0 10px 0'} value={this.state.industry}
                                       onchange={e => {
                                           this.setState({industry: e.target.value})
                                           this.setState({buttonDisabled: false})
                                       }}/>
                            <TextInput title={'Phone *'} required={true} margin={'0 0 10px 0'}
                                       value={this.state.phone !== 0 ? this.state.phone : ""}
                                       onchange={e => {
                                           e.preventDefault()
                                           if (/^[0-9]*$/.test(e.target.value)) {
                                               this.setState({phone: e.target.value})
                                           }
                                           this.setState({buttonDisabled: false})
                                       }}/>

                            <MainButton style={this.props.updateProfile ? {height: '40px'} : {width: '155px'}} margin={'9px 0 0'} disabled={this.state.buttonDisabled}
                                        type={'submit'} button={this.props.updateProfile ? 'main' : 'accent'}
                                        text={this.props.updateProfile ? "GO TO DASHBOARD" : "Save"}/>
                        </form>
                    </>
                    }

                </div>
            )
    }
}

export default ProfileUpdateComponent
