import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAccountLimit } from '../../API';
import '../../components/style/login.scss';
import axios from 'axios';
import { Keys } from '../../helper';
import TextInput from '../TextInput';
import MainButton from '../MainButton';

const LoginComponent = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!password) {
      setIsErrorPassword(true);
      return;
    }
    setErrorCode('');
    setEmail(email.toLowerCase());
    // test.riddletag@gmail.com
    //ebrgVCM=v47M$_dG

    axios
      .post(process.env.REACT_APP_API_URL + '/login', { email, password })
      .then((res) => {
        localStorage.setItem(Keys.ACCOUNT_KEY, res.data.accountKey);
        localStorage.setItem(
          Keys.AUTHORIZATION_TOKEN,
          res.data.authorizationToken
        );
        getAccountLimit();
      })
      .catch((error) => {
        setErrorCode(error.response.data.code);
        switch (error.response.data.code) {
          case 'R_003':
            setIsErrorPassword(true);
            break;
          case 'BO_005':
            setIsErrorPassword(true);
            break;
          default:
            break;
        }
      });
  };

  return (
    <div className="login__wrapper">
      <form className="login-form" onSubmit={(event) => handleSubmit(event)}>
        <h2 style={{ fontWeight: 500 }}>Welcome back!</h2>
        <h3 className={'sign_up_text_bold'}>
          Enter your email and password to continue
        </h3>
        <div className="login-form__change">
          <p className={'grey_text_login'}>First time here?</p>

          <div
            className={'login-form__change__button'}
            onClick={() => {
              history.push('/signup', { from: '/login' });
            }}
          >
            Create an account
          </div>
        </div>
        <TextInput
          title={'Email'}
          type={'email'}
          margin={'0 0 10px 0'}
          value={email}
          error={isErrorEmail}
          required={true}
          onchange={(e) => {
            setIsErrorEmail(false);
            setEmail(e.target.value);
          }}
        />

        <TextInput
          title={'Password'}
          type={'password'}
          margin={'0 0 10px 0'}
          value={password}
          error={isErrorPassword}
          required={true}
          onchange={(e) => {
            setIsErrorPassword(false);
            setPassword(e.target.value);
          }}
        />

        {errorCode === 'R_003' || errorCode === 'BO_005' ? (
          <p className="login-form--error">Wrong email or password.</p>
        ) : null}

        <div className="login-form__change">
          <p className={'grey_text_login'}>Forgot your password?</p>
          <div
            className={'login-form__change__button'}
            onClick={() => {
              props.changeToReset();
            }}
          >
            Reset your password
          </div>
        </div>
        <MainButton
          margin={'8px 0 0'}
          button={'main_big'}
          type={'submit'}
          text={'SIGN IN'}
        />
      </form>
    </div>
  );
};

export default LoginComponent;
