import React from 'react';
import './style/UsedData.css'

function TextInput(props) {

    return (
        <div style={props.margin ? {margin: props.margin} : null} className={'used_data_component'}>
            <span className={"used_text"}>{props.usedData + "%"}</span>
            <div className={'all_data'}>
                <div
                    style={props.usedData <= 33 ? {background: '#0CCC92', height: `${props.usedData}%`} :
                        props.usedData <= 66 ? {background: '#FFAB00', height: `${props.usedData}%`} :
                            props.usedData <= 100 ? {background: '#FE3E3E', height: `${props.usedData}%`} : null}
                    className={'used_data'}></div>
            </div>
        </div>
    );
}

export default TextInput;
