import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import * as serviceWorker from './serviceWorker';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Provider} from "react-redux";
import { createStore} from "redux";
import combine from "./store/combine";

const openLanding = () => window.location = "dashboard";
const stripePromise = loadStripe("pk_test_4X0yBHHpPU1keFObQCIAPjOt002wltiKAf");
const store = createStore(combine);


if (window.location.pathname !== "/") {
    ReactDOM.render(
        <Provider store={store}>
            <Elements stripe={stripePromise}>
                <Router/>
            </Elements>
        </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
} else {
    openLanding()
}
