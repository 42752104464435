import React from 'react';
import './style/CustomCheckbox.css'

function CloseIcon(props) {

    return (
        <div className={'close_popup'} onClick={()=> props.onclick()} style={props.wrapperStyle ? props.wrapperStyle : props.style}>
            <svg width="18" style={props.style ? props.style : null} height="12" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7332 9.00156L17.636 2.09949C18.1191 1.61633 18.1162 0.842229 17.636 0.361271C17.155 -0.121896 16.3809 -0.116004 15.9007 0.361271L8.99861 7.26333L2.09654 0.361271C1.61338 -0.121896 0.839282 -0.118949 0.361271 0.361271C-0.121896 0.845174 -0.118949 1.61854 0.361271 2.09949L7.26407 9.00156L0.361271 15.9007C-0.121896 16.3839 -0.118949 17.158 0.361271 17.6382C0.842229 18.1221 1.61632 18.1191 2.09654 17.6382L8.99861 10.7361L15.9007 17.6382C16.3839 18.1221 17.158 18.1191 17.636 17.6382C18.1191 17.155 18.1162 16.3809 17.636 15.9007L10.7332 9.00156Z" fill="#D9D9D9"/>
            </svg>
        </div>

    );
}

export default CloseIcon;
