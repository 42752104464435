import React from "react";
// import API from "../API";
import Layout from "../components/Layout";
import {withRouter} from "react-router-dom";
import MainButton from "../components/MainButton";
import downloadIcon from '../assets/download_cloud.svg'

let style = {
    everyBlock: {
        background: '#fff',
        border: '1px solid #EBECF0',
        boxSizing: 'border-box',
        borderRadius: '5px',
    },
    lastBlock: {
        marginTop: '32px'
    },
    titleOfBlockText: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#4E5D78',
    },
    titleOfBlock: {
        padding: '11.5px 16px',
        borderBottom: '1px solid rgba(0,0,0,.1)'
    },
    descriptionOfBlock: {
        marginTop: '17px',
        padding: '0 16px'
    },
    descriptionOfBlockText: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        color: '#818181'
    },
    textLinkWrapper: {
        marginTop: '24px',
        padding: '4px',
        borderTop: '1px solid rgba(0,0,0, .1)'
    },
    textLinkWrapper2: {
        marginTop: '24px',
        padding: '12px 20px',
        borderTop: '1px solid rgba(0,0,0, .1)'
    },
};

const Downloads = () => {
    return (
        <Layout>
            <div style={{maxWidth: '871px', margin: 'auto'}}>
                <div className={'wrapper_profile_information'} style={{gridTemplateColumns: '1fr'}}>
                    <h2 className={"children-wrapper__page-header"}>Downloads</h2>
                </div>
                <div style={style.everyBlock}>
                    <div style={style.titleOfBlock}>
                        <span style={style.titleOfBlockText}>Download 3D Face Analysis Software zip archive</span>
                    </div>
                    <div style={style.descriptionOfBlock}>
                        <div style={style.descriptionOfBlockText}>
                        <span>
                            Special Riddletag software for detection, analyzing, and processing
                            the image of the human face received from Intel RealSense 3D camera.
                            The current software version supports Intel RealSense D415 and D435 cameras. <br/>
                            Before you start, read the </span>
                            <a style={{textDecoration: 'underline'}} href="#dwa">Instruction for installation, </a><span>please.</span>
                        </div>
                    </div>
                    <div style={style.textLinkWrapper}>
                        <a href={'https://releases.ubuntu.com/18.04.4/ubuntu-18.04.4-live-server-amd64.iso'}>
                            <MainButton style={{textTransform: 'unset', width: '120px'}} button={'download'} text={'Download'}/>
                        </a>
                    </div>
                </div>
                <div style={{...style.everyBlock, ...style.lastBlock}}>
                    <div style={style.titleOfBlock}>
                        <span style={style.titleOfBlockText}>Download Ubuntu server 18.04.4</span>
                    </div>
                    <div style={style.descriptionOfBlock}>
                        <div style={style.descriptionOfBlockText}>
                        <span>In order for you to be able to successfully install
                        Riddletag 3D Face Analysis Software on the UP board / UP squared,
                        you will need to first install the Ubuntu server 18.04.4 operating system.
                        Full installation instructions are available here - </span>
                            <a style={{textDecoration: 'underline'}} href="#dwadwa">How to install Ubuntu.</a>
                        </div>
                    </div>
                    <div style={style.textLinkWrapper2}>
                        <a style={{textDecoration: 'underline'}} href={'https://releases.ubuntu.com/18.04.4/ubuntu-18.04.4-live-server-amd64.iso'}>
                            <img style={{marginRight: '8px'}} src={downloadIcon} alt=""/>
                            https://releases.ubuntu.com/18.04.4/ubuntu-18.04.4-live-server-amd64.iso
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 style={{marginLeft: '8px'}}>
                                <path d="M4.40625 2.8H9.20625V7.6" stroke="#5C9BFF" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M2.78906 9.2L9.18906 2.8" stroke="#5C9BFF" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </Layout>);
};

export default withRouter(Downloads)
