import React, {useEffect, useState} from 'react';
import './style/TextInput.css'

function SearchInput(props) {
    const [disabled, setDisabled] = useState(false)
    const [required, setRequired] = useState(false)
    const [focus, setFocus] = useState(false)

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
        if (props.required !== undefined && props.required !== null) {
            setRequired(props.required)
        }
    }, [props.disabled, props.required])

    return (
        <form onSubmit={(e) => {
            e.preventDefault()
            props.onsubmit(e)
        }}>
        <div style={props.margin ? {margin: props.margin} : null} className={'input_component input_search'}>
            {props.title ? <span style={disabled ? {color: '#C2C2C2'} : null}>{props.title}</span> : null }
            <div>
                <div className={focus ? 'searchImage search_image_active' : 'searchImage'}></div>
            <input
                style={props.width ? {width: `${props.width}`} : {paddingLeft: '50px'}}
                disabled={disabled}
                autoFocus={props.focus ? props.focus : false}
                onFocus={() => {
                    setFocus(true)
                    props.onfocusin()
                }}
                onBlur={() => {
                    setFocus(false)
                    props.onfocusout()
                }}
                required={required}
                placeholder={props.placeholder ? props.placeholder : null}
                className={props.error ? 'error custom_input' : 'custom_input'}
                type={props.type ? props.type : 'text'}
                value={props.value ? props.value : ''}
                onChange={props.onchange ? e => {
                    props.onchange(e)
                } : null}
            />
            </div>
        </div>
        </form>
    );
}

export default SearchInput;
