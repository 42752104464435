import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {Keys} from "../helper";
import {getAccountLimit} from "../API";
import LoginLayout from "../components/LoginLayout";

const ValidateSignup = () => {

    useEffect(() => {
        const url = window.location;
        const token = new URLSearchParams(url.search).get('token');

        if (token) {
            let urlPart = "registration"
            if (/password/.test(url.toString())) {
                urlPart = "account/password/recovery"
            }
            axios.get(process.env.REACT_APP_API_URL + `/${urlPart}/redirect/set?token=${token}`)
                .then(res => {
                    localStorage.setItem(Keys.ACCOUNT_KEY, res.data.accountKey)
                    localStorage.setItem(Keys.AUTHORIZATION_TOKEN, res.data.authorizationToken)
                    getAccountLimit()
                })
                .catch((error) => {
                    alert("Confirmation error")
                    if (error.response.data.code === "BO_002") {
                        window.location = "/login"
                    } else if (error.response.data.code === "BO_001") {
                        window.location = "/login?signup=true"
                    } else if (error.response.status === 400) {
                        window.location = "/login?reset=true"
                    } else window.location = "/login?signup=true"
                })
        } else {
            window.location = "/login?signup=true"
        }
    }, []);

    return (
        <LoginLayout setIsLogIn={() => {
            window.location = "/login"
        }} isLogIn={false}/>)
};

export default withRouter(ValidateSignup)
