import React from 'react';

import Layout from './Layout';
// import Logo from "../assets/riddletag-logo-white-new.svg";
// import betaLogo from "../assets/betaLogo.svg"
import betaLogo from '../assets/Logobeta.svg';
import MainButton from './MainButton';
import { useHistory } from 'react-router-dom';

const LoginLayout = (props) => {
  const history = useHistory();
  const getAuthForm = (page) => {
    return page
      ? history.push('/signup', { from: '/login' })
      : history.push('/login', { from: '/signup' });
  };
  return (
    <Layout hideNavigation={true}>
      <main>
        <header>
          <img
            className={'login__logo'}
            src={betaLogo}
            alt={'Riddletag logo'}
            height={34}
          />
          {props.isLogIn !== 0 ? (
            <MainButton
              wrapperStyle={{ zIndex: '999' }}
              style={{ width: '100px', marginLeft: 'auto' }}
              text={props.isLogIn && !props.email ? 'SIGN UP' : 'SIGN IN'}
              button={'cancel'}
              onclick={() => {
                if (props.email) {
                  props.setIsReset(false);
                  props.setIsLogInCustom(true);
                  props.setEmail();
                } else {
                  props.setIsLogIn(!props.isLogIn);
                  props.setEmail();
                  getAuthForm(props.isLogIn);
                }
              }}
            />
          ) : null}
        </header>
        <section className="login">{props.children}</section>
        {/*<footer>*/}
        {/*    <a href={"www.google.com"}>Terms of Service</a>*/}
        {/*    <a href={"www.google.com"}>Privacy Policy</a>*/}
        {/*    <a href={"www.google.com"}>GDPR</a>*/}
        {/*    <p>©2020 Riddletag. All rights reserved.</p>*/}
        {/*</footer>*/}
      </main>
    </Layout>
  );
};

export default LoginLayout;
