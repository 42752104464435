import React, {useEffect, useState} from 'react';
import './style/MainButton.css'
import downloadIcon from '../assets/download_icon.svg';
import downloadIconDisabled from '../assets/download_icon_disabled.svg';
import deleteIcon from '../assets/delete_icon.svg';
import deleteIconDisabled from '../assets/delete_icon_disabled.svg';
import addIcon from '../assets/add_icon.svg';

function MainButton(props) {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
    }, [props.disabled])

    return (
        <div style={props.margin ? {margin: props.margin} : props.wrapperStyle ? props.wrapperStyle : null}>
            <button
                id={props.id ? props.id : ''}
                autoFocus={props.autofocus}
                onClick={props.onclick ? () => {
                    props.onclick()
                } : null}
                type={props.type ? props.type : 'button'}
                style={props.style ? props.style : props.width ? {width: props.width} : props.small ? {width: '155px'} : null}
                className={props.button === 'main' ? 'main_button button_default' :
                    props.button === 'main_big' ? 'main_button big_button button_default' :
                        props.button === 'accent' ? 'button_accent button_default' :
                            props.button === 'accent_big' ? 'button_accent button_default big_button' :
                                props.button === 'warning' ? 'warning_button button_default' :
                                    props.button === 'danger' ? 'danger_button button_default' :
                                        props.button === 'cancel' ? 'cancel_button button_default' :
                                            props.button === 'download' ? 'download_button button_default' :
                                                props.button === 'delete' ? 'delete_button button_default' :
                                                    props.button === 'add' ? 'add_button button_default' :
                                                        'main_button button_default'}
                disabled={disabled}>
                {props.loader ? <i style={{marginRight: '8px', color: 'white'}} class="fa fa-refresh fa-spin"></i> :
                    props.img ? <img style={{marginRight: '4px'}} src={props.img} alt=""/> :
                        props.button === 'download' ?
                            disabled ? <img src={downloadIconDisabled} alt=""/> : <img src={downloadIcon} alt=""/> :
                            props.button === 'delete' ?
                                disabled ? <img src={deleteIconDisabled} style={props.imgStyle ? props.imgStyle : null}
                                                alt=""/> :
                                    <img src={deleteIcon} style={props.imgStyle ? props.imgStyle : null} alt=""/> :
                                props.button === 'add' ? <img src={addIcon} alt=""/> :
                                    null
                }
                {props.loader ? 'Payment processing' : props.text ? props.text : ''}
            </button>
        </div>
    );
}

export default MainButton;
