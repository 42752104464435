import axios from 'axios'
import {Keys} from "./helper";

let host = process.env.REACT_APP_API_URL;
if (host === undefined) {
    host = '';
}


let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};


if (localStorage.getItem(Keys.AUTHORIZATION_TOKEN)) headers.Authorization = localStorage.getItem(Keys.AUTHORIZATION_TOKEN);
// const baseURL = host + "/api/v1.4";
const baseURL = host;

let API = axios.create({
    baseURL: baseURL,
    timeout: 60000,
    headers: headers
});

API.shouldIntercept = true;
API.shouldlog = true;

// token refresh


API.interceptors.request.use(function (request) {
    // if (API.shouldlog) {
    // }
    return request;
}, function (error) {
});

API.interceptors.response.use(function (response) {
    if (API.shouldlog) {
        console.log(response)
    }
    return response;
}, function (error) {
    if (API.shouldlog && error.response) {
        console.log(error.response.data);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
    }
    if (error.response && 401 !== error.response.status) {

        // Object.prototype.toString = function () {
        //     let output = "";
        //     for (let i in this) {
        //         output += i + ": " + this[i] + "\n";
        //     }
        //     return output;
        // };
        //
        // alert(error.response);
    }
    if (error.response && 401 === error.response.status && API.shouldIntercept) {

        const refreshToken = localStorage.getItem('RiddletagRefreshToken');
        const authToken = localStorage.getItem('RiddletagToken');
        if (authToken && refreshToken) {
            axios.put(baseURL + "/open/authentication", {authToken, refreshToken}).then(r => {
                if (r.status === 200) {
                    localStorage.setItem('RiddletagToken', r.data.authToken);
                    localStorage.setItem('RiddletagRefreshToken', r.data.refreshToken);
                } else {
                    logOut(r)
                }
            }).catch((e) => {
                logOut(e)
            })
        } else {
            logOut("No tokens")
        }
    } else {

        return Promise.reject(error);
    }
});

export function logOut(m) {
    API.post("/logout", {accountKey: localStorage.getItem(Keys.ACCOUNT_KEY)}).then(res => {
        console.log(res)
        localStorage.removeItem(Keys.ACCOUNT_KEY);
        localStorage.removeItem(Keys.AUTHORIZATION_TOKEN);
        window.location.pathname = "/login";
    });

}

export function getAccountLimit(needData, callback) {
    const accountKey = localStorage.getItem(Keys.ACCOUNT_KEY)
    const headers = {
        Authorization: localStorage.getItem(Keys.AUTHORIZATION_TOKEN)
    }
    API.post(`/account/limit/list/get`, {accountKey}, {headers})
        .then(res => {
            res.data.limits.forEach(limit => {
                if (limit.isEnabled) {
                    switch (limit.paymentStatus) {
                        case "NOT_ACTIVE":
                            break;
                        //sub create
                        default:
                            window.location = "/profile-update"
                            break;
                    }
                }
            })
        })
        .catch(error => {

        })
}


export default API
