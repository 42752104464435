import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import API from '../API';
import betaLogo from '../assets/Logobeta.svg';
import DashboardIcon from '../assets/sidenav-dashboard.svg';
import ArrowLinkBlue from '../assets/arrow_link_blue.svg';
import KeysIcon from '../assets/sidenav-keys.svg';
import LicensesIcon from '../assets/sidenav-licenses.svg';
import DownloadIcon from '../assets/sidenav-download.svg';
import PricingIcon from '../assets/sidenav-pricing.svg';
import BillingIcon from '../assets/sidenav-billing.svg';
import DocsIcon from '../assets/sidenav-docs.svg';
import ProfileIcon from '../assets/sidenav-profile.svg';
import SettingsIcon from '../assets/sidenav-settings.svg';
import LogoutIcon from '../assets/sidenav-logout.svg';
import { logOut } from '../API';
import { setRedirectModal } from '../store/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Sidenav = (props) => {
  const [accountData, setAccountData] = useState([]);
  const [userData, setUserData] = useState([]);
  const history = useHistory();

  const navObj = [
    {
      name: 'Dashboard',
      link: '/dashboard',
      slug: '/dashboard',
      icon: DashboardIcon,
    },
    {
      name: 'API Keys',
      link: '/keys',
      slug: '/keys',
      icon: KeysIcon,
    },
    {
      name: 'Licenses',
      link: '/licenses',
      slug: '/licenses',
      icon: LicensesIcon,
    },
    {
      name: 'Download',
      link: '/download',
      slug: '/download',
      icon: DownloadIcon,
    },
    {
      name: 'Docs',
      link: '/docs',
      slug: '/docs',
      icon: DocsIcon,
    },
    {
      name: 'Pricing',
      link: '/pricing',
      slug: '/pricing',
      icon: PricingIcon,
    },
    {
      name: 'Billing',
      link: '/billing',
      slug: '/billing',
      icon: BillingIcon,
    },
    {
      name: 'Profile',
      link: '/profile',
      slug: '/profile',
      icon: ProfileIcon,
    },
    {
      name: 'Account',
      link: '/settings',
      slug: '/settings',
      icon: SettingsIcon,
    },
    // {
    //     name: "PAYMENT TEST",
    //     link: "/payment-test",
    //     slug: "/payment-test",
    //     icon: BillingIcon,
    // },
    {
      name: 'Log out',
      link: '/log-out',
      slug: '/log-out',
      icon: LogoutIcon,
    },
  ];

  const getDashboardPage = () => {
    history.push('/dashboard');
  };

  useEffect(() => {
    API.post('/account/get', {
      accountKey: localStorage.getItem('RiddletagAccountKey'),
    }).then((res) => {
      setAccountData(res.data);
      console.log(res);
    });
    API.post('/account/limit/list/get', {
      accountKey: localStorage.getItem('RiddletagAccountKey'),
    }).then((res) => {
      res.data.limits.forEach((limit) => {
        setUserData(limit);
      });
    });
  }, []);

  return (
    <section className="sidenav">
      <div className="sidenav__wrapper">
        <div className="sidenav-logo">
          <img
            className="sidenav-logo__source фф"
            src={betaLogo}
            alt="Riddletag"
            onClick={getDashboardPage}
          />
        </div>
        <div style={{ marginBottom: 0 }} className={'sidenav__separator'} />
        <div className={'sidenav_user_info'}>
          <div>
            <span className={'user_info_company'}>
              {accountData.companyName ? accountData.companyName : '...'}
            </span>
            <span className={'user_info_email'}>
              {accountData.email ? accountData.email : '...'}
            </span>
          </div>
          <div>
            <span className={'user_info_plan'}>{userData.title}</span>
            <button onClick={() => props.setUpgradePlan()}>Upgrade</button>
          </div>
        </div>
        <div className={'sidenav__separator'} />
        <ul className="sidenav-list">
          {navObj.map((item, id) => (
            <li className="sidenav-list__item" key={id}>
              <NavLink
                className="sidenav-list__link"
                activeClassName="active"
                to={
                  item.link === '/pricing' || item.link === '/billing'
                    ? ''
                    : item.link
                }
                onClick={(e) => {
                  if (item.link === '/log-out') {
                    e.preventDefault();
                    logOut();
                  } else if (item.link === '/docs') {
                    e.preventDefault();
                    props.setRedirectModal(true);
                  } else if (item.link === '/pricing') {
                    e.preventDefault();
                    props.setUpgradePlan();
                  } else if (item.link === '/billing') {
                    e.preventDefault();
                    props.setBillingPopup();
                  }
                }}
                isActive={(match, location) =>
                  location.pathname.startsWith(item.slug)
                }
              >
                <img
                  className="sidenav-list__icon"
                  src={item.icon}
                  alt={item.name}
                />
                {item.name}
                {item.link === '/docs' && (
                  <img
                    style={{ paddingLeft: '5px' }}
                    src={ArrowLinkBlue}
                    alt="arrow"
                  />
                )}
                <div className={'active_triangle'}></div>
              </NavLink>
              {id === 4 || id === 8 ? (
                <div className={'sidenav__separator margin-top-8'} />
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectModal: state.shop.redirectModal,
  };
};

const mapDispatchToProps = {
  setRedirectModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
