import React, {useEffect, useRef, useState} from "react"

import Sidenav from "./Sidenav"

import useMediaQuery from "./useMediaQuery"
import Header from "./Header";
import {Keys} from "../helper";
import Pricing from "../pages/Pricing";
import CloseIcon from "./CloseIcon";
import chrome from '../assets/chrome.png'
import mozila from '../assets/mozila.png'
import opera from '../assets/opera.png'
import {setRedirectModal} from "../store/actions";
import {connect} from "react-redux";
import attentionIcon from "../assets/attention_icon.svg";
import MainButton from "./MainButton";
import useOutsideClick from "./useOutsideClick";
import API from "../API";

const Layout = (props) => {
    const isMediaSmall = useMediaQuery('(max-width: 1024px)');
    const initials = localStorage.getItem(Keys.INITIALS)
    const email = localStorage.getItem(Keys.EMAIL)
    const [upgradePlan, setUpgradePlan] = useState(false)
    const [billingPopup, setBillingPopup] = useState(false)

    const ref = useRef()

    useOutsideClick(ref, () => {
        if (props.redirectModal) {
            props.setRedirectModal(false)
        }
        if (billingPopup) {
            setBillingPopup(false)
        }
    });

    useEffect(() => {
        if (upgradePlan) {
            document.addEventListener('keydown', (e) => {
                if (e.which === 27) {
                    document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                    setUpgradePlan(false)
                }
            })
        }
    }, [upgradePlan]);

    const goToStripe = () => {
        API.get('/billing/redirect', {params: {return_url: window.location.href}})
            .then(res => {
                window.location.href = res.data.link
            })
    }

    return (
        <>
            <main className="main">
                {navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) ?
                    <>
                        <div style={{gridGap: '12px'}} className="main__error">
                            <span className={'main__error-big'}>Sorry we don’t support your browser</span>
                            <span className={'main__error-small'}>We currently support the browsers listed below. Download and install a browser that is more suitable for you by following the link.</span>
                            <iframe title={'ie_gif'} src="https://giphy.com/embed/YD4fJ72Qr0cQ8" width="480"
                                    height="270"
                                    frameBorder="0" className="giphy-embed" allowFullScreen/>
                            <div style={{marginTop: '8px'}} className={'main__error-browser_wrapper'}>
                                <span>Choose a browser</span>
                                <div>
                                    <div className={'chrome'}>
                                        <img src={chrome} alt=""/>
                                        <div>
                                            <span>Google Chrome</span>
                                            <a rel="noopener noreferrer" target={'_blank'}
                                               href={'https://www.google.com/intl/en/chrome/'}>Download</a>
                                        </div>
                                    </div>
                                    <div className={'mozila'}>
                                        <img src={mozila} alt=""/>
                                        <div>
                                            <span>Mozilla Firefox</span>
                                            <a rel="noopener noreferrer" target={'_blank'}
                                               href={'https://www.mozilla.org/en-US/firefox/new/'}>Download</a>
                                        </div>
                                    </div>
                                    <div className={'opera'}>
                                        <img src={opera} alt=""/>
                                        <div>
                                            <span>Opera</span>
                                            <a rel="noopener noreferrer" target={'_blank'}
                                               href={'https://www.opera.com'}>Download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    isMediaSmall ?
                        <>
                            <div className="main__error">
                                <span className={'main__error-big'}>Try on a bigger screen</span>
                                <span className={'main__error-small'}>Auther supports screens bigger than 1024px only. Remember, this still is a beta version.</span>
                                <iframe title={'sizeErrorGif'} src="https://giphy.com/embed/PiQejEf31116URju4V"
                                        width="480" height="288"
                                        frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
                            </div>
                        </>
                        :
                        !props.hideNavigation ? <>
                            {upgradePlan ?
                                <>
                                    <CloseIcon onclick={() => {
                                        document.getElementsByTagName('body')[0].style.overflowY = 'auto'
                                        setUpgradePlan(false)
                                    }}
                                               style={{
                                                   position: 'fixed',
                                                   top: '24px',
                                                   right: '24px',
                                                   zIndex: '1000000',
                                                   cursor: 'pointer'
                                               }}/>
                                    <Pricing goToDashboard={() => setUpgradePlan(false)} fixed={true}/>
                                </>
                                : null
                            }
                            {billingPopup ?
                                <div className={'global_popup_wrapper'}>
                                    <form style={{width: '475px'}} className={'global_popup'}
                                          ref={ref}
                                          onSubmit={goToStripe}>
                                        <CloseIcon onclick={() => setBillingPopup(false)}/>
                                        <div className={'wrapper_title_of_popup'}>
                                            <span>Billing</span>
                                        </div>
                                        <div className={'delete_license_content'}>
                                <span
                                    className={'license_text'}>Do you want to cancel the subscription, download an invoice, or change the payment method?</span>
                                            <div className={'warning_block'}>
                                                <img src={attentionIcon} alt=""/>
                                                <span
                                                    style={{color: '#FFAB00'}}>You will be redirected  to the Stripe Customer portal</span>
                                            </div>
                                            <div className={'delete_buttons'}>
                                                <MainButton autofocus={true} id={'firstReg'} button={'warning'} width={'155px'}
                                                            text={"Open"}
                                                            onclick={() => {
                                                                goToStripe();
                                                                setTimeout(() => setBillingPopup(false))
                                                            }}/>
                                                <MainButton button={'cancel'} id={'lastReg'} width={'155px'} text={"Cancel"}
                                                            onclick={() => setBillingPopup(false)}/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                : null
                            }
                            {props.redirectModal ?
                                <div className={'global_popup_wrapper'}>
                                    <form style={{width: '475px'}} className={'global_popup'}
                                          ref={ref}
                                          onSubmit={e => e.preventDefault()}>
                                        <CloseIcon onclick={() => props.setRedirectModal(false)}/>
                                        <div className={'wrapper_title_of_popup'}>
                                            <span>Read the Docs</span>
                                        </div>
                                        <div className={'delete_license_content'}>
                                <span
                                    className={'license_text'}>Before starting, read the Docs on the Developers section.</span>
                                            <div className={'warning_block'}>
                                                <img src={attentionIcon} alt=""/>
                                                <span
                                                    style={{color: '#FFAB00'}}>Your will be redirected to external link.</span>
                                            </div>
                                            <div className={'delete_buttons'}>
                                                <MainButton autofocus={true} id={'firstReg'} button={'accent'} width={'155px'}
                                                            text={"Read the docs"}
                                                            onclick={() => {
                                                                window.open("https://auther.ai/developers")
                                                                setTimeout(() => props.setRedirectModal(false))
                                                            }}/>
                                                <MainButton button={'cancel'} id={'lastReg'} width={'155px'} text={"Cancel"}
                                                            onclick={() => props.setRedirectModal(false)}/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                : null}
                            <Sidenav setBillingPopup={() => setBillingPopup(true)} setUpgradePlan={() => setUpgradePlan(true)}/>
                            <Header popupAlert={props.popupAlert} popupAlertText={props.popupAlertText}
                                    setPopupAlert={() => props.setPopupAlert(0)} initials={initials} email={email}/>
                            <div className={"children-wrapper"}>
                                {props.children}</div>
                        </> : <>{props.children} </>
                }
            </main>
        </>
    )
};

const mapStateToProps = state => {
    return {
        redirectModal: state.shop.redirectModal,
    }
}

const mapDispatchToProps = {
    setRedirectModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
